import { differenceInDays } from "date-fns"
import { mapModelStatus, mapPriorities } from "src/helpers/request"

export const initialOptions = [
  {
    id: null,
    label: "",
    selected: true
  },
  {
    id: 5,
    label: "Alta",
    selected: false
  },
  {
    id: 3,
    label: "Media",
    selected: false
  },
  {
    id: 1,
    label: "Baja",
    selected: false
  }
]

export const requirementFormatter = (requirement) => {
  let oldRequest = 0

  if (requirement.fecha_vencimiento !== null) {
    const date = requirement.fecha_vencimiento.split("-")
    oldRequest = differenceInDays(
      new Date(),
      new Date(date[0], date[1] - 1, date[2])
    )
  }

  const priority = mapPriorities(requirement.prioridad)

  const status = mapModelStatus(requirement.status)

  const newObject = {
    id: requirement.id,
    header: {
      status
    },
    body: {
      title: requirement.titulo,
      description: requirement.descripcion,
      responsability: {
        id: requirement.usuario_responsable.id,
        name: requirement.usuario_responsable.nombre,
        position: requirement.usuario_responsable.cargo,
        email: requirement.usuario_responsable.correo
      },
      date: requirement.fecha_vencimiento,
      priority: {
        id: priority?.id,
        label: priority?.label,
        color: priority?.color
      },
      nameFile: requirement.nombre_archivo,
      rejectReason: requirement.motivo_rechazo
    },
    old: oldRequest >= 90 ? true : false
  }

  return newObject
}
