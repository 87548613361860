import cloneDeep from "lodash/cloneDeep"
import {
  ADD_FINDING_ACTION_PLAN_ACTIVITY,
  DELETE_FINDING_ACTION_PLAN_ACTIVITY,
  LOAD_FINDINGS,
  SET_IS_LOADING_FINDINGS,
  UPDATE_FINDING,
  UPDATE_FINDING_ACTION_PLAN_ACTIVITY
} from "./actions"

export const makeInitialState = () => ({
  findings: [],
  configs: null,
  isLoading: false,
  hasLoadedAll: false
})

export const makeDefaultAction = () => ({
  type: "",
  entries: [],
  entry: null,
  flag: true,
  key: ""
})

export default function findingsReducer(state = makeInitialState(), action = makeDefaultAction()) {
  const { type = "", key = "", entries = [], entry = null, flag = true } = action

  switch (type) {
    case UPDATE_FINDING: {
      const [findingId] = key
      let findings = cloneDeep(state.findings)
      const find = findings.find((el) => el.id === findingId)
      Object.entries(find).forEach(([key]) => {
        if (key in action.entries) {
          if (typeof action.entries[key] === "object" && action.entries[key] !== null) {
            find[key] = {
              ...find[key],
              ...action.entries[key]
            }
          } else {
            find[key] = action.entries[key]
          }
        }
      })

      return {
        ...state,
        findings
      }
    }

    case UPDATE_FINDING_ACTION_PLAN_ACTIVITY: {
      const [findingId, activityId] = key

      const findingIdx = state.findings.findIndex((f) => f.id === findingId)

      if (findingIdx === -1) {
        return { ...state }
      }

      const findings = cloneDeep(state.findings)
      const finding = findings[findingIdx]

      const activityIdx = finding.plan_accion.actividades.findIndex((a) => a.id === activityId)

      if (activityIdx === -1) {
        return { ...state }
      }

      const activity = finding.plan_accion.actividades[activityIdx]

      finding.plan_accion.actividades[activityIdx] = {
        ...activity,
        ...entry
      }

      return {
        ...state,
        findings
      }
    }

    case DELETE_FINDING_ACTION_PLAN_ACTIVITY: {
      const [planActionId, activityId] = key

      let findings = cloneDeep(state.findings)

      const indexPlanAction = findings.findIndex((el) => el.plan_accion.id === planActionId)

      const finding = findings[indexPlanAction]

      const updatedPlanAction = findings.find((el) => el.plan_accion.id === planActionId)["plan_accion"]

      const found = updatedPlanAction["actividades"]

      const indexFound = updatedPlanAction["actividades"].findIndex((el) => el.id === activityId)

      if (indexFound === -1) {
        return { ...state }
      }

      found.splice(indexFound, 1)

      finding.plan_accion.actividades = updatedPlanAction.actividades

      return {
        ...state,
        findings
      }
    }
    case ADD_FINDING_ACTION_PLAN_ACTIVITY: {
      const findingIdx = state.findings.findIndex((f) => f.id === key)

      if (findingIdx === -1) {
        return { ...state }
      }

      const findings = cloneDeep(state.findings)
      const finding = findings[findingIdx]

      finding.plan_accion?.actividades?.push(entry)

      return {
        ...state,
        findings
      }
    }
    case SET_IS_LOADING_FINDINGS: {
      return {
        ...state,
        isLoading: flag
      }
    }

    case LOAD_FINDINGS: {
      return {
        ...state,
        findings: [...entries]
      }
    }

    case "ADD_FINDINGS": {
      return {
        ...state,
        findings: [...state.findings, ...entries]
      }
    }

    case "SET_FINDINGS_CONFIGS": {
      return {
        ...state,
        configs: entries
      }
    }

    default: {
      return {
        ...state
      }
    }
  }
}
