export const POPULATE_DATA = 'POPULATE_DATA'
export const TOGGLE_PERSONAL_ALLOWANCE_GROUP = 'TOGGLE_PERSONAL_ALLOWANCE_GROUP'
export const EXPAND_ALL_PERSONAL = 'EXPAND_ALL_PERSONAL'
export const COLLAPSE_ALL_PERSONAL = 'COLLAPSE_ALL_PERSONAL'
export const CREATE_MEMBER = 'CREATE_MEMBER'
export const UPDATE_MEMBER = 'UPDATE_MEMBER'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const UPDATE_PROJECT_GROUP = 'UPDATE_PROJECT_GROUP'

export const populateData = ({ projects, users }) => {
  return {
    type: POPULATE_DATA,
    byProject: projects,
    byUser: users
  }
}

export const togglePersonalAllowanceGroup = ({
  projectId = null,
  userId = null
}) => {
  return {
    type: TOGGLE_PERSONAL_ALLOWANCE_GROUP,
    projectId,
    userId
  }
}

export const expandAllPersonal = ({ byProject }) => {
  return {
    type: EXPAND_ALL_PERSONAL,
    byProject
  }
}

export const collapseAllPersonal = ({ byProject }) => {
  return {
    type: COLLAPSE_ALL_PERSONAL,
    byProject
  }
}

export const createMember = ({
  projectId,
  newMember
}) => {
  return {
    type: CREATE_MEMBER,
    projectId,
    newMember
  }
}

export const createProject = ({
  auditId,
  user,
  rol,
  newProject
}) => {
  return {
    type: CREATE_PROJECT,
    auditId,
    user,
    rol,
    newProject
  }
}

export const updateMember = ({
  projectId,
  memberId,
  updatedMember
}) => {
  return {
    type: UPDATE_MEMBER,
    projectId,
    memberId,
    updatedMember
  }
}

export const updateProject = ({
  memberId,
  teamMemberId,
  updatedProject
}) => {
  return {
    type: UPDATE_PROJECT,
    memberId,
    teamMemberId,
    updatedProject
  }
}

export const updateProjectGroup = ({
  projectId,
  updatedProject
}) => {
  return {
    type: UPDATE_PROJECT_GROUP,
    projectId,
    updatedProject
  }
}

