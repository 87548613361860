export const SET_AUTHENTICATED_ONCE = "SET_AUTHENTICATED_ONCE"
export const SET_IS_LOADING_AUTHENTICATION = "SET_IS_LOADING_AUTHENTICATION"

export const setHasAuthenticatedOnce = (value = true) => {
  return {
    type: SET_AUTHENTICATED_ONCE,
    data: value
  }
}

export const setIsLoadingAuthentication = (value = true) => {
  return {
    type: SET_IS_LOADING_AUTHENTICATION,
    data: value
  }
}
