import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import translationEN from "./locales/en"
import translationES from "./locales/es"

const resources = {
  es: {
    translation: translationES
  },
  en: {
    translation: translationEN
  }
}

i18n.use(initReactI18next).init({
  resources,
  interpolation: {
    escapeValue: false,
    skipOnVariables: false
  }
})
i18n.changeLanguage(typeof window === "undefined" ? "es" : window.localStorage.getItem("user_lng") ?? "es")
export default i18n
