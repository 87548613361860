import {
  ADD_REQUIREMENT,
  POPULATE_REQUIREMENTS,
  DELETE_REQUIREMENT,
  UPDATE_REQUEST
} from './actions'
import { requirementFormatter } from './helpers'

export const initialState = {
  data: [],
  isLoaded: false
}

export default function requirementsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_REQUIREMENT:
      return {
        ...state,
        data: [...state.data, requirementFormatter(action.newRequirement)]
      }
    case DELETE_REQUIREMENT:
      const updatedData = [...state.data]

      const index = updatedData.findIndex(
        (requirement) => requirement.id === action.requirementId
      )

      updatedData.splice(index, 1)

      return {
        ...state,
        data: updatedData
      }
    case POPULATE_REQUIREMENTS:
      return {
        ...state,
        data: action.requirements.map((_el) => requirementFormatter(_el)),
        isLoaded: true
      }
    case UPDATE_REQUEST: {
      const updatedData = [...state.data]

      const index = updatedData.findIndex((_request) => _request.id === action.requestId)

      updatedData[index] = {
        ...updatedData[index],
        ...requirementFormatter(action.updatedRequest)
      }

      return {
        ...state,
        data: updatedData
      }
    }

    default:
      return state
  }
}
