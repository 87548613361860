const treeMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case 'TREE.TOGGLE_ROW': {
      localStorage.setItem(
        'toggleState',
        JSON.stringify({ indexes: action.indexes, flag: action.flag })
      )
      break
    }
  }

  return next(action)
}

export default treeMiddleware
