export default function useAccessControl() {
  const user = typeof window === "undefined" ? null : JSON.parse(localStorage.getItem("user"))

  const checkUserHasPermissionValue = (userPermissionValue, permissionValue) => {
    if (typeof userPermissionValue == "number") {
      userPermissionValue = "" + userPermissionValue
    }
    if (permissionValue === null) {
      return userPermissionValue !== "0"
    }

    if (["number", "string"].includes(typeof permissionValue)) {
      if (userPermissionValue == `${permissionValue}`) {
        return true
      }

      if (userPermissionValue === "undefined" && `${permissionValue}` == "1") {
        return true
      }

      return false
    }

    if (userPermissionValue === "1") {
      return true
    }

    if (userPermissionValue === "0") {
      return false
    }

    if (userPermissionValue === "undefined") {
      return true
    }

    permissionValue = permissionValue[userPermissionValue]
    if (typeof permissionValue === "undefined") {
      return false
    }

    if (typeof permissionValue === "function") {
      return permissionValue({ currentUserId: user.id })
    }

    return !!permissionValue
  }

  /**
   *
   * @param {string} permission - The permission name.
   *
   * @returns  {number} The permission value assigned for current user.
   */
  const getPermission = (permission) => {
    if (isUserAdmin()) {
      return 1
    }

    return user.permissions[permission] ?? 0
  }

  /**
   *
   * @param {string} permissions - The permission to check
   *
   * @returns {string}
   */
  const getPermissionByWildcards = (permissions) => {
    const permparts = permissions.split(".")
    if (permparts <= 1) {
      return null
    }

    for (let k = 0; k < permparts.length - 1; k++) {
      const perm = permparts.slice(0, (k + 1) * -1).join(".")
      if (typeof user.permissions[`${perm}.*`] != "undefined") {
        return `${perm}.*`
      }
    }

    return null
  }

  /**
   *
   * @param {(string | string[])} permissions - the permissions to check.
   * @param {(number | Object.<number, boolean>)} permissionValue
   *
   * @returns {boolean}
   */
  const hasPermission = (permissions, permissionValue = null) => {
    if (!user) {
      return false
    }

    if (isUserAdmin()) {
      return true
    }

    if (typeof permissions === "undefined" || permissions === true) {
      return true
    }

    if (!Array.isArray(permissions)) {
      if (typeof user.permissions[permissions] == "undefined" && permissions.includes(".")) {
        const permwithwildcard = getPermissionByWildcards(permissions)
        if (permwithwildcard !== null) {
          return checkUserHasPermissionValue(user.permissions[permwithwildcard], permissionValue)
        }
      }

      return checkUserHasPermissionValue(`${user.permissions[permissions]}`, permissionValue)
    } else {
      return permissions.every((perm) => checkUserHasPermissionValue(`${user.permissions[perm]}`, permissionValue))
    }
  }

  /**
   *
   * @param {(string | string[])} - the permissions to check.
   * @param {(number | Object.<number, boolean>)} permissionValue
   *
   * @returns {boolean}
   */
  const hasNonReadonlyPermission = (permissions, permissionValue = null) => {
    if (isUserGuest()) {
      return false
    }
    return hasPermission(permissions, permissionValue)
  }

  const hasPermissionOnObject = (permissions, object, extraPermissionsValue = null) => {
    if (!object) {
      return false
    }

    let permissionsValue = {}
    permissionsValue[2] = object.created_by?.id == user.id

    if (extraPermissionsValue) {
      permissionsValue = { ...permissionsValue, ...extraPermissionsValue }
    }

    return hasPermission(permissions, permissionsValue)
  }

  /**
   *
   * @param  {...string} roles - The roles to check
   *
   * @returns {boolean} Returns true if current user has any of roles passed as argument.
   */
  const hasUserRole = (...roles) => {
    if (roles && roles[0] === "ADMIN") {
      return isUserAdmin() || user.role == "ADMIN"
    }
    return roles.includes(user?.role)
  }

  /**
   *
   * @returns {boolean} Returns true if current user is admin, false otherwise.
   */
  const isUserAdmin = () => {
    return user?.is_superuser
  }

  /**
   *
   * @returns {boolean} Returns true if current user is GUEST type, false otherwise.
   */
  const isUserGuest = () => {
    return hasUserRole("GUEST")
  }

  /**
   *
   * @returns {boolean} Returns true if current user has specified group, false otherwise.
   */
  const hasGroup = (group) => {
    return user?.groups?.includes(group)
  }

  /**
   *
   * @param {object} object
   * @param {number} object.permission - The object permission to check.
   * @param {number} object.role_permissions - The object role permissions to check, if attribute 'permission' is not defined.
   *
   * @returns {boolean}
   */
  const checkObjectPermission = (object) => {
    if (isUserAdmin()) {
      return true
    }

    if (typeof object.permission != "undefined") {
      if (typeof object.permission === "string") {
        object.permission = [object.permission]
      }
      return hasPermission(...object.permission)
    } else if (typeof object.role_permissions != "undefined") {
      return hasUserRole(...object.role_permissions)
    }

    return true
  }

  return {
    hasPermission,
    hasNonReadonlyPermission,
    hasPermissionOnObject,
    hasUserRole,
    hasGroup,
    isUserAdmin,
    isUserGuest,
    getPermission,
    checkObjectPermission,
    getCurrentUser: () => user,
    getCurrentUserId: () => user?.id,
    isAuthenticated: () => !!user
  }
}
