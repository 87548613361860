import cx from "classnames"
import ErrorBoundary from "src/errors/ErrorBoundary"
import styles from "./style.module.scss"

export default function DialogBody({
  theme = null,
  background = null,
  color = null,
  align = null,
  className = null,
  rounded = false,
  children,
  style = {}
}) {
  if (theme === "light") {
    background = "#fff"
    color = "#424242"
    align = "left"
  }
  return (
    <ErrorBoundary>
      <div
        className={cx(styles.dialogBody, className, "dialog-body")}
        style={{
          backgroundColor: background,
          color,
          textAlign: align,
          padding: 14,
          borderRadius: rounded ? (rounded === true ? 8 : rounded) : "none",
          ...style
        }}
      >
        {children}
      </div>
    </ErrorBoundary>
  )
}
