import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getUsers = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get(`/users/`, { params })

  return response.data
})

export const getUserSlots = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get(`/user_slots/`, { params })

  return response.data
})

export const getUserAbi = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.get(`/user_slots/abi/`)

  return response.data
})

export const requestUserAbi = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.post(`/user_slots/abi/`)

  return response.data
})

export const revokeUserAbi = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.delete(`/user_slots/abi/`)

  return response.data
})

export const getUserEvent = authValidationMiddleware(async (eventName) => {
  const response = await axiosApiInstance.get("/users/@me/event/", { params: { event: eventName } })

  return response.data
})

export const postUserEvent = authValidationMiddleware(async (eventName) => {
  const response = await axiosApiInstance.post("/users/@me/event/", { event: eventName })

  return response.data
})

export const releaseUserSlot = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.put(`/user_slots/${id}/release/`)

  return response.data
})

export const createUserSlot = authValidationMiddleware(async ({ slotId, first_name, last_name, email, role }) => {
  const response = await axiosApiInstance.post(`/user_slots/${slotId}/assign_user/`, {
    first_name,
    last_name,
    email,
    role
  })

  return response.data
})

export const updateUser = authValidationMiddleware(async ({ id, firstName, lastName, role, email }) => {
  const response = await axiosApiInstance.put(`/users/${id}/`, {
    first_name: firstName,
    last_name: lastName,
    role,
    email
  })

  return response.data
})

export const genericUserActionService = (id, action) => {
  const URL = `/user/${id}/${action}/`

  return {
    GET: authValidationMiddleware(async (params = {}) => {
      const response = await axiosApiInstance.get(URL, { params })
      return response.data
    }),
    POST: authValidationMiddleware(async (payload = {}) => {
      const response = await axiosApiInstance.post(URL, payload)
      return response.data
    }),
    PUT: authValidationMiddleware(async (payload = {}) => {
      const response = await axiosApiInstance.put(URL, payload)
      return response.data
    }),
    DELETE: authValidationMiddleware(async () => {
      const response = await axiosApiInstance.delete(URL)
      return response.data
    })
  }
}

export const editPassword = authValidationMiddleware(
  async ({ userId, password, passwordConfirmation, generate_password }) => {
    const response = await axiosApiInstance.put(`/users/${userId}/`, {
      password,
      password_confirmation: passwordConfirmation,
      generate_password
    })
    return response.data
  }
)

export const getUser = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.get(`/users/@me/`)
  return response.data
})

export const getUserDisclamerText = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.get(`/users/@me/disclamer_text/`)
  return response.data
})

export const genericUserMeActionService = (action) => {
  const URL = `/users/@me/${action}/`

  return {
    GET: authValidationMiddleware(async (params = {}) => {
      const response = await axiosApiInstance.get(URL, { params })
      return response.data
    }),
    POST: authValidationMiddleware(async (payload = {}) => {
      const response = await axiosApiInstance.post(URL, payload)
      return response.data
    }),
    DELETE: authValidationMiddleware(async () => {
      const response = await axiosApiInstance.delete(URL)
      return response.data
    })
  }
}

export const genericAccountActionService = (action) => {
  const URL = `/account/${action}/`

  return {
    GET: authValidationMiddleware(async (params = {}, options = {}) => {
      const response = await axiosApiInstance.get(URL, { params, ...options })
      return response.data
    }),
    POST: authValidationMiddleware(async (payload = {}) => {
      const response = await axiosApiInstance.post(URL, payload)
      return response.data
    }),
    DELETE: authValidationMiddleware(async () => {
      const response = await axiosApiInstance.delete(URL)
      return response.data
    })
  }
}

export const getUsersRole = authValidationMiddleware(async () => {
  const response = await axiosApiInstance.get(`/paramlists/usuario_rol/`)

  return response.data
})

export const getUsersRolePermissions = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.get(`/paramlists/usuario_rol/${id}/permissions/`)

  return response.data
})

export const setUsersRolePermissions = authValidationMiddleware(async (id, permissions) => {
  const response = await axiosApiInstance.post(`/paramlists/usuario_rol/${id}/permissions/`, {
    permissions
  })

  return response.data
})

export const createUserRole = authValidationMiddleware(async (name) => {
  const response = await axiosApiInstance.post(`/paramlists/usuario_rol/`, {
    nombre: name
  })

  return response.data
})

export const updateUserRole = authValidationMiddleware(async ({ id, name }) => {
  const response = await axiosApiInstance.put(`/paramlists/usuario_rol/${id}/`, {
    nombre: name
  })

  return response.data
})

export const deleteUserRole = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.delete(`/paramlists/usuario_rol/${id}/`)

  return response.data
})

export const getUserGroups = authValidationMiddleware(async (params = {}) => {
  const response = await axiosApiInstance.get(`/user_roles/`, { params })

  return response.data
})

export const getUserGroupPermission = authValidationMiddleware(async (id) => {
  const response = await axiosApiInstance.get(`/user_roles/${id}/permissions/`)

  return response.data
})

export const setUserGroupPermission = authValidationMiddleware(async (id, permissions) => {
  const response = await axiosApiInstance.post(`/user_roles/${id}/permissions/`, {
    permissions
  })

  return response.data
})

export const actionsSettignsPassword = () => {
  const endPoint = "/account/settings/security_user_password_rules/"
  return {
    GET: authValidationMiddleware(async () => {
      const response = await axiosApiInstance.get(endPoint)

      return response.data
    })
  }
}
