export const ADD_PROBABILITY_SETTING = "ADD_PROBABILITY_SETTING"
export const ADD_IMPACT_SETTING = "ADD_IMPACT_SETTING"
export const ADD_INHERENT_RISK_SETTING = "ADD_INHERENT_RISK_SETTING"
export const ADD_CRITICALY_SETTING = "ADD_CRITICALY_SETTING"
export const SET_GLOBAL = "SET_GLOBAL"

export function addProbabilitySetting(data = {}) {
  return {
    type: ADD_PROBABILITY_SETTING,
    data
  }
}

export function addImpactSetting(data = {}) {
  return {
    type: ADD_IMPACT_SETTING,
    data
  }
}

export function addInherentRiskSetting(data = {}) {
  return {
    type: ADD_INHERENT_RISK_SETTING,
    data
  }
}

export function addCriticalySetting(data = {}) {
  return {
    type: ADD_CRITICALY_SETTING,
    data
  }
}

export function setGlobal (){
  return {
    type: SET_GLOBAL
  }
}