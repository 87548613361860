import { actionPlan, modelStatus as findModelStatuses } from "../constants/find"
import { statusNotes } from "../constants/status"

export const STATUS_NEW = "new"
export const STATUS_ASSIGNED = "assigned"
export const STATUS_PROCESSING = "processing"
export const STATUS_COMPLETED = "completed"
export const STATUS_REVIEWED = "reviewed"
export const STATUS_REVIEW_NOTES = "review_notes"
// Findings
export const STATUS_DEFAULT = "default"
export const STATUS_PENDING_DISCUSSION = "pendingDiscussion"
export const STATUS_DISCUSSED = "discussed"
export const STATUS_ACCEPTED = "accepted"
export const STATUS_REJECTED = "rejected"
// REQUESTS
export const STATUS_PENDING = "pending"
export const STATUS_RECEIVED = "received"
// ACTIVITIES
export const STATUS_APPROVED = "approved"
export const STATUS_REALIZED = "realized"
export const STATUS_IMPLEMENTED = "implemented"
export const STATUS_EVALUATED = "evaluated"

export default function mapStatus(status = "") {
  switch (status) {
    case "NUEVO":
      return STATUS_NEW
    case "ASIGNADO":
      return STATUS_ASSIGNED
    case "EN_PROCESO":
      return STATUS_PROCESSING
    case "COMPLETADO":
      return STATUS_COMPLETED
    case "REVISADO":
      return STATUS_REVIEWED
    case "NOTA_REVISION":
      return STATUS_REVIEW_NOTES
    case "EN_BORRADOR":
      return STATUS_DEFAULT
    case "SIN_DISCUTIR":
      return STATUS_PENDING_DISCUSSION
    case "DISCUTIDO":
      return STATUS_DISCUSSED
    case "ACEPTADO":
      return STATUS_ACCEPTED
    case "NO_ACEPTADO":
      return STATUS_REJECTED
    case "PENDIENTE":
      return STATUS_PENDING
    case "RECIBIDO":
      return STATUS_RECEIVED
    case "APROBADO":
      return STATUS_APPROVED
    case "REALIZADO":
      return STATUS_REALIZED
    case "IMPLEMENTADO":
      return STATUS_IMPLEMENTED
    case "EVALUADO":
      return STATUS_EVALUATED
    case "RECHAZADO":
      return STATUS_REJECTED
    default:
      return STATUS_NEW
      throw new Error(
        `mapStatus did not expect a status with value "${status}"`
      )
  }
}

export const processesStatus = Object.freeze({
  NEW: {
    value: "NUEVO",
    color: "#ed4138",
    label: "Nuevo",
    score: 0
  },
  ASSIGNED: {
    value: "ASIGNADO",
    color: "#ffae39",
    label: "Asignado",
    score: 0
  },
  IN_PROCESS: {
    value: "EN_PROCESO",
    color: "#ffe543",
    label: "En Proceso",
    score: 0.4
  },
  COMPLETED: {
    value: "COMPLETADO",
    color: "#00a750",
    label: "Completado",
    score: 0.85
  },
  REVIEWED: {
    value: "REVISADO",
    color: "#56aaff",
    label: "Revisado",
    score: 1
  },
  NOTES: {
    value: "NOTA_REVISION",
    color: "#662e93",
    label: "Notas Revisión",
    score: 0.85
  }
})

export const worksheetsModelStatus = Object.freeze({
  ...processesStatus,
  ...findModelStatuses
})

export const mapWorksheetsModelStatus = (value) => {
  return Object.values(worksheetsModelStatus).find((el) => el.value === value)
}

export const mapStatusActionPlan = (value) => {
  return Object.values(actionPlan).find((el) => el.value === value)
}

export const auditStatuses = Object.freeze({
  NOT_STARTED: {
    value: "SIN INICIAR",
    color: "#ed4138",
    label: "Sin iniciar"
  }
})

export const mapAuditStatuses = (value) => {
  switch (value) {
    case auditStatuses.NOT_STARTED.value: {
      return auditStatuses.NOT_STARTED
    }
    default: {
      return null
    }
  }
}

export const mapNotesStatuses = (value) => {
  return Object.values(statusNotes).find((el) => el.value === value)
}
