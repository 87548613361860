import { isValidElement, useState } from "react"
import Button from "../../components/Button"
import Dialog from "../../components/Dialog"
import Icon from "../../components/Icon"
import Loading from "../../components/Loading"

export default function YesNoModal({
  isOpen,
  config = {
    request: async () => {},
    title: "",
    question: "",
    buttons: {
      left: {
        text: "No",
        name: "warning"
      },
      right: {
        text: "Acepto",
        name: "normal"
      }
    }
  },
  className = null,
  style = {},
  onClose = () => {},
  onSuccess = () => {}
}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Dialog
      isOpen={isOpen}
      style={{ height: "auto", width: "50%", ...style }}
      className={className}
      dialogStyle={{ backdropFilter: "blur(8px)" }}
    >
      {isLoading && <Loading color='#fff' />}
      <Dialog.Header background='none' color='#e6e6e6' align='center' buttonClose={false}>
        <Icon name='notification_none' size='80' color='#fff' />
        <h4 style={{ fontSize: 18 }}>{config.title}</h4>
      </Dialog.Header>
      <Dialog.Body background='none' color='#e6e6e6' align='center'>
        <span
          style={{
            lineHeight: "1.5em",
            fontSize: 16,
            color: "white"
          }}
        >
          {isValidElement(config.question) ? (
            config.question
          ) : (
            <div dangerouslySetInnerHTML={{ __html: config.question }} />
          )}
        </span>
      </Dialog.Body>
      <Dialog.Footer background='none' align='right'>
        <Button
          text={config?.buttons?.left?.text}
          name={config?.buttons?.left?.name}
          type='big'
          handleClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            onClose()
          }}
        />
        <Button
          text={config?.buttons?.right?.text}
          name={config?.buttons?.right?.name}
          type='big'
          handleClick={async (event) => {
            event.preventDefault()
            event.stopPropagation()

            try {
              setIsLoading(true)
              if (config.request) {
                const res = await config.request()
                onSuccess(res)
              } else {
                onSuccess(null)
              }
            } catch (error) {
            } finally {
              setIsLoading(false)
            }
          }}
        />
      </Dialog.Footer>
    </Dialog>
  )
}
