import { logError } from "src/services/logger.service"

export function saveError(error, errorInfo, url, user) {
  if (process.env.NODE_ENV === "production") {
    if (typeof user == "undefined") {
      user = localStorage.getItem("user")
    }

    if (["AxiosError: Network Error", "AxiosError: Request failed with status code 400"].includes(error.message)) {
      return
    }

    logError({
      error: error.message,
      errorInfo,
      url,
      user
    })
  }
}
