import cloneDeep from "lodash/cloneDeep"
import { ADD_COMMITTEES, DELETE_COMMITTEE, EDIT_COMMITTEE } from "./actions"

export function makeAuditingCommitteesState() {
  return {
    committees: []
  }
}

export default function auditingCommitteesReducer(
  state = makeAuditingCommitteesState(),
  action = {
    type: "",
    target: "",
    data: [
      {
        id: "",
        date: "",
        hour: "",
        name: "",
        organizer: "",
        description: ""
      }
    ]
  }
) {
  const { type, target, data } = action

  const cleanArray = (v) => (v === null ? [] : v)

  switch (type) {
    case "SET_COMMITTEES": {
      return { ...state, committees: data }
    }
    case ADD_COMMITTEES: {
      const committees = cloneDeep(cleanArray(state.committees))

      committees.push(...data)

      return {
        ...state,
        committees
      }
    }
    case EDIT_COMMITTEE: {
      const committees = cloneDeep(cleanArray(state.committees))

      const committee = committees.find((committee) => committee.id === target) ?? null

      if (committee === null) {
        return { ...state }
      }

      const [newData] = data

      if (typeof newData === "undefined") {
        return { ...state }
      }

      Object.keys(newData).forEach((dataKey) => {
        committee[dataKey] = newData[dataKey]
      })

      return {
        ...state,
        committees
      }
    }
    case DELETE_COMMITTEE: {
      const committeeIdx = cleanArray(state.committees).findIndex((committee) => committee.id === target)

      if (committeeIdx === -1) {
        return { ...state }
      }

      const committees = cloneDeep(cleanArray(state.committees))

      committees.splice(committeeIdx, 1)

      return {
        ...state,
        committees
      }
    }
    default: {
      return state
    }
  }
}
