export const ADD_COMMITTEES = 'ADD_COMMITTEES'
export const EDIT_COMMITTEE = 'EDIT_COMMITTEE'
export const DELETE_COMMITTEE = 'DELETE_COMMITTEE'

export function addCommittees(committees = [
  {
    id: '',
    date: '',
    hour: '',
    name: '',
    organizer: '',
    description: ''
  }
]) {
  return {
    type: ADD_COMMITTEES,
    data: committees
  }
}

export function editCommittee(committeeId = '', committee = {
  date: '',
  hour: '',
  name: '',
  organizer: '',
  description: ''
}) {
  return {
    type: EDIT_COMMITTEE,
    data: [committee],
    target: committeeId
  }
}

export function deleteCommittee(committeeId = '') {
  return {
    type: DELETE_COMMITTEE,
    target: committeeId
  }
}