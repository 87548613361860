import { batch } from "react-redux"
import { getTreeItem } from "../../services/tree.service"
import { getTreeEvaluationItem } from "../../services/treeEvaluation.service"
import { ArrayPropertyUpdateType, updateTreeRow } from "../tree/actions"
import { formatAndParse } from "../tree/helpers"

export const OPEN_WORKSHEET = "OPEN_WORKSHEET"
export const CLOSE_WORKSHEET = "CLOSE_WORKSHEET"
export const CLOSE_WORKSHEETS_AND_NORMALS = "CLOSE_WORKSHEETS_AND_NORMALS"
export const CLOSE_NORMAL_SIDEMODAL = "CLOSE_NORMAL_SIDEMODAL"
export const SET_WORKSHEET_IN_FRONT = "SET_WORKSHEET_IN_FRONT"
export const OPEN_NORMAL_SIDEMODAL = "OPEN_NORMAL_SIDEMODAL"
export const LOAD_WORKSHEET_DATA = "LOAD_WORKSHEET_DATA"
export const LOAD_WORKSHEET_DATA_SUCCESS = "LOAD_WORKSHEET_DATA_SUCCESS"
export const LOAD_WORKSHEET_DATA_FAILURE = "LOAD_WORKSHEET_DATA_FAILURE"
export const MANAGE_WORKSHEET_PROP_ARRAY = "MANAGE_WORKSHEET_ROW_PROP_ARRAY"
export const UPDATE_WORKSHET_DATA = "UPDATE_WORKSHET_DATA"
export const SET_SIDEMODAL_CONTENT = "SET_SIDEMODAL_CONTENT"
export const SET_WORKSHEET_CONTENT = "SET_WORKSHEET_CONTENT"

export function manageWorksheetPropArray(
  options = {
    indexes: [],
    data: {},
    selectorFn: () => [],
    finderNeedle: "",
    finderFn: (item = {}, needle = "") => item.id === needle,
    worksheetKey: "",
    actionType: ArrayPropertyUpdateType.NONE
  }
) {
  const { indexes, data, selectorFn, finderFn, finderNeedle, worksheetKey, actionType } = options

  return {
    type: MANAGE_WORKSHEET_PROP_ARRAY,
    key: worksheetKey,
    indexes,
    data,
    selectorFn,
    finderFn,
    finderNeedle,
    actionType
  }
}

export const openWorksheet = ({ data, indexes, key }) => {
  return {
    type: OPEN_WORKSHEET,
    data,
    indexes,
    key
  }
}

export const openWorksheetNew = ({ data, indexes, key }) => {
  console.log(data, "data")
  // return {
  //   type: OPEN_WORKSHEET,
  //   data,
  //   indexes,
  //   key
  // }
}

export const loadWorksheetData = ({ key }) => {
  return {
    type: LOAD_WORKSHEET_DATA,
    key
  }
}

export const loadWorksheetDataSuccess = ({ key }) => {
  return {
    type: LOAD_WORKSHEET_DATA_SUCCESS,
    key
  }
}

export const loadWorksheetDataFailure = ({ key }) => {
  return {
    type: LOAD_WORKSHEET_DATA_FAILURE,
    key
  }
}

export const setWorksheetInFront = ({ key }) => {
  return {
    type: SET_WORKSHEET_IN_FRONT,
    key
  }
}

export const closeWorksheet = ({ key }) => {
  return {
    type: CLOSE_WORKSHEET,
    key
  }
}

export const openNormalSidemodal = ({ data, indexes, key }) => {
  return {
    type: OPEN_NORMAL_SIDEMODAL,
    data,
    key
  }
}

export const closeNormalSidemodal = ({ key }) => {
  return {
    type: CLOSE_NORMAL_SIDEMODAL,
    key
  }
}

export const setSidemodalContent = ({ key, content }) => {
  return {
    type: SET_SIDEMODAL_CONTENT,
    key,
    content
  }
}

export const setWorksheetContent = ({ key, content }) => {
  return {
    type: SET_WORKSHEET_CONTENT,
    key,
    content
  }
}

export const closeWorksheetsAndNormals = () => {
  return {
    type: CLOSE_WORKSHEETS_AND_NORMALS
  }
}

export const openWorksheetAsync = ({ data, indexes, key, auditId, typeTree = "auditPlan" }) => {
  return async function (dispatch) {
    try {
      if (!["step", "inspection"].includes(key) && data.dataIsLoaded) {
        batch(() => {
          dispatch(loadWorksheetData({ key }))
          dispatch(openWorksheet({ data: {}, indexes, key }))
        })

        setTimeout(() => {
          batch(() => {
            dispatch(updateTreeRow({ indexes, data }))
            dispatch(openWorksheet({ data, indexes, key }))
            dispatch(loadWorksheetDataSuccess({ key }))
          })
        }, 100)
      } else {
        batch(() => {
          dispatch(loadWorksheetData({ key }))
          dispatch(openWorksheet({ data: {}, indexes, key }))
        })

        let _completeData

        switch (typeTree) {
          case "auditPlan":
          case "auditTemplate":
            const { data: completeData } = await getTreeItem({
              auditId,
              itemId: data.id.replace(/-EV$|-PA$/, "")
            })

            if (/-EV$/.test(data.id)) {
              completeData._id = completeData.id
              completeData.id = data.id
              completeData.item_tipo = "HT_EVALUACION"
              completeData.nombre = "Evaluación Plan de Acción " + completeData.codigo
              completeData.isOpen = true
            }

            if (/-PA$/.test(data.id)) {
              completeData._id = completeData.id
              completeData.id = data.id
              completeData.item_tipo = "HT_PLAN_ACCION"
              completeData.nombre = "Plan de Accion " + completeData.codigo
              completeData.isOpen = true
            }

            _completeData = completeData
            if (typeof data.parents !== "undefined") {
              _completeData.parents = data.parents.map((x) => x)
            }
            break
          case "riskEvaluation":
            const { data: completeEvaluationData } = await getTreeEvaluationItem({
              evaluationId: auditId,
              itemId: data.id
            })
            _completeData = completeEvaluationData
            break

          default:
            break
        }

        const parsedData = await formatAndParse(_completeData, typeTree)

        batch(() => {
          dispatch(updateTreeRow({ indexes, data: parsedData }))
          dispatch(openWorksheet({ data: parsedData, indexes, key }))
          dispatch(loadWorksheetDataSuccess({ key }))
        })
      }
    } catch (err) {
      dispatch(loadWorksheetDataFailure({ key }))
    }
  }
}

export const updateWorksheetData = ({ worksheetKey, dataToUpdate, overwrite = false }) => {
  return {
    type: UPDATE_WORKSHET_DATA,
    key: worksheetKey,
    data: dataToUpdate,
    overwrite
  }
}
