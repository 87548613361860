import { merge } from "lodash"
import cloneDeep from "lodash/cloneDeep"
import { ArrayPropertyUpdateType } from "../tree/actions"
import { manageArrayPropData } from "../tree/helpers"
import {
  CLOSE_NORMAL_SIDEMODAL,
  CLOSE_WORKSHEET,
  CLOSE_WORKSHEETS_AND_NORMALS,
  LOAD_WORKSHEET_DATA,
  LOAD_WORKSHEET_DATA_FAILURE,
  LOAD_WORKSHEET_DATA_SUCCESS,
  MANAGE_WORKSHEET_PROP_ARRAY,
  OPEN_NORMAL_SIDEMODAL,
  OPEN_WORKSHEET,
  SET_SIDEMODAL_CONTENT,
  SET_WORKSHEET_CONTENT,
  SET_WORKSHEET_IN_FRONT,
  UPDATE_WORKSHET_DATA
} from "./actions"

export const initialState = {
  baseSidenav: {
    sideOptions: [
      "createPhase",
      "adminSprints",
      "findings",
      "assignMass",
      "importRisks",
      "revisionNotes",
      "requests",
      "exportRisks",
      "trashBin"
    ],
    sideOptionsRisk: [
      "importRisks",
      "revisionNotes",
      "exportAsExcel"
      //"trashBin"
    ],
    sideOptionsMapRisk: ["exportAsExcel"],
    sideOptionsReport: ["exportAsPdf"],
    // sideOptionsFinding: ["findingsMinisystem"]
    sideOptionsFinding: []
  },
  worksheets: {
    flowchart: {
      indexes: [],
      isOpen: false,
      closeConfig: {
        text: false
      },
      order: 0,
      sideOptions: [
        "assignUser",
        "findings",
        "revisionNotes",
        //'changeHistory',
        "timeAllocation",
        "delete"
        //"exportAsPdf"
      ],
      data: {},
      isLoading: false,
      style: {
        width: "calc(100% - 60px)"
      }
    },
    risk: {
      indexes: [],
      isOpen: false,
      closeConfig: {
        text: "Riesgo",
        useCode: true,
        color: "#ed4138"
      },
      order: 0,
      sideOptions: [
        "assignUser",
        "createControl",
        "findings",
        "revisionNotes",
        //'changeHistory',
        //"exportAsExcel",
        "timeAllocation",
        "exportAsPdf",
        "delete"
      ],
      data: {},
      isLoading: false,
      style: {}
    },
    control: {
      indexes: [],
      isOpen: false,
      closeConfig: { text: "Control", color: "#ffae39", useCode: true },
      order: 0,
      sideOptions: [
        "findings",
        "assignUser",
        "revisionNotes",
        //'changeHistory',
        "timeAllocation",
        "exportAsPdf",
        "delete"
      ],
      data: {},
      isLoading: false,
      style: {}
    },
    test: {
      indexes: [],
      isOpen: false,
      closeConfig: {
        text: "Validación",
        color: "#ed4138"
      },
      order: 0,
      sideOptions: [
        "findings",
        "assignUser",
        // 'sampling',
        "revisionNotes",
        //'changeHistory',
        "timeAllocation",
        "exportAsPdf",
        "delete"
      ],
      data: {},
      isLoading: false,
      style: {}
    },
    finding: {
      indexes: [],
      isOpen: false,
      closeConfig: {
        text: "Hallazgo",
        useCode: true,
        color: "#ed4138"
      },
      order: 0,
      sideOptions: ["assignUser", "revisionNotes", "timeAllocation", "exportAsPdf", "delete"],
      data: {},
      isLoading: false,
      style: {}
    },
    acctionPlan: {
      indexes: [],
      isOpen: false,
      closeConfig: {
        text: "Plan de Acción",
        useCode: true,
        color: "#ed4138"
      },
      order: 0,
      sideOptions: [, /*'changeHistory',*/ "timeAllocation", "exportAsPdf"],
      data: {},
      isLoading: false,
      style: {}
    },
    evaluation: {
      indexes: [],
      isOpen: false,
      closeConfig: {
        text: "Evaluación Plan de Acción",
        useCode: true,
        color: "#ed4138"
      },
      order: 0,
      sideOptions: [, /*'changeHistory',*/ "revisionNotes", "timeAllocation", "exportAsPdf"],
      data: {},
      isLoading: false,
      style: {} /** */
    },
    step: {
      indexes: [],
      isOpen: false,
      closeConfig: {
        text: "",
        color: "#00a750"
      },
      order: 0,
      sideOptions: [
        "createInstruction",
        "assignUser",
        "findings",
        "revisionNotes",
        //'changeHistory',
        "timeAllocation",
        "exportAsPdf",
        "delete"
      ],
      data: {},
      isLoading: false,
      style: {}
    },
    inspection: {
      indexes: [],
      isOpen: false,
      closeConfig: {
        text: "",
        color: "#00a750"
      },
      order: 0,
      sideOptions: [
        "assignUser",
        "findings",
        "revisionNotes",
        //'changeHistory',
        "timeAllocation",
        "exportAsPdf",
        "delete"
      ],
      data: {},
      isLoading: false,
      style: {}
    },
    survey: {
      indexes: [],
      isOpen: false,
      closeConfig: {
        text: "",
        color: "#00a750"
      },
      order: 0,
      sideOptions: [
        /*'changeHistory',  "exportAsPdf",*/
        "timeAllocation",
        "delete"
      ],
      data: {},
      isLoading: false,
      style: {}
    }
  },
  normalSidemodals: {
    findings: {
      isOpen: false,
      closeConfig: {},
      order: 0,
      data: {},
      style: {
        width: "fit-content"
      }
    },
    findingsMinisystem: {
      isOpen: false,
      closeConfig: {},
      order: 0,
      data: {},
      style: {
        width: "fit-content"
      }
    },
    notes: {
      isOpen: false,
      closeConfig: {},
      order: 0,
      data: {},
      style: {
        width: "fit-content"
      }
    },
    record: {
      isOpen: false,
      closeConfig: {},
      order: 0,
      data: {},
      style: {
        width: "fit-content"
      }
    },
    requests: {
      isOpen: false,
      closeConfig: {},
      order: 0,
      data: {},
      style: {
        width: "fit-content"
      }
    }
  },
  sideOptions: {
    profile: {
      icon: "sidenav/my-profile",
      color: "transparent"
    },
    createPhase: {
      tooltip: "Añadir Fase",
      icon: "sidenav/add",
      color: "#a8cf45",
      permission: "plan.treeitem__add"
    },
    adminSprints: {
      tooltip: "frontend.auditoria.sprint.admin__title",
      icon: {
        type: "icons",
        icons: ["big calendar alternate outline", "pencil small corner"]
      },
      color: "#a8cf45",
      permission: "plan.treeitem__add"
    },
    assignUser: {
      tooltip: "Asignación de Usuario",
      icon: "sidenav/assign",
      permission: "plan.assign_user"
    },
    assignMass: {
      tooltip: "Asignación masiva",
      icon: "sidenav/assign",
      permission: "plan.mass_assign"
    },
    findings: {
      tooltip: "Hallazgos",
      icon: "sidenav/exclamation-circle",
      willOpenSidemodal: {
        type: 2,
        key: "findings"
      }
    },
    findingsMinisystem: {
      tooltip: "Hallazgos",
      icon: "sidenav/exclamation-circle",
      willOpenSidemodal: {
        type: 2,
        key: "findingsMinisystem"
      }
    },
    revisionNotes: {
      tooltip: "Notas de revisión",
      icon: "sidenav/notes",
      willOpenSidemodal: {
        type: 2,
        key: "notes"
      }
    },
    requests: {
      tooltip: "Requerimientos",
      icon: "sidenav/request",
      willOpenSidemodal: {
        type: 2,
        key: "requests"
      }
    },
    changeHistory: {
      tooltip: "Historial de Cambios",
      icon: "sidenav/history",
      willOpenSidemodal: {
        type: 2,
        key: "record"
      }
    },
    timeAllocation: {
      tooltip: "Cargar horas",
      icon: "sidenav/history-solid",
      permission: "plan.time__add"
    },
    delete: {
      tooltip: "Eliminar",
      icon: "sidenav/thrash-bin",
      permission_dynamic: true
    },
    createInstruction: {
      tooltip: "Añadir instrucción",
      icon: "sidenav/add",
      color: "#a8cf45",
      permission: "plan.test__add"
    },
    createValidation: {
      tooltip: "Crear validación",
      icon: "sidenav/add",
      color: "#a8cf45",
      permission: "plan.test__add"
    },
    sampling: {
      tooltip: "Muestreo",
      icon: "filter_alt"
    },
    createControl: {
      tooltip: "Crear control",
      icon: "arbol_control",
      permission: "plan.control__add"
    },
    exportAsExcel: {
      tooltip: "Exportar como Excel",
      icon: "sidenav/file-excel-regular",
      exportAs: true
    },
    exportAsPdf: {
      tooltip: "Exportar como PDF",
      icon: "sidenav/export-as-pdf",
      exportAs: true
    },
    exportRisks: {
      tooltip: "Exportar Riesgos y Controles",
      icon: "sidenav/file-excel-regular",
      exportAs: true,
      exportModule: "auditoria__tree/risks",
      permission: "plan.risk__export"
    },
    trashBin: {
      tooltip: "main_ui.sidenav.trashbin",
      icon: "sidenav/trash-undo",
      url: "/auditorias/papelera/?auditoria-id={auditoria-id}",
      permission: "trashbin.access"
    },
    importRisks: {
      tooltip: "Importar riesgos",
      icon: "sidenav/import",
      importAs: true,
      permission: "plan.risk__import"
    },

    exportAsWord: {
      tooltip: "Exportar como Word",
      icon: "sidenav/export-as-word",
      exportAs: true
    }
  },
  worksheetInFront: null,
  normalInFront: null,
  worksheetsOpened: 0,
  normalsOpened: 0,
  openWorksheetEventually: ""
}

export default function sidenavContainerReducer(state = initialState, action) {
  const currentWorksheet = state.worksheets[action?.key]
  const currentNormal = state.normalSidemodals[action?.key]

  const currentWorksheetAlreadyOpen = currentWorksheet?.isOpen

  const currentWorksheetIsInFront = state.worksheetInFront === action?.key

  switch (action.type) {
    case OPEN_WORKSHEET: {
      let order
      let worksheetsOpened
      let worksheets = {
        ...state.worksheets
      }
      let normalSidemodals = {
        ...state.normalSidemodals
      }

      if (!currentWorksheetIsInFront) {
        Object.entries(normalSidemodals).forEach(([key, normalSidemodal]) => {
          normalSidemodal.order = 0
          normalSidemodal.isOpen = false
        })
      }

      if (state.worksheetInFront === "flowchart" || action.key === "flowchart") {
        order = 1

        worksheetsOpened = 1

        Object.entries(worksheets).forEach(([key, worksheet]) => {
          worksheet.order = 0
          worksheet.isOpen = false
        })
      } else {
        order = currentWorksheetAlreadyOpen ? state.worksheetsOpened : ++state.worksheetsOpened

        worksheetsOpened = order

        if (currentWorksheetAlreadyOpen && !currentWorksheetIsInFront) {
          Object.entries(worksheets).forEach(([key, worksheet]) => {
            if (worksheet.order > currentWorksheet.order) {
              worksheets[key].order -= 1
            }
          })
        }
      }

      worksheets[action.key] = {
        ...currentWorksheet,
        indexes: action.indexes,
        data: cloneDeep(action.data),
        order,
        isOpen: true
      }

      return {
        ...state,
        worksheets,
        worksheetInFront: action.key,
        worksheetsOpened,
        normalSidemodals,
        sideOptions: {
          ...state.sideOptions,
          assignUser: {
            tooltip: "Asignación de usuarios",
            icon: "sidenav/assign",
            permission: "plan.assign_user"
          }
        }
      }
    }

    case "OPEN_WORKSHEET_EVENTUALLY":
      return {
        ...state,
        openWorksheetEventually: action.value
      }
    case SET_WORKSHEET_IN_FRONT: {
      if (currentWorksheetIsInFront) {
        return state
      }

      const worksheets = {
        ...state.worksheets
      }
      let normalSidemodals = {
        ...state.normalSidemodals
      }

      Object.entries(normalSidemodals).forEach(([key, normalSidemodal]) => {
        normalSidemodal.order = 0
        normalSidemodal.isOpen = false
      })

      Object.entries(worksheets).forEach(([key, sideModal]) => {
        if (sideModal.order > currentWorksheet.order) {
          worksheets[key].order -= 1
        }
      })

      currentWorksheet.order = state.worksheetsOpened

      return {
        ...state,
        worksheets,
        worksheetInFront: action.key,
        normalSidemodals
      }
    }
    case CLOSE_WORKSHEET: {
      let worksheetsOpened = state.worksheetsOpened
      let worksheetInFront = state.worksheetInFront

      let worksheets = {
        ...state.worksheets
      }
      let normalSidemodals = {
        ...state.normalSidemodals
      }

      if (currentWorksheetIsInFront) {
        Object.entries(normalSidemodals).forEach(([key, normalSidemodal]) => {
          normalSidemodal.order = 0
          normalSidemodal.isOpen = false
        })
      }

      Object.entries(worksheets).forEach(([key, worksheet]) => {
        if (worksheet.order > currentWorksheet.order) {
          worksheets[key].order -= 1
        }
      })

      worksheets[action.key] = {
        ...currentWorksheet,
        isOpen: false,
        data: {},
        order: 0
      }

      worksheetsOpened -= 1

      if (worksheetsOpened === 0) {
        worksheetInFront = null
      } else if (worksheetsOpened > 0) {
        const [key] = Object.entries(worksheets).find(([key, worksheet]) => {
          return worksheet.order === worksheetsOpened
        })
        worksheetInFront = key
      }

      return {
        ...state,
        worksheets,
        worksheetInFront,
        worksheetsOpened,
        normalSidemodals
      }
    }
    case CLOSE_WORKSHEETS_AND_NORMALS: {
      let worksheets = {
        ...state.worksheets
      }

      let normalSidemodals = {
        ...state.normalSidemodals
      }

      if (state.worksheetInFront) {
        Object.entries(worksheets).forEach(([key, worksheet]) => {
          worksheet.order = 0
          worksheet.isOpen = false
        })
      }

      if (state.normalInFront) {
        Object.entries(normalSidemodals).forEach(([key, normalSidemodal]) => {
          normalSidemodal.order = 0
          normalSidemodal.isOpen = false
        })
      }

      return {
        ...state,
        worksheets,
        normalSidemodals,
        worksheetInFront: null,
        normalInFront: null,
        worksheetsOpened: 0,
        normalsOpened: 0
      }
    }
    case OPEN_NORMAL_SIDEMODAL: {
      let order = 1
      let normalsOpened = 1
      let normalSidemodals = {
        ...state.normalSidemodals
      }

      Object.entries(normalSidemodals).forEach(([key, normalSidemodal]) => {
        normalSidemodal.order = 0
        normalSidemodal.isOpen = false
      })

      normalSidemodals[action.key] = {
        ...currentNormal,
        indexes: action.indexes,
        data: cloneDeep(action.data),
        order,
        isOpen: true
      }

      return {
        ...state,
        normalSidemodals,
        normalInFront: action.key,
        normalsOpened
      }
    }
    case CLOSE_NORMAL_SIDEMODAL: {
      let normalsOpened = 0
      let normalInFront = null

      let normalSidemodals = {
        ...state.normalSidemodals
      }

      normalSidemodals[action.key] = {
        ...currentNormal,
        isOpen: false,
        data: {},
        order: 0
      }

      return {
        ...state,
        normalSidemodals,
        normalInFront,
        normalsOpened
      }
    }

    case SET_SIDEMODAL_CONTENT: {
      let normalSidemodals = {
        ...state.normalSidemodals
      }

      normalSidemodals[action.key] = {
        ...currentNormal,
        content: action.content
      }

      return {
        ...state,
        normalSidemodals
      }
    }

    case SET_WORKSHEET_CONTENT: {
      let worksheets = {
        ...state.worksheets
      }

      worksheets[action.key] = {
        ...currentWorksheet,
        content: action.content
      }

      return {
        ...state,
        worksheets
      }
    }

    case LOAD_WORKSHEET_DATA: {
      return {
        ...state,
        worksheets: {
          ...state.worksheets,
          [action.key]: {
            ...state.worksheets[action.key],
            isLoading: true
          }
        }
      }
    }

    case LOAD_WORKSHEET_DATA_SUCCESS: {
      return {
        ...state,
        worksheets: {
          ...state.worksheets,
          [action.key]: {
            ...state.worksheets[action.key],
            isLoading: false
          }
        }
      }
    }

    case LOAD_WORKSHEET_DATA_FAILURE: {
      return {
        ...state,
        worksheets: {
          ...state.worksheets,
          [action.key]: {
            ...state.worksheets[action.key],
            isLoading: false
          }
        }
      }
    }

    case MANAGE_WORKSHEET_PROP_ARRAY: {
      const { data, selectorFn, finderFn, finderNeedle, actionType } = action
      if (typeof actionType === "undefined" || actionType === null || actionType === ArrayPropertyUpdateType.NONE) {
        return {
          ...state
        }
      }

      const worksheets = {
        ...state.worksheets
      }
      const worksheet = worksheets[action.key]
      const worksheetData = cloneDeep(worksheet.data)

      manageArrayPropData(selectorFn(worksheetData), data, actionType, finderFn, finderNeedle)

      worksheets[action.key] = {
        ...worksheet,
        data: worksheetData,
        isOpen: true
      }

      return {
        ...state,
        worksheets
      }
    }

    case UPDATE_WORKSHET_DATA: {
      let worksheetDataUpdated

      if (action.overwrite) {
        worksheetDataUpdated = {
          ...state.worksheets[action.key].data,
          ...action.data
        }
      } else {
        worksheetDataUpdated = { ...state.worksheets[action.key].data }

        merge(worksheetDataUpdated, action.data)
      }

      return {
        ...state,
        worksheets: {
          ...state.worksheets,
          [action.key]: {
            ...state.worksheets[action.key],
            data: worksheetDataUpdated
          }
        }
      }
    }

    default:
      return state
  }
}
