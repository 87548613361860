export const priority = Object.freeze({
  LOW: {
    id: 1,
    color: "#00a750",
    label: "Baja"
  },
  MEDIUM: {
    id: 3,
    color: "#ffe543",
    label: "Media"
  },
  HIGH: {
    id: 5,
    color: "#ed4138",
    label: "Alta"
  }
})

export const modelStatus = Object.freeze({
  PENDING: {
    value: "PENDIENTE",
    color: "#ed4138",
    label: "Pendiente"
  },
  RECEIVED: {
    value: "RECIBIDO",
    color: "#00a750",
    label: "Recibido"
  },
  ACCEPTED: {
    value: "ACEPTADO",
    color: "#0a858d",
    label: "Aceptado"
  },
  REJECTED: {
    value: "RECHAZADO",
    color: "#662e93",
    label: "Rechazado"
  }
})
