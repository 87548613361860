export const ADD_REQUIREMENT = 'ADD_REQUIREMENT'
export const DELETE_REQUIREMENT = 'DELETE_REQUIREMENT'
export const POPULATE_REQUIREMENTS = 'POPULATE_REQUIREMENTS'
export const UPDATE_REQUEST = 'UPDATE_REQUEST'

export function addRequirement({ newRequirement }) {
  return {
    type: ADD_REQUIREMENT,
    newRequirement
  }
}

export function deleteRequirement({ requirementId }) {
  return {
    type: DELETE_REQUIREMENT,
    requirementId
  }
}

export function populateRequirements({ requirements }) {
  return {
    type: POPULATE_REQUIREMENTS,
    requirements: requirements
  }
}

export const updateRequest = ({ requestId = '', updatedRequest }) => ({
  type: UPDATE_REQUEST,
  updatedRequest,
  requestId
})