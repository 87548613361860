import { batch } from "react-redux"
import { getTreeItem } from "../../services/tree.service"
import { getTreeEvaluationItem } from "../../services/treeEvaluation.service"
import * as sidenavContainerActions from "../sidenavContainer/actions"
import { formatAndParse } from "./helpers"

export const UPDATE_TREE_ROW = "UPDATE_TREE_ROW"
export const MANAGE_TREE_ROW_PROP_ARRAY = "MANAGE_TREE_ROW_PROP_ARRAY"
export const TREE_ADD = "TREE.ADD"
export const TREE_UPDATE = "TREE.UPDATE"
export const FILTER_TREE = "FILTER_TREE"
export const FILTER_TREE_NEW = "FILTER_TREE_NEW"

export const ArrayPropertyUpdateType = Object.freeze({
  NONE: null,
  ADD: 0,
  UPDATE: 1,
  DELETE: 2
})

export function manageTreeRowPropArray(
  options = {
    indexes: [],
    data: {},
    selectorFn: () => [],
    finderNeedle: "",
    finderFn: (item = {}, needle = "") => item.id === needle,
    actionType: ArrayPropertyUpdateType.NONE
  }
) {
  const { indexes, data, selectorFn, finderFn, finderNeedle, actionType } = options

  return {
    type: MANAGE_TREE_ROW_PROP_ARRAY,
    indexes,
    data,
    selectorFn,
    finderFn,
    finderNeedle,
    actionType
  }
}

export const updateTreeRow = ({ indexes, data, overwrite = false }) => {
  return { type: UPDATE_TREE_ROW, indexes, data, overwrite }
}

export function updateTree({ indexes, data }) {
  return {
    type: TREE_UPDATE,
    indexes,
    data
  }
}

export const updateTreeRowAndWorksheetData = ({ indexes, dataToUpdate, worksheetKey, overwrite = false }) => {
  return function (dispatch) {
    batch(() => {
      dispatch(updateTreeRow({ indexes, data: dataToUpdate, overwrite }))

      dispatch(sidenavContainerActions.updateWorksheetData({ worksheetKey, dataToUpdate, overwrite }))
    })
  }
}

export const updateTreeRowAndWorksheetDataAsync = ({
  indexes,
  worksheetKey,
  typeTree,
  auditId,
  itemId,
  treeConfigs = {},
  evaluation = false
}) => {
  return async function (dispatch) {
    let _completeData

    switch (typeTree) {
      case "auditPlan":
        const { data: completeData } = await getTreeItem({
          auditId,
          itemId
        })
        _completeData = completeData
        break
      case "riskEvaluation":
        const { data: completeEvaluationData } = await getTreeEvaluationItem({
          evaluationId: auditId,
          itemId
        })
        _completeData = completeEvaluationData
        break
    }

    if (evaluation) {
      ;(_completeData.item_tipo = "HT_EVALUACION"),
        (_completeData.id = _completeData?.id + "-EV"),
        (_completeData.type = "evaluation"),
        (_completeData._type = "evaluation"),
        (_completeData.name = "Evaluación Plan de Acción " + _completeData.codigo)
    }

    const parsedData = await formatAndParse(_completeData, typeTree, false, treeConfigs)

    dispatch(
      updateTreeRowAndWorksheetData({
        indexes,
        dataToUpdate: parsedData,
        worksheetKey,
        overwrite: true
      })
    )
  }
}

export const treeAdd = ({ indexes, data, typeTree = "auditPlan", include_items = false }) => {
  return async function (dispatch) {
    dispatch({
      type: TREE_ADD,
      indexes,
      data: await formatAndParse([data], typeTree, include_items)
    })
  }
}

export const manageTreeRowAndWorksheetsPropArray = ({
  indexes,
  data,
  selectorFn,
  finderNeedle,
  finderFn = (item = {}, needle = "") => item.id === needle,
  worksheetKey,
  actionType
}) => {
  return function (dispatch) {
    batch(() => {
      dispatch(
        manageTreeRowPropArray({
          indexes,
          data,
          selectorFn,
          finderNeedle,
          finderFn,
          actionType
        })
      )

      dispatch(
        sidenavContainerActions.manageWorksheetPropArray({
          indexes,
          data,
          selectorFn,
          finderNeedle,
          finderFn,
          worksheetKey,
          actionType
        })
      )
    })
  }
}

export const filterTree = ({ searchText }) => {
  return {
    type: FILTER_TREE,
    searchText
  }
}

export const filterTreeNew = ({ filters }) => {
  return { type: FILTER_TREE_NEW, filters }
}
