import TreeTwoRow from "../../models/treeTwoRow"

export const POPULATE_DATA = "POPULATE_DATA"
export const CREATE_TREE_ITEM = "CREATE_TREE_ITEM"
export const CREATE_EVALUATION = "CREATE_EVALUATION"
export const DELETE_EVALUATION = "DELETE_EVALUATION"
export const UPDATE_EVALUATION = "UPDATE_EVALUATION"
export const UPDATE_EVALUATION_HEADER = "UPDATE_EVALUATION_HEADER"
export const CREATE_AUDIT = "CREATE_AUDIT"
export const CREATE_COMPANY = "CREATE_COMPANY"
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const DELETE_COMPANY = "DELETE_COMPANY"
export const EXPAND_ALL_BY_COMPANY = "EXPAND_ALL_BY_COMPANY"
export const COLLAPSE_ALL_BY_COMPANY = "COLLAPSE_ALL_BY_COMPANY"
export const TOGGLE_EVALUATION_GROUP = "TOGGLE_EVALUATION_GROUP"
export const TOGGLE_EVALUATION = "TOGGLE_EVALUATION"
export const COLLAPSE_EVALUATION = "COLLAPSE_EVALUATION"
export const UPDATE_USER = "UPDATE_USER"

export const populateData = (data) => {
  return {
    type: POPULATE_DATA,
    companies: data
  }
}

export const createTreeItem = (
  newItem = new TreeTwoRow("", "", "", []),
  indexes
) => {
  return {
    type: CREATE_TREE_ITEM,
    newItem,
    indexes
  }
}

export const createEvaluation = ({
  companyId,
  newEvaluation,
  evaluationName,
  creationType
}) => {
  return {
    type: CREATE_EVALUATION,
    newEvaluation,
    companyId,
    evaluationName,
    creationType
  }
}

export const deleteEvaluation = ({
  evaluationId,
  companyId,
  evaluationName
}) => {
  return {
    type: DELETE_EVALUATION,
    evaluationId,
    companyId,
    evaluationName
  }
}

export const updateEvaluation = ({
  evaluationId,
  companyId,
  evaluationName,
  updatedEvaluation
}) => {
  return {
    type: UPDATE_EVALUATION,
    evaluationId,
    companyId,
    evaluationName,
    updatedEvaluation
  }
}

export const updateEvaluationHeader = ({
  companyId,
  evaluationNameBeforeUpdate,
  updatedEvaluationHeader = {
    evaluationName: "",
    businessUnit: {}
  },
  evaluationId
}) => {
  return {
    type: UPDATE_EVALUATION_HEADER,
    companyId,
    evaluationNameBeforeUpdate,
    updatedEvaluationHeader,
    evaluationId
  }
}

export const createAudit = ({
  companyId,
  newAudit,
  evaluationName,
  evaluationId
}) => {
  return {
    type: CREATE_AUDIT,
    newAudit,
    companyId,
    evaluationName,
    evaluationId
  }
}

export const createCompany = ({ id, name }) => {
  return {
    type: CREATE_COMPANY,
    id,
    name
  }
}

export const deleteCompany = (companyId) => {
  return {
    type: DELETE_COMPANY,
    companyId
  }
}

export function updateCompany({ companyId, name }) {
  return {
    type: UPDATE_COMPANY,
    companyId,
    name
  }
}

export const toggleEvaluation = ({
  companyId,
  evaluationName,
  evaluationId
}) => {
  return {
    type: TOGGLE_EVALUATION,
    companyId,
    evaluationName,
    evaluationId
  }
}

export const toggleEvaluationGroup = ({
  companyId,
  evaluationName,
  evaluationId
}) => {
  return {
    type: TOGGLE_EVALUATION_GROUP,
    companyId,
    evaluationName,
    evaluationId
  }
}

export const expandAllByCompany = ({ companyId }) => {
  return {
    type: EXPAND_ALL_BY_COMPANY,
    companyId
  }
}

export const collapseAllByCompany = ({ companyId }) => {
  return {
    type: COLLAPSE_ALL_BY_COMPANY,
    companyId
  }
}
