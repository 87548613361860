export class FreshChat {
  restoreId = null
  storageKey = {
    restoreId: "freshchat__restoreid",
    open: "freshchat__open",
    user: "user"
  }

  get open() {
    const _open = localStorage.getItem(this.storageKey.open) ?? null

    if (typeof _open === "boolean") {
      return _open
    }

    return _open === "true"
  }

  get user() {
    const _user = localStorage.getItem(this.storageKey.user) ?? null

    if (_user === null) {
      return null
    }

    return JSON.parse(_user) ?? {}
  }

  constructor() {
    const restoreId = localStorage.getItem(this.storageKey.restoreId)

    if (typeof restoreId === "undefined" || restoreId === null || restoreId === "") {
      return
    }

    const restoreIdParts = restoreId.split(":")
    const isValid = new Date().toDateString() === new Date(restoreIdParts[0]).toDateString()

    if (!isValid) {
      localStorage.removeItem(this.storageKey.restoreId)
      return
    }

    this.restoreId = restoreIdParts[1] ?? null
  }

  start() {
    if (!("fcWidget" in window)) {
      return
    }

    const freshChatWidget = window.fcWidget ?? null

    if (freshChatWidget === null) {
      return
    }

    freshChatWidget.init({
      token: process.env.NEXT_PUBLIC_FRESHCHAT_TOKEN,
      host: "https://wchat.freshchat.com",
      siteId: "ABI",
      externalId: this.user?.id ?? "Usuario",
      locale: "es",
      restoreId: this.restoreId, //Have the widget open on load by default by setting the below value to true
      open: this.open,
      config: {
        //Disable Events Tracking
        disableEvents: false,
        cssNames: {
          widget: "fc_frame",
          open: "fc_open",
          expanded: "fc_expanded"
        },
        showFAQOnOpen: false,
        hideFAQ: true,
        agent: {
          hideName: true,
          hidePic: true,
          hideBio: true
        },
        headerProperty: {
          appName: "AuditBrain",
          appLogo: "https://app.auditbrain.com/images/logo1.png",
          backgroundColor: "#0a858d",
          foregroundColor: "#ffffff",
          hideChatButton: false //Set Widget to be left to right.
          //direction: 'ltr'
        },
        content: {
          placeholders: {
            search_field: "Buscar",
            reply_field: "Escriba aquí",
            csat_reply: "Escriba sus comentarios aquí"
          },
          headers: {
            chat: "Chat de Soporte",
            chat_help: "Reach out to us if you have any questions"
          }
        }
      }
    })

    const handleOnUserCreate = (response) => {
      const status = response.status
      const data = response.data

      if (status === 200 && typeof data?.restoreId === "string") {
        localStorage.setItem(this.storageKey.restoreId, `${new Date().getTime()}:${data.restoreId}`)
      }
    }

    const handleOnUserObtain = (response) => {
      const currentUserData = {
        firstName: this.user?.first_name ?? "- Anonimo -",
        lastName: this.user?.last_name,
        email: this.user?.email ?? "",
        plan: this.user?.account_name ?? "",
        tipoUsuario: this.user?.user_type ?? ""
      }

      const status = response.status

      if (
        status !== 200 ||
        !response.data ||
        response.data.firstName !== currentUserData.firstName ||
        response.data.lastName !== currentUserData.lastName
      ) {
        freshChatWidget.user.setProperties(currentUserData)

        freshChatWidget.on("user:created", handleOnUserCreate)
      }
    }

    const handleOnWidgetOpen = () => {
      localStorage.setItem(this.storageKey.open, "true")
    }

    const handleOnWidgetClose = () => {
      localStorage.removeItem(this.storageKey.open)
    }

    freshChatWidget.user.get(handleOnUserObtain)
    freshChatWidget.on("widget:opened", handleOnWidgetOpen)
    freshChatWidget.on("widget:closed", handleOnWidgetClose)
  }
}
