export default function pageReducer(state = {}, action) {
  switch (action.type) {
    case "PAGE_SET_TITLE":
      return {
        ...state,
        ...{ title: action.value }
      }

    case "PAGE_LOADING":
      return {
        ...state,
        ...{ loading: action.value }
      }

    case "PAGE_SETNAVITEMS":
      return {
        ...state,
        ...{ navitems: action.value }
      }

    case "PAGE_PUSHNAVITEMS":
      const navitems = state.navitems ?? []
      try {
        action.value.forEach((x) => {
          const existing = navitems.find((y) => (typeof x.id !== "undefined" ? y.id == x.id : y.tooltip == x.tooltip))
          if (!existing) {
            navitems.push(x)
          }
        })
      } catch (e) {
        console.error(e)
      }

      return {
        ...state,
        navitems
      }

    case "PAGE_PUSH_TOPALERT":
      const { topAlerts } = state
      if (action.id) {
        const found = topAlerts.find((x) => x.id === action.id)
        if (!found) {
          topAlerts.push({ alert: action.value, id: action.id })
        }
      } else {
        topAlerts.push({ alert: action.value })
      }

      return {
        ...state,
        ...topAlerts
      }

    case "PAGE_SETCONFIG":
      return {
        ...state,
        ...{ configs: { ...state.configs, ...{ [action.config]: action.value } } }
      }

    default:
      return { ...state }
  }
}
