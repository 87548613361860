import formatFunc from "date-fns/format"
import isValidDate from "date-fns/isValid"
import parse from "date-fns/parse"
import parseISO from "date-fns/parseISO"
import { settings } from "src/constants/userSettings"
const { utcToZonedTime } = require("date-fns-tz")

export const ShortISOFormat = "yyyy-MM-dd"

export function dateParser(value = "", format = null) {
  format = format ?? settings.dateFormat
  const fallbackFormat = settings.fallbackDateFormat

  if (
    typeof value === "undefined" ||
    value === null ||
    (typeof value === "string" && (value === "" || value === "ERROR"))
  ) {
    return null
  }

  try {
    if (value.length === format.length) {
      const result = parse(value, format, new Date())

      if (!isValidDate(result)) {
        return parse(value, fallbackFormat, new Date())
      }

      return result
    }

    return parseISO(value)
  } catch (error) {
    console.error("dateParser", { error, value, format })

    return null
  }
}

export function dateFormatter(value = new Date(), format = null, parseFormat = null) {
  format = format ?? settings.dateFormat

  if (value === null || typeof value === "undefined") {
    return null
  }

  try {
    if (typeof value === "string") {
      return parseFormat === null
        ? formatFunc(parseZonedDate(parseISO(value)), format)
        : formatFunc(parseZonedDate(parse(value, parseFormat, new Date())), format)
    }

    return formatFunc(value, format)
  } catch (error) {
    console.error("dateFormatter", { error, value, format })

    return "ERROR"
  }
}

export function parseZonedDate(date) {
  return utcToZonedTime(new Date(date), settings.tz)
}
