import { cloneDeep } from "lodash"
import {
  COLLAPSE_ALL_PERSONAL,
  EXPAND_ALL_PERSONAL,
  POPULATE_DATA,
  TOGGLE_PERSONAL_ALLOWANCE_GROUP,
  CREATE_MEMBER,
  UPDATE_MEMBER,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECT_GROUP
} from "./actions"
import {
  getBudgetedCost,
  getBudgetedHours,
  parseItemsByProject,
  parsePersonalByProject,
  parsePersonalByUser,
  parseTeamMember,
  parseUser
} from "./helpers"

export const initialState = {
  byProject: [],
  byUser: [],
  isLoaded: false
}
export default function personalAllowanceReducer(state = initialState, action) {
  switch (action.type) {
    case POPULATE_DATA: {
      return {
        ...state,
        byProject: parsePersonalByProject(action.byProject),
        byUser: parsePersonalByUser(action.byUser),
        isLoaded: true
      }
    }
    case TOGGLE_PERSONAL_ALLOWANCE_GROUP: {
      const id = action.userId ? action.userId : action.projectId
      const updatedPersonal = action.userId ?
        [...state.byUser] :
        [...state.byProject]

      const found = updatedPersonal.find(
        (el) => el.id === id
      )

      found.open = !found.open

      return {
        ...state,
        byProject: action.projectId ? updatedPersonal : [...state.byProject],
        byUser: action.userId ? updatedPersonal : [...state.byUser],
      }
    }

    case CREATE_MEMBER: {
      const memberByProjectUpdated = [...state.byProject]
      if (
        typeof memberByProjectUpdated.find(
          (el) => el.id === action.projectId
        ) !== 'undefined'
      ) {
        const { items } = memberByProjectUpdated.find(
          (el) => el.id === action.projectId
        )
        items.push(parseItemsByProject(action.newMember))
        memberByProjectUpdated.forEach((item, key) => {
          if (item.id === action.projectId) {
            memberByProjectUpdated[key].assignedHours = getBudgetedHours(items)
            memberByProjectUpdated[key].budgetedCost = getBudgetedCost(items)
          }
        })
      }
      return {
        ...state,
        byProject: memberByProjectUpdated
      }
    }

    case CREATE_PROJECT: {
      const projectByUserUpdated = [...state.byUser]
      const { id: userId, name: userName, sigla: userSigla } = action.user
      const { id: rolId, name: rolName } = action.rol
      if (
        typeof projectByUserUpdated.find(
          (el) => el.id === userId
        ) !== 'undefined'
      ) {
        const { items } = projectByUserUpdated.find(
          (el) => el.id === userId
        )
        items.push(action.newProject)
        projectByUserUpdated.forEach((item, key) => {
          if (item.id === userId) {
            projectByUserUpdated[key].assignedHours = getBudgetedHours(items)
            projectByUserUpdated[key].budgetedCost = getBudgetedCost(items)
          }
        })
      } else {
        const teamMember = {
          usuario: {
            id: userId,
            name: userName,
            sigla: userSigla
          },
          rol: {
            id: rolId,
            nombre: rolName
          }
        }
        projectByUserUpdated.push({
          ...parseTeamMember(teamMember, action.auditId),
          ...parseUser(action.newProject)
        })
      }
      return {
        ...state,
        byUser: projectByUserUpdated
      }
    }

    case UPDATE_MEMBER: {
      const memberByProjectUpdated = cloneDeep([...state.byProject])
      const { items } = memberByProjectUpdated.find((el) => el.id === action.projectId)
      const newData = action.updatedMember
      items.forEach((item, key) => {
        if (item.id === action.memberId) {
          items[key] = newData
        }
      })
      memberByProjectUpdated.forEach((item, key) => {
        if (item.id === action.projectId) {
          memberByProjectUpdated[key].assignedHours = getBudgetedHours(items)
          memberByProjectUpdated[key].budgetedCost = getBudgetedCost(items)
        }
      })
      return {
        ...state,
        byProject: memberByProjectUpdated
      }
    }

    case UPDATE_PROJECT: {
      const memberByUserUpdated = cloneDeep([...state.byUser])
      const { items } = memberByUserUpdated.find((el) => el.id === action.memberId)
      const newData = action.updatedProject

      items.forEach((item, key) => {
        if (item.id === action.teamMemberId) {
          const { auditId, label } = items[key]
          items[key] = {
            auditId,
            label,
            ...newData
          }

        }
      })
      memberByUserUpdated.forEach((item, key) => {
        if (item.id === action.memberId) {
          memberByUserUpdated[key].assignedHours = getBudgetedHours(items)
          memberByUserUpdated[key].budgetedCost = getBudgetedCost(items)
        }
      })
      return {
        ...state,
        byUser: memberByUserUpdated
      }
    }

    case UPDATE_PROJECT_GROUP: {
      const projectUpdated = cloneDeep([...state.byProject])
      const newData = action.updatedProject
      projectUpdated.forEach((item, key) => {
        if (item.id === action.projectId) {
          const {
            icon,
            items,
            assignedHours,
            budgetedCost,
            open
          } = item
          projectUpdated[key] = {
            icon,
            items,
            assignedHours,
            budgetedCost,
            open,
            ...newData
          }

        }
      })
      return {
        ...state,
        byProject: projectUpdated
      }
    }

    case COLLAPSE_ALL_PERSONAL: {
      const updatedPersonal = !action.byProject ?
        [...state.byUser] :
        [...state.byProject]

      updatedPersonal.forEach((el) => {
        el.open = false
      })

      return {
        ...state,
        byProject: action.byProject ? updatedPersonal : [...state.byProject],
        byUser: !action.byProject ? updatedPersonal : [...state.byUser],
      }
    }

    case EXPAND_ALL_PERSONAL: {
      const updatedPersonal = !action.byProject ?
        [...state.byUser] :
        [...state.byProject]

      updatedPersonal.forEach((el) => {
        el.open = true
      })

      return {
        ...state,
        byProject: action.byProject ? updatedPersonal : [...state.byProject],
        byUser: !action.byProject ? updatedPersonal : [...state.byUser],
      }
    }

    default:
      return state
  }
}