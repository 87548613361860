import {
  ADD_RISK,
  POPULATE_RISKS,
  DELETE_RISK,
  DELETE_SPECIFIC_RISK,
  UPDATE_RISK,
  CREATE_RISK,
  UPDATE_SPECIFIC_RISK,
  CREATE_SPECIFIC_RISK
} from "./actions"

export const initialState = {
  general: [],
  factors: [],
  objectives: [],
  isLoaded: false
}

export default function risksReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_RISK:
      if (action.id !== null) {
        state[action.key].map((el, index) => {
          if (el.id === action.id) {
            el.riesgos_especificos.push(action.riskCreated)
          }
        })
        return {
          ...state
        }
      } else {
        return {
          ...state,
          [action.key]: [...state[action.key], action.riskCreated]
        }
      }

    case DELETE_RISK: {
      const updatedRisk = [...state[action.key]]

      const indexFound = updatedRisk.findIndex((el) => el.id === action.id)

      updatedRisk.splice(indexFound, 1)

      return {
        ...state,
        [action.key]: updatedRisk
      }
    }

    case UPDATE_RISK: {
      const updatedRisk = [...state[action.key]]

      const found = updatedRisk.find((el) => el.id === action.id)

      found.nombre = action.name

      return {
        ...state,
        [action.key]: updatedRisk
      }
    }

    case CREATE_RISK: {
      const updatedRisk = [...state[action.key]]

      updatedRisk.push(action.newRisk)

      return {
        ...state,
        [action.key]: updatedRisk
      }
    }

    case UPDATE_SPECIFIC_RISK: {
      const updatedRisk = [...state.general]

      const found = updatedRisk.find(
        (el) => el.id === action.generalId
      ).riesgos_especificos

      const indexFound = found.findIndex((el) => el.id === action.specificId)

      found[indexFound].nombre = action.name

      return {
        ...state,
        general: updatedRisk
      }
    }

    case DELETE_SPECIFIC_RISK: {
      const updatedRisk = [...state.general]

      const found = updatedRisk.find(
        (el) => el.id === action.generalId
      ).riesgos_especificos

      const indexFound = found.findIndex((el) => el.id === action.specificId)

      found.splice(indexFound, 1)

      return {
        ...state,
        general: updatedRisk
      }
    }

    case CREATE_SPECIFIC_RISK: {
      const updatedRisk = [...state.general]

      const found = updatedRisk.find(
        (el) => el.id === action.generalId
      ).riesgos_especificos

      found.push(action.newRisk)

      return {
        ...state,
        general: updatedRisk
      }
    }

    case POPULATE_RISKS:
      return {
        ...state,
        general: action.risks.general,
        factors: action.risks.factors,
        objectives: action.risks.objectives,
        isLoaded: true
      }

    default:
      return state
  }
}
