import { cloneDeep, sortBy } from "lodash"
import {
  COLLAPSE_ALL_BY_COMPANY,
  CREATE_AUDIT,
  CREATE_COMPANY,
  CREATE_EVALUATION,
  CREATE_TREE_ITEM,
  DELETE_COMPANY,
  DELETE_EVALUATION,
  EXPAND_ALL_BY_COMPANY,
  POPULATE_DATA,
  TOGGLE_EVALUATION,
  TOGGLE_EVALUATION_GROUP,
  UPDATE_COMPANY,
  UPDATE_EVALUATION,
  UPDATE_EVALUATION_HEADER
} from "./actions"
import { creationTypes } from "./helpers"

function parseEvaluations(companies = []) {
  if (typeof companies === "undefined" || companies === null || !Array.isArray(companies)) {
    return [[], {}]
  }
  const { companies: resultCompanies, evaluationsByCompany: resultEvaluationsByCompany } = companies.reduce(
    (agg, value) => {
      agg.companies.push({
        nombre: value["nombre"] ?? "",
        id: value["id"] ?? ""
      })

      if ("unidades_negocio" in value && Array.isArray(value.unidades_negocio)) {
        const { _array } = value.unidades_negocio.reduce(
          (evaluationsAgg, businessUnit) => {
            if ("evaluaciones" in businessUnit && Array.isArray(businessUnit.evaluaciones)) {
              businessUnit.evaluaciones.forEach((businessEvaluation) => {
                let _evaluation
                const evaluationGrouperId = `${businessEvaluation.nombre}-${businessEvaluation.unidad_negocio.id}`
                if (evaluationGrouperId in evaluationsAgg._map) {
                  _evaluation = evaluationsAgg._map[evaluationGrouperId]

                  _evaluation.evaluations.push(businessEvaluation)
                } else {
                  _evaluation = {
                    nombre: businessEvaluation.nombre,
                    evaluationId: businessEvaluation.id,
                    evaluations: [businessEvaluation],
                    businessUnit: businessEvaluation.unidad_negocio,
                    lastEvaluationYear: businessEvaluation.ano_ultima_auditoria,
                    rotation: businessEvaluation.rotacion,
                    open: false
                  }

                  evaluationsAgg._map[evaluationGrouperId] = _evaluation

                  evaluationsAgg._array.push(_evaluation)
                }
              })
            }
            return evaluationsAgg
          },
          {
            _map: {},
            _array: []
          }
        )

        _array.forEach((el, key) => {
          const _evaluation = el.evaluations.find((e) => "empresa" in e && e.empresa?.id === value.id)

          if (typeof _evaluation !== "undefined" && _evaluation !== null) {
            _array[key].startDate = _evaluation.fecha_inicio
          }
        })
        agg.evaluationsByCompany[value["id"]] = sortBy(_array, "startDate")
      }

      return agg
    },
    {
      companies: [],
      evaluationsByCompany: {}
    }
  )
  return [resultCompanies, resultEvaluationsByCompany]
}

export const initialState = {
  companies: [],
  evaluationsByCompanies: {},
  isLoaded: false
}

export default function evaluationsReducer(state = initialState, action) {
  switch (action.type) {
    case POPULATE_DATA: {
      const [companies, evaluationsByCompanies] = parseEvaluations(action.companies)
      return {
        ...state,
        companies,
        evaluationsByCompanies,
        isLoaded: true
      }
    }

    case "EVALUACION_COMPANY_SET_BU":
      const companies = state.companies?.map((item) => {
        if (item.id == action.empresa) {
          item["businessUnits"] = action.value
        }

        return item
      })
      return {
        ...state,
        companies: companies
      }

    case CREATE_TREE_ITEM: {
      const updatedCompanies = [...state.companies]

      if (action.indexes && action.indexes.length > 0) {
        const traverse = (_indexes, _rows) => {
          const indexTofind = _indexes[0]

          _indexes.shift()

          const rowFound = _rows[indexTofind]

          if (_indexes.length > 0) {
            traverseRows(_indexes, rowFound.items)
          } else {
            rowFound.items.push(action.newItem)
          }
        }

        traverse(action.indexes, updatedCompanies)
      } else {
        updatedCompanies.push(action.newItem)
      }

      return {
        ...state,
        companies: updatedCompanies
      }
    }

    case CREATE_EVALUATION: {
      const evaluationsByCompaniesUpdated = { ...state.evaluationsByCompanies }

      switch (action.creationType) {
        case creationTypes.NEW_EVALUATION_FROM_SCRATCH: {
          evaluationsByCompaniesUpdated[action.companyId].push({
            nombre: action.evaluationName,
            evaluations: [action.newEvaluation],
            evaluationId: action.newEvaluation.id,
            businessUnit: action.newEvaluation.unidad_negocio,
            lastEvaluationYear: action.newEvaluation.ano_ultima_auditoria,
            rotation: action.newEvaluation.rotacion,
            open: true
          })
          break
        }
        case creationTypes.NEW_EVALUATION_BASED_ON_AN_EVALUATION_ALREADY_CREATED:
          {
            const found = evaluationsByCompaniesUpdated[action.companyId].find(
              (el) => el.nombre === action.evaluationName
            )

            found.evaluations.push(action.newEvaluation)
          }
          break
      }

      return {
        ...state,
        evaluationsByCompanies: evaluationsByCompaniesUpdated
      }
    }

    case DELETE_EVALUATION: {
      const evaluationsByCompaniesUpdated = { ...state.evaluationsByCompanies }

      const evaluationGroups = evaluationsByCompaniesUpdated[action.companyId]

      const evaluationGroupIndex = evaluationGroups.findIndex((el) => el.nombre === action.evaluationName)

      const currentEvaluationGroup = evaluationGroups[evaluationGroupIndex]

      const { evaluations } = currentEvaluationGroup

      if (evaluations.length === 1) {
        evaluationGroups.splice(evaluationGroupIndex, 1)
      } else if (evaluations.length > 1) {
        const index = evaluations.findIndex((el) => el.id === action.evaluationId)

        evaluations.splice(index, 1)
      }

      return {
        ...state,
        evaluationsByCompanies: evaluationsByCompaniesUpdated
      }
    }

    case UPDATE_EVALUATION: {
      const evaluationsByCompaniesUpdated = cloneDeep({
        ...state.evaluationsByCompanies
      })

      const { evaluations } = evaluationsByCompaniesUpdated[action.companyId].find(
        (el) => el.nombre === action.evaluationName
      )

      const newData = action.updatedEvaluation

      /*Object.keys(newData).forEach((dataKey) => {
        evaluations[0][dataKey] = newData[dataKey]
      })*/

      evaluations.forEach((item, key) => {
        if (item.id === action.evaluationId) {
          evaluations[key] = newData
        }
      })

      return {
        ...state,
        evaluationsByCompanies: evaluationsByCompaniesUpdated
      }
    }

    case UPDATE_EVALUATION_HEADER: {
      const evaluationsByCompaniesUpdated = { ...state.evaluationsByCompanies }

      const found = evaluationsByCompaniesUpdated[action.companyId].find(
        (el) => el.nombre === action.evaluationNameBeforeUpdate && el.evaluationId === action.evaluationId
      )

      if (found) {
        found.nombre = action.updatedEvaluationHeader.evaluationName
        found.businessUnit = action.updatedEvaluationHeader.businessUnit
      }

      return {
        ...state,
        evaluationsByCompanies: evaluationsByCompaniesUpdated
      }
    }

    case CREATE_AUDIT: {
      const evaluationsByCompaniesUpdated = { ...state.evaluationsByCompanies }

      if (
        typeof evaluationsByCompaniesUpdated[action.companyId].find((el) => el.nombre === action.evaluationName) !==
        "undefined"
      ) {
        const { evaluations } = evaluationsByCompaniesUpdated[action.companyId].find(
          (el) => el.nombre === action.evaluationName
        )

        const { auditorias } = evaluations.find((el) => el.id === action.evaluationId)

        auditorias.push(action.newAudit)
      }

      return {
        ...state,
        evaluationsByCompanies: evaluationsByCompaniesUpdated
      }
    }

    case CREATE_COMPANY: {
      const updatedCompanies = [...state.companies]
      updatedCompanies[updatedCompanies.length] = {
        id: action.id,
        nombre: action.name
      }
      const updatedEvaluationsByCompanies = { ...state.evaluationsByCompanies }
      updatedEvaluationsByCompanies[action.id] = []

      return {
        ...state,
        companies: updatedCompanies,
        evaluationsByCompanies: updatedEvaluationsByCompanies
      }
    }

    case UPDATE_COMPANY: {
      const updatedCompanies = [...state.companies]

      const index = updatedCompanies.find((el) => el.id === action.companyId)

      index.nombre = action.name

      return {
        ...state,
        companies: updatedCompanies
      }
    }

    case DELETE_COMPANY: {
      const updatedCompanies = [...state.companies]

      const index = updatedCompanies.findIndex((el) => el.id === action.companyId)

      updatedCompanies.splice(index, 1)

      return {
        ...state,
        companies: updatedCompanies
      }
    }

    case COLLAPSE_ALL_BY_COMPANY: {
      const updatedEvaluationsByCompanies = { ...state.evaluationsByCompanies }

      updatedEvaluationsByCompanies[action.companyId].forEach((el) => {
        el.open = false

        el.evaluations.forEach((_evaluation) => {
          _evaluation.open = false
        })
      })

      return {
        ...state,
        evaluationsByCompanies: updatedEvaluationsByCompanies
      }
    }

    case EXPAND_ALL_BY_COMPANY: {
      const updatedEvaluationsByCompanies = { ...state.evaluationsByCompanies }

      updatedEvaluationsByCompanies[action.companyId].forEach((el) => {
        el.open = true

        el.evaluations.forEach((_evaluation) => {
          _evaluation.open = true
        })
      })

      return {
        ...state,
        evaluationsByCompanies: updatedEvaluationsByCompanies
      }
    }

    case TOGGLE_EVALUATION_GROUP: {
      const updatedEvaluationsByCompanies = { ...state.evaluationsByCompanies }

      const found = updatedEvaluationsByCompanies[action.companyId].find(
        (el) => el.evaluationId === action.evaluationId
      )

      found.open = !found.open

      return {
        ...state,
        evaluationsByCompanies: updatedEvaluationsByCompanies
      }
    }

    case TOGGLE_EVALUATION: {
      const updatedEvaluationsByCompanies = { ...state.evaluationsByCompanies }

      const found = updatedEvaluationsByCompanies[action.companyId].find((el) => el.nombre === action.evaluationName)

      const evaluationFound = found.evaluations.find((_evaluation) => _evaluation.id === action.evaluationId)

      evaluationFound.open = !evaluationFound.open

      return {
        ...state,
        evaluationsByCompanies: updatedEvaluationsByCompanies
      }
    }

    default:
      return state
  }
}
