import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import useDispatch from "../store"

export default function usePageHead() {
  const dispatch = useDispatch()

  const pageTitle = useSelector((state) => state.page.title || "")
  const { t } = useTranslation()

  const setPageTitle = (...newTitle) => {
    if (typeof newTitle === "undefined") {
      newTitle = []
    }

    if (Array.isArray(newTitle[0])) {
      newTitle = newTitle[0]
    }
    newTitle = newTitle
      .filter((l) => l !== null)
      .map((l) => {
        return l == null ? "" : t(`page.${l}`, l)
      })

    if (newTitle.join(" \u00BB ") !== pageTitle) {
      dispatch({ type: "PAGE_SET_TITLE", value: newTitle.join(" \u00BB ") })
    }
  }

  return {
    pageTitle,
    setPageTitle
  }
}
