import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { useRouter } from "next/router"
import { Popup } from "semantic-ui-react"
import Icon from "src/components/Icon"
import useTranslation from "src/hooks/useTranslation"
import styles from "./Button.module.scss"

/**
 * @typedef {import('react').CSSProperties} CSSProperties
 */

/**
 *
 * @param {object} props
 * @param {(string|string[]|JSX.Element)} props.text - The button text.
 * @param {string} props.type -
 * @param {function} props.handleClick - The onClick handler.
 * @param {(string|JSX.Element)} props.icon - The button icon. It renders before the text.
 * @param {(number)} props.iconSize - The button icon size.
 * @param {boolean} props.disabled - If the button is disabled. Defaults to False.
 * @param {string} props.className - The component classnames.
 * @param {(Object.<string, boolean>|string)} props.disabledText - The tooltip text to show explaining the disabled state.
 * @param {boolean} [props.loading=false] - If the button is in loading state. Defaults to False.
 * @param {boolean} [props.submit=false] - If the button is a submit button. Defaults to False.
 * @param {CSSProperties} props.style - The component style.
 * @param {string} props.disabledHeaderText - The tooltip header text disable. Defaults is "main_ui.general.lb_disabled_option_title".
 * @param {(string|{pathname:string, query:string})} props.href - Attribute href when button is render as link (tag a).
 * @param {string} props.target - Attribute target when button is render as link (tag a).
 */
export default function Button({
  text = null,
  name = null,
  type = "big",
  icon = null,
  iconSize = null,
  handleClick = null,
  forwardRef = null,
  href = null,
  target = null,
  styleMacComponent = false,
  disabled = false,
  colorText = "",
  submit = false,
  loading = false,
  style = {},
  className = null,
  disabledText = null,
  disabledHeaderText = "main_ui.general.lb_disabled_option_title",
  ...otherProps
}) {
  const { t } = useTranslation()

  if (disabledText && typeof disabledText == "object") {
    const f = Object.entries(disabledText).find(([text, condition]) => condition)
    disabledText = f ? f[0] : null
    if (disabledText && typeof disabledText === "string") {
      disabledText = t(disabledText, disabledText)
    }
  }
  if (disabledText === false) {
    disabledText = null
  }

  if (disabledText !== null) {
    disabled = true
  }

  const classPaddingButton = icon ? `${styles.paddingButtonIcon}` : `${styles.paddingButton}`
  const sizeIcon = iconSize === null ? (type === "big" || type === "medium" ? 20 : 18) : iconSize
  const colorIcon = name === "white" || name === "gray" ? "#000" : "#fff"
  let cIcon = icon ? (
    typeof icon === "string" ? (
      icon.startsWith("ui icon") ? (
        <i className={icon} />
      ) : (
        <Icon name={icon} size={sizeIcon} color={colorIcon} />
      )
    ) : (
      icon
    )
  ) : (
    ""
  )
  const classRadius = styleMacComponent ? `${styles.radiusMacroComponent}` : `${styles.radiusNormal}`
  const pillClassName = cx(
    styles.general,
    styles[name],
    styles[type],
    classPaddingButton,
    classRadius,
    disabled && "disabled",
    className
  )

  const buildButtonText = (text) => {
    if (typeof text === "string") {
      text = t(["main_ui.general." + text, text], text)
    } else if (Array.isArray(text)) {
      text = t(text[0], text[1])
    }

    return text
  }

  if (loading) {
    disabled = true
    handleClick = null
    text = typeof loading === "string" ? t([`main_ui.general.${loading}`, loading], loading) : buildButtonText(text)
    icon = true
    cIcon = <FontAwesomeIcon icon={faSyncAlt} spin />
  } else {
    text = buildButtonText(text)
  }

  const router = useRouter()
  if (href && typeof href === "object") {
    if (!href.pathname) {
      href.pathname = router.pathname
    }
    if (href.query) {
      href =
        `${href.pathname}/?` +
        Object.entries(href.query)
          .map(([k, v]) => k + "=" + v)
          .join("&")
    } else {
      href = href.pathname
    }
  }

  const renderButton = () => (
    <button
      disabled={disabled}
      className={pillClassName}
      onClick={disabled ? null : handleClick}
      ref={forwardRef}
      type={submit ? "submit" : "button"}
      style={style}
      {...otherProps}
    >
      {icon ? <div className={styles.buttonIcon}>{cIcon} </div> : null}
      <div style={{ color: colorText }}>{text}</div>
    </button>
  )
  const rendeLink = () => (
    <a
      target={target}
      disabled={disabled}
      className={pillClassName}
      onClick={disabled ? null : handleClick}
      ref={forwardRef}
      href={disabled ? null : href}
      type={submit ? "submit" : "button"}
      style={style}
      {...otherProps}
    >
      {icon ? <div className={styles.buttonIcon}>{cIcon} </div> : null}
      <div style={{ color: colorText }}>{text}</div>
    </a>
  )

  const component = href ? rendeLink() : renderButton()

  return disabledText === null ? (
    component
  ) : (
    <Popup
      content={disabledText}
      header={t(disabledHeaderText, disabledHeaderText)}
      className='disabled-op'
      size='small'
      disabled={disabledText === null}
      trigger={<div style={{ cursor: "not-allowed" }}>{component}</div>}
    />
  )
}
