import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"

const appInsightsReactPlugin = new ReactPlugin()

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.NEXT_PUBLIC_AZURE_APPINSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    disableCookiesUsage: true,
    disableTelemetry:
      typeof window === "undefined" || process.env.NODE_ENV === "development" || process.env.NEXT_PUBLIC_DEV_SERVER,
    excludeRequestFromAutoTrackingPatterns: [/^\/_next/],
    extensions: [appInsightsReactPlugin]
  }
})

if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_AZURE_APPINSIGHTS_CONNECTION_STRING) {
  appInsights.loadAppInsights()

  let user = localStorage.getItem("user")
  if (user) {
    try {
      user = JSON.parse(user)
      appInsights.setAuthenticatedUserContext(user.id)
    } catch (e) {
      // no-op
    }
  }
}

const Wrapper = (Component) => withAITracking(appInsightsReactPlugin, Component)
export default Wrapper
export { appInsights, appInsightsReactPlugin }
