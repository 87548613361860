import { rowBackendType } from "./rowTypes"

export const statusFinding = [
  {
    id: "NUEVO",
    name: "En borrador"
  },
  {
    id: "SIN_DISCUTIR",
    name: "Sin discutir"
  },
  {
    id: "COMPLETADO",
    name: "Discutido"
  },
  {
    id: "ACEPTADO",
    name: "Aceptado"
  },
  {
    id: "NO_ACEPTADO",
    name: "No Aceptado"
  },
  {
    id: "EVALUADO",
    name: "Evaluado"
  }
]

export const statusType = [
  {
    id: "NUEVO",
    name: "Nuevo"
  },
  {
    id: "ASIGNADO",
    name: "Asignado"
  },
  {
    id: "EN_PROCESO",
    name: "En proceso"
  },
  {
    id: "COMPLETADO",
    name: "Completado"
  },
  [
    {
      id: "REVISADO",
      name: "Revisado"
    },
    {
      id: "NOTA_REVISION",
      name: "Notas Revisión"
    }
  ]
]

export const statusRequest = [
  {
    id: "PENDIENTE",
    name: "Pendiente"
  },
  {
    id: "RECIBIDO",
    name: "Recibido"
  },
  {
    id: "ACEPTADO",
    name: "Aceptado"
  },
  {
    id: "RECHAZADO",
    name: "Rechazado"
  }
]

export const statusNotes = Object.freeze({
  NEW: {
    value: "NUEVO",
    color: "#ed4138",
    label: "Nuevo"
  },
  COMPLETED: {
    value: "COMPLETADO",
    color: "#00a750",
    label: "Completado"
  },
  REVIEWED: {
    value: "REVISADO",
    color: "#56aaff",
    label: "Revisado"
  }
})

export const typeWorkSheets = (t) => {
  return [
    {
      id: rowBackendType.HT_UNDERSTANDING,
      name: t("frontend.auditoria.treeitem.entendimiento")
    },
    {
      id: rowBackendType.HT_RISK,
      name: t("frontend.auditoria.treeitem.riesgo")
    },
    {
      id: rowBackendType.HT_CONTROL,
      name: t("frontend.auditoria.treeitem.control")
    },
    {
      id: rowBackendType.HT_TEST,
      name: t("frontend.auditoria.treeitem.control_test")
    },
    {
      id: rowBackendType.HT_FINDING,
      name: t("frontend.auditoria.treeitem.hallazgo")
    },
    {
      id: rowBackendType.HT_STEP,
      name: `${t("frontend.auditoria.treeitem.paso")}/${t("frontend.auditoria.treeitem.inspeccion")}`
    }
    // {
    //   id: rowBackendType.HT_SURVEY,
    //   name: t("")
    // }
  ]
}
