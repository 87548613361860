import cloneDeep from "lodash/cloneDeep"

import {
  ADD_CRITICALY_SETTING,
  ADD_IMPACT_SETTING,
  ADD_INHERENT_RISK_SETTING,
  ADD_PROBABILITY_SETTING,
  SET_GLOBAL
} from "./actions"

export const makeInitialState = () => ({
  impact: null,
  inherentRisk: null,
  probability: null,
  criticaly: null,
  global: false
})

export const makeDefaultAction = () => ({
  type: "",
  data: {
    nombre: "",
    valor: [],
    empresa: null
  },
  entry: {}
})

export function settingsReducer(state = makeInitialState(), action = makeDefaultAction()) {
  const {
    type = "",
    data: { nombre: dataName = "", valor: dataValue = [], empresa: dataCompany = null } = {},
    entry = {}
  } = action ?? {}
  const _state = cloneDeep(state)

  switch (type) {
    case ADD_PROBABILITY_SETTING: {
      if (_state.probability === null) {
        _state.probability = {}
      }
      _state.probability.nombre = dataName
      _state.probability.valor = dataValue

      return _state
    }

    case ADD_IMPACT_SETTING: {
      if (_state.impact === null) {
        _state.impact = {}
      }
      _state.impact.nombre = dataName
      _state.impact.valor = dataValue

      return _state
    }

    case ADD_INHERENT_RISK_SETTING: {
      if (_state.inherentRisk === null) {
        _state.inherentRisk = {}
      }
      _state.inherentRisk.nombre = dataName
      _state.inherentRisk.valor = dataValue

      return _state
    }

    case ADD_CRITICALY_SETTING: {
      if (_state.criticaly === null) {
        _state.criticaly = {}
      }
      _state.criticaly.nombre = dataName
      _state.criticaly.valor = dataValue
      _state.criticaly.empresa = dataCompany

      _state.global = dataCompany === null ? true : false

      return _state
    }

    case "SETTINGS_SET": {
      return {
        ..._state,
        [action.setting]: action.value
      }
    }

    case SET_GLOBAL: {
      return {
        ..._state,
        global: false
      }
    }

    default: {
      return _state
    }
  }
}
