import { rowFrontendType } from "src/constants/rowTypes"
import { treeItemColumn } from "src/constants/treeItemColumn"
import { findFromConfigList, getTreeitemRefId } from "src/helpers/tree"
import { getFrontendType } from "src/helpers/typeFormatter"
import { sortTreeData } from "src/macrocomponents/Tree/Parser/TreeDataParser"

function _parse(data, level = 0, treeConfigs = {}, parents = []) {
  return sortTreeData(data).map((item) => {
    let statuses

    let isDraggable = true

    let _type = getFrontendType(item.item_tipo)
    let name = item.name || item.nombre

    const signature = item.firmas
      ? {
          completed: item.firmas.completado.length > 0,
          reviewed: item.firmas.revisado.length > 0,
          completers: item.firmas.completado,
          reviewers: item.firmas.revisado
        }
      : undefined

    switch (_type) {
      case rowFrontendType.BUSINESS_UNIT:
      case rowFrontendType.MACROPROCESS:
      case rowFrontendType.PROCESS:
      case rowFrontendType.SUB_PROCESS:
        statuses = [
          {
            statusId: treeItemColumn.RISKS,
            data: 0
          },
          {
            statusId: treeItemColumn.INHERENT_RISK,
            data: {
              value: 0
            }
          },
          {
            statusId: treeItemColumn.SOLIDITY_CONTROL,
            data: {
              value: 0
            }
          },
          {
            statusId: treeItemColumn.RESIDUAL_RISK,
            data: {
              value: 0
            }
          },
          {
            statusId: treeItemColumn.PROGRESS,
            data: 0
          }
        ]
        break
      case rowFrontendType.UNDERSTANDING:
        isDraggable = false

        statuses = [
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: item.status,
              initials: item.usuario_asignado?.sigla,
              name: item.usuario_asignado?.name
            }
          },
          {
            statusId: treeItemColumn.SIGNATURE,
            data: signature
          }
        ]
        break
      case rowFrontendType.RISK:
        statuses = [
          {
            statusId: treeItemColumn.PROBABILITY,
            data: {
              value: item.probabilidad,
              color: findFromConfigList(treeConfigs?.riesgo_probabilidad_ranges, item.probabilidad, "color"),
              label: findFromConfigList(treeConfigs?.riesgo_probabilidad_ranges, item.probabilidad, "name")
            }
          },
          {
            statusId: treeItemColumn.IMPACT_RISK,
            data: {
              value: item.impacto,
              color: findFromConfigList(treeConfigs?.riesgo_impacto_ranges, item.impacto, "color"),
              label: findFromConfigList(treeConfigs?.riesgo_impacto_ranges, item.impacto, "name")
            }
          },
          {
            statusId: treeItemColumn.INHERENT_RISK,
            data: {
              value: item.riesgo_inherente,
              color: findFromConfigList(treeConfigs?.riesgo_ri_ranges, item.riesgo_inherente, "color"),
              label: findFromConfigList(treeConfigs?.riesgo_ri_ranges, item.riesgo_inherente, "name")
            }
          },
          {
            statusId: treeItemColumn.SOLIDITY_CONTROL,
            data: {
              value: item.controles_solidez
            }
          },
          {
            statusId: treeItemColumn.RESIDUAL_RISK,
            data: {
              value: item.riesgo_residual,
              color: findFromConfigList(treeConfigs?.riesgo_ri_ranges, item.riesgo_residual, "color"),
              label: findFromConfigList(treeConfigs?.riesgo_ri_ranges, item.riesgo_residual, "name")
            }
          },
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: item.status,
              initials: item.usuario_asignado?.sigla,
              name: item.usuario_asignado?.name
            }
          },
          {
            statusId: treeItemColumn.SIGNATURE,
            data: signature
          }
        ]
        break
      case rowFrontendType.CONTROL:
        statuses = [
          {
            statusId: treeItemColumn.DESIGN,
            data: item.control_diseno
          },
          {
            statusId: treeItemColumn.EXECUTION,
            data: {
              value: 0
            }
          },
          {
            statusId: treeItemColumn.SOLIDITY,
            data: item.control_solidez
          },
          {
            statusId: treeItemColumn.FINDINGS,
            data: 0
          },
          {
            statusId: treeItemColumn.STATUS,
            data: {
              status: item.status,
              initials: item.usuario_asignado?.sigla,
              name: item.usuario_asignado?.name
            }
          },
          {
            statusId: treeItemColumn.SIGNATURE,
            data: signature
          }
        ]
        break
    }

    return {
      ...item,
      signature,
      id: item.id,
      codigo: item.codigo,
      emphasis: level === 0,
      refId: getTreeitemRefId(item),
      colorless: false,
      noPadding: false,
      type: _type,
      _type,
      isDraggable,
      statuses,
      name,
      _name: item._nombre,
      impact: item.impacto,
      probability: item.probabilidad,
      level,
      items: Array.isArray(item.items) ? _parse(item.items, level + 1, treeConfigs, [].concat(parents, [item])) : null,
      allowDrop: true,

      isOpen: _type === rowFrontendType.BUSINESS_UNIT,
      isActive: false,
      dataIsLoaded: false,

      start:
        "fecha_inicio" in item && typeof item.fecha_inicio === "string" && item.fecha_inicio.length === 10
          ? new Date(`${item.fecha_inicio} 00:00:00`)
          : null,
      end:
        "fecha_fin" in item && typeof item.fecha_fin === "string" && item.fecha_fin.length === 10
          ? new Date(`${item.fecha_fin} 00:00:00`)
          : null,

      startDate: item.fecha_inicio,
      endDate: item.fecha_fin,
      parents
    }
  })
}

export default function parseRiskEvaluation(data, level = 0, treeConfigs) {
  return new Promise((resolve) =>
    setTimeout(() => {
      const parsed = _parse(data, level, treeConfigs)

      resolve(parsed)
    }, 0)
  )
}
