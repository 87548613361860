import { useEffect } from "react"
import { useSelector } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import MainBar from "src/macrocomponents/MainBar"
import { TermsAndConditionsGuard } from "src/macrocomponents/TermsAndConditions"
import { genericAccountActionService } from "src/services/user.service"
import Loading from "../components/Loading"
import useAuthentication from "../hooks/useAuthentication"
import { logoutCallback } from "../services/authValidationMiddleware"
import useDispatch from "../store"
import BasicLayout from "./BasicLayout"

export default function AuthenticatedBasicLayout({ children, hideSideBar = false, ...otherProps }) {
  const { router, hasAuthenticatedOnce, isAuthenticated, finishedLoading } = useAuthentication()

  const dispatch = useDispatch()
  const _logout = logoutCallback(dispatch, router)

  const pageLoading = useSelector((state) => state.page.loading ?? false)
  const user = useSelector((state) => state.user)

  useEffect(() => {
    if (!finishedLoading) {
      return
    }

    if (isAuthenticated) {
      return
    }

    _logout()
  }, [hasAuthenticatedOnce, isAuthenticated, finishedLoading])

  useEffect(() => {
    try {
      const flash = JSON.parse(window.localStorage.getItem("ABI.user.flash_messages"))
      if (flash) {
        if ((flash.type ?? "success") == "success") {
          toast.success(flash.msg)
        } else if (flash.type == "error") {
          toast.error(flash.msg)
        } else if (flash.type == "warning") {
          toast.warn(flash.msg)
        }
        window.localStorage.removeItem("ABI.user.flash_messages")
      }
    } catch (Exception) {
      return
    }
  }, [])

  useEffect(() => {
    if (user?.id && typeof user?.account_public_settings === "undefined") {
      loadPublicSettings()
    }
  }, [user?.id])

  useEffect(() => {
    if (!location.pathname.startsWith("/profile/ResetPassword/") && user?.force_reset_password) {
      window.location.href = "/profile/ResetPassword/"
    }
  }, [user?.force_reset_password])

  const loadPublicSettings = () => {
    const accountSettings = window.localStorage.getItem("ABI.account_public_settings")
    const promise = !accountSettings
      ? genericAccountActionService("public_settings")
          .GET()
          .then(({ data }) => {
            window.localStorage.setItem("ABI.account_public_settings", JSON.stringify(data.records))
            return data.records
          })
      : Promise.resolve(JSON.parse(accountSettings))

    promise.then((value) => {
      dispatch({ type: "UPDATE_USER", data: { account_public_settings: value } })
    })
  }

  const renderLoading = () => {
    const l = pageLoading === true ? null : typeof pageLoading === "string" ? { text: pageLoading } : pageLoading
    if (l !== null) {
      l.color ??= "#fff"
      l.curtain ??= "#33333399"
    }

    return l === null ? <Loading /> : <Loading {...l} />
  }

  const renderContent = () => {
    return (
      <>
        <div style={{ display: "flex", height: "100vh", gap: 10 }}>
          <TermsAndConditionsGuard>
            {hideSideBar ? null : <MainBar />}
            {children}
          </TermsAndConditionsGuard>
          {pageLoading && renderLoading()}
        </div>
        <ToastContainer theme='colored' autoClose={3000} />
      </>
    )
  }

  return (
    <BasicLayout hideSideBar={hideSideBar} {...otherProps}>
      {!finishedLoading ? <Loading curtain={false} /> : isAuthenticated && renderContent()}
    </BasicLayout>
  )
}
