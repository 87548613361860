export function findAttachments(
  state = { models: {} },
  key = { modelId: '', fieldId: '' }) {
  if (!(key.modelId in state.models)) {
    state.models[key.modelId] = {}
  }

  const _model = state.models[key.modelId]

  if (!(key.fieldId in _model)) {
    _model[key.fieldId] = []
  }

  return _model[key.fieldId]
}

export function getFileExtension(fileName = '') {
  const parts = fileName.split('.')

  return parts[parts.length - 1]
}

export function mapFileExtension(extension = 'docx') {
  switch (extension) {
    case 'doc':
    case 'docx':
      return 'word'
    case 'xlsx':
    case 'xls':
    case 'csv':
      return 'excel'
    case 'pdf':
    default:
      return 'pdf'
  }
}

export function getFileSize(fileSizeInBytes = 0) {
  let fileSize = fileSizeInBytes / 1024
  let fileSizePrefix = 'KB'

  if (fileSize > 1000.0) {
    fileSize /= 1024
    fileSizePrefix = 'MB'
  }

  if (fileSize > 1000.0) {
    fileSize /= 1024
    fileSizePrefix = 'GB'
  }

  return {
    size: Math.floor(fileSize * 100) / 100.0,
    prefix: fileSizePrefix
  }
}

export function readableFileSize(fileSizeInBytes = 0) {
  const fileSize = getFileSize(fileSizeInBytes)

  return `${fileSize.size}${fileSize.prefix}`
}
