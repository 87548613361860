export const rowBackendType = Object.freeze({
  GR_PHASE: "GR_FASE",
  GR_BUSINESS_UNIT: "GR_UNIDAD_NEGOCIO",
  GR_MACROPROCESS: "GR_MACROPROCESO",
  GR_PROCESS: "GR_PROCESO",
  GR_ACTIVITY: "GR_ACTIVIDAD",
  HT_UNDERSTANDING: "HT_ENTENDIMIENTO",
  HT_RISK: "HT_RIESGO",
  HT_CONTROL: "HT_CONTROL",
  HT_TEST: "HT_CONTROL_TEST",
  HT_FINDING: "HT_HALLAZGO",
  HT_PLAN_ACCION: "HT_PLAN_ACCION",
  HT_EVALUACION: "HT_EVALUACION",
  HT_STEP: "HT_PASO",
  HT_INSPECTION: "HT_INSPECCION",
  HT_LINK: "HT_LINK",
  HT_SURVEY: "HT_ENCUESTA",
  HT_REF: "HT_REF"
})

export const rowFrontendType = Object.freeze({
  AUDIT: "audit",
  PHASE: "phase",
  BUSINESS_UNIT: "unit",
  MACROPROCESS: "macroprocess",
  PROCESS: "process",
  SUB_PROCESS: "subprocess",
  UNDERSTANDING: "flowchart",
  RISK: "risk",
  CONTROL: "control",
  TEST: "test",
  FINDING: "finding",
  ACTION_PLAN: "acctionPlan", 
  EVALUATION: "evaluation",
  STEP: "step",
  INSPECTION: "inspection",
  LINK: "link",
  SURVEY: "survey"
})
