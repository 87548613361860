import {
  ADD_COMMENT,
  ADD_NOTE,
  DELETE_NOTE,
  POPULATE_NOTES,
  UPDATE_NOTE
} from './actions'
import { revisionNoteFormatter } from './helpers'

export const initialState = {
  data: [],
  isLoaded: false
}

export default function notesReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTE:
      return {
        ...state,
        data: [...state.data, revisionNoteFormatter(action.noteCreated)]
      }
    case DELETE_NOTE: {
      const updatedData = [...state.data]

      const index = updatedData.findIndex((note) => note.id === action.noteId)

      updatedData.splice(index, 1)

      return {
        ...state,
        data: updatedData
      }
    }

    case ADD_COMMENT: {
      const updatedData = [...state.data]

      const found = updatedData.find((_note) => _note.id === action.noteId)

      found.comments.push(action.newComment)

      return {
        ...state,
        data: updatedData
      }
    }

    case UPDATE_NOTE: {
      const updatedData = [...state.data]

      const index = updatedData.findIndex((_note) => _note.id === action.noteId)

      updatedData[index] = {
        ...updatedData[index],
        ...action.updatedNote
      }

      return {
        ...state,
        data: updatedData
      }
    }
    case POPULATE_NOTES:
      return {
        ...state,
        data: action.notes.map((_el) => revisionNoteFormatter(_el)),
        isLoaded: true
      }

    default:
      return state
  }
}
