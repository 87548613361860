import { rowBackendType, rowFrontendType } from "../constants/rowTypes"

export const getWorksheetKey = (typeFromBackendOrFrontend) => {
  let worksheetKey = null

  switch (typeFromBackendOrFrontend) {
    case rowBackendType.HT_UNDERSTANDING:
    case rowFrontendType.UNDERSTANDING:
      worksheetKey = "flowchart"
      break

    case rowBackendType.HT_CONTROL:
    case rowFrontendType.CONTROL:
      worksheetKey = "control"
      break

    case rowBackendType.HT_FINDING:
    case rowFrontendType.FINDING:
      worksheetKey = "finding"
      break

    case rowBackendType.HT_PLAN_ACCION:
    case rowFrontendType.ACTION_PLAN:
      worksheetKey = "acctionPlan"
      break

    case rowBackendType.HT_EVALUACION:
    case rowFrontendType.EVALUATION:
      worksheetKey = "evaluation"
      break

    case rowBackendType.HT_RISK:
    case rowFrontendType.RISK:
      worksheetKey = "risk"
      break

    case rowBackendType.HT_TEST:
    case rowFrontendType.TEST:
      worksheetKey = "test"
      break

    case rowBackendType.HT_STEP:
    case rowFrontendType.STEP:
      worksheetKey = "step"
      break

    case rowBackendType.HT_INSPECTION:
    case rowFrontendType.INSPECTION:
      worksheetKey = "inspection"
      break
  }

  return worksheetKey
}
