import { rowFrontendType } from "src/constants/rowTypes"
import { treeItemColumn } from "src/constants/treeItemColumn"
import { dateParser } from "src/helpers/dateFormatter"
import { getTreeitemRefId } from "src/helpers/tree"
import { getFrontendType } from "src/helpers/typeFormatter"
import { treeRowSort } from "src/store/tree/helpers"

export function sortTreeData(data = [], onlyWorksheets = false) {
  const _data = [...data].sort(treeRowSort)

  if (onlyWorksheets) {
    return _data
  }

  const flowCharts = _data.filter((_item) => _item?.item_tipo == "HT_ENTENDIMIENTO")
  const flowChartsIds = flowCharts.map((item) => item.id)

  return [...flowCharts, ..._data.filter((_item) => !flowChartsIds.includes(_item.id))]
}

function _parse(data, level = 0, treeConfigs = {}, parents = null) {
  return sortTreeData(data)
    .map((item) => {
      let statuses

      let isDraggable = true
      const _type = getFrontendType(item)

      let name = item.name || item.nombre

      let signature = item.firmas
        ? {
            completed: item.firmas.completado.length > 0,
            reviewed: item.firmas.revisado.length > 0,
            completers: item.firmas.completado,
            reviewers: item.firmas.revisado
          }
        : undefined

      switch (_type) {
        case rowFrontendType.PHASE:
          isDraggable = false

          statuses = []
          break
        case rowFrontendType.BUSINESS_UNIT:
        case rowFrontendType.MACROPROCESS:
        case rowFrontendType.PROCESS:
        case rowFrontendType.SUB_PROCESS:
          statuses = []
          break

        case rowFrontendType.CONTROL:
          isDraggable = false

          statuses = []
          break
        case rowFrontendType.RISK:
          statuses = []

          item.impact = item.impacto
          item.probability = item.probabilidad

          break
        case rowFrontendType.TEST:
          isDraggable = false

          statuses = []
          break
        case rowFrontendType.FINDING:
          statuses = []

          if (level > 0 && item.ref_parent?.id) {
            const _parents = item.parents ? item.parents : parents
            if (_parents && !_parents.find((x) => x.id == item.ref_parent?.id)) {
              return null
            }
          }

          if (item.tratamiento_hallazgo !== "NO_PLAN_DE_ACCION") {
            item.items = [
              {
                ...item,
                item_tipo: "HT_PLAN_ACCION",
                id: item?.id.replace(/-\w\w$/, "") + "-PA",
                type: "acctionPlan",
                _type: "acctionPlan",
                name: "Plan de Acción " + item.codigo,
                items: []
              }
            ]
          }

          break
        case rowFrontendType.ACTION_PLAN:
          statuses = [
            {
              statusId: "EXPIRATION"
            },
            {
              statusId: "RESPONSABLE"
            },
            {
              statusId: "ACTION_PLAN",
              data: (item) => {
                return {
                  status: item.plan_accion_status
                }
              }
            },
            {}
          ]

          if (item.plan_accion_tratamiento_plan_accion !== "NO_EVALUAR") {
            item.items = [
              {
                ...item,
                item_tipo: "HT_EVALUACION",
                id: item?.id.replace(/-\w\w$/, "") + "-EV",
                type: "evaluation",
                _type: "evaluation",
                name: "Evaluación Plan de Acción " + item.codigo,
                items: []
              }
            ]
          }

          break
        case rowFrontendType.EVALUATION:
          signature = item.evaluacion_firmas
            ? {
                completed: item.evaluacion_firmas.completado.length > 0 ? true : false,
                reviewed: item.evaluacion_firmas.revisado.length > 0 ? true : false,
                completers: item.evaluacion_firmas.completado,
                reviewers: item.evaluacion_firmas.revisado
              }
            : undefined

          statuses = []
          break
        case rowFrontendType.UNDERSTANDING:
          isDraggable = false

          statuses = []
          break

        case rowFrontendType.STEP:
          statuses = [
            {
              statusId: treeItemColumn.STATUS,
              data: {
                status: item.status,
                initials: item.usuario_asignado?.sigla,
                name: item.usuario_asignado?.name
              }
            },
            {
              statusId: treeItemColumn.SIGNATURE,
              data: signature
            }
          ]
          if (item.paso_tipo === "INSPECCION_ITEM" && item.parent_paso_tipo == "INSPECCION") {
            statuses.unshift({
              statusId: "KPI",
              data: {
                label: "Cumplimiento",
                value: item.cumplimiento === null ? "-" : `${item.cumplimiento}%`
              }
            })
          }
          break

        case rowFrontendType.INSPECTION:
          statuses = [
            {
              statusId: treeItemColumn.FINDINGS,
              data: 0
            },
            {
              statusId: treeItemColumn.PROGRESS,
              data: 0
            },
            {}
          ]
          break
        case rowFrontendType.SURVEY:
          statuses = [
            {
              statusId: treeItemColumn.STATUS,
              data: {
                status: item.status,
                initials: item.usuario_asignado?.sigla,
                name: item.usuario_asignado?.name
              }
            },
            {
              statusId: treeItemColumn.SIGNATURE,
              data: signature
            }
          ]
      }

      return {
        ...item,
        signature,
        id: item.id,
        refId: getTreeitemRefId(item),
        codigo: item.codigo,
        emphasis: level === 0,
        colorless: false,
        noPadding: false,
        type: _type,
        _type,
        isDraggable,
        statuses,
        name,
        _name: item._nombre,
        level,
        items: Array.isArray(item.items)
          ? _parse(
              item.items,
              level + 1,
              treeConfigs,
              [].concat(item.parents ? item.parents : parents === null ? [] : parents, [item])
            )
          : null,
        isOpen: false,
        isActive: false,
        dataIsLoaded: false,

        start: dateParser(item.fecha_inicio),
        end: dateParser(item.fecha_fin),
        startDate: item.fecha_inicio,
        endDate: item.fecha_fin,
        ...(parents === null ? {} : { parents: item.parents ? item.parents : parents })
      }
    })
    .filter((x) => x)
}

export default function parse(data, level = 0, configs = {}) {
  return new Promise((resolve) =>
    setTimeout(() => {
      const parsed = _parse(data, level, configs)

      resolve(parsed)
    }, 0)
  )
}
