import { dataParser } from "../helpers/dataParser"
import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getTreeData = authValidationMiddleware(async (auditId, filters = {}) => {
  const params = { ...filters }

  if (typeof filters?.start === "string" && filters?.start !== "") {
    params["fecha_inicio"] = filters?.start
    delete params.start
  }

  if (typeof filters?.end === "string" && filters?.end !== "") {
    params["fecha_fin"] = filters?.end
    delete params.end
  }

  const response = await axiosApiInstance.get(
    `/proyecto/riesgo/auditoria/${auditId}/tree/`,
    Object.keys(params).length > 0
      ? {
          params
        }
      : undefined
  )

  return response.data
})

export const getTreeItem = authValidationMiddleware(async ({ auditId, itemId }) => {
  const response = await axiosApiInstance.get(
    `/proyecto/riesgo/auditoria/${auditId}/tree/${itemId.replace("-EV", "")}/`
  )

  return response.data
})

export const createTreeItem = authValidationMiddleware(async ({ auditId, ...data }) => {
  if (typeof data.nombre === "undefined" && data.name) {
    data.nombre = data.name
  }

  let url = `/proyecto/riesgo/auditoria/${auditId}/tree/`

  if (data.item_tipo == "HT_CONTROL" && data.create_type == "copy") {
    url = `/proyecto/riesgo/auditoria/${auditId}/actions/controles/`
  }

  const response = await axiosApiInstance.post(url, data)

  return response.data
})

export const deleteTreeItem = authValidationMiddleware(async ({ auditId, itemId }) => {
  const response = await axiosApiInstance.delete(
    `/proyecto/riesgo/auditoria/${auditId}/tree/${itemId.replace(/-EV$|-PA$/, "")}/`
  )

  return response.data
})

export const updateTreeItem = authValidationMiddleware(async ({ auditId, itemId, dataToUpdate }) => {
  const formattedData = dataParser(dataToUpdate)

  const response = await axiosApiInstance.put(
    `/proyecto/riesgo/auditoria/${auditId}/tree/${itemId.replace(/-EV$|-PA$/, "")}/`,
    formattedData
  )

  return response.data
})

export const copyTreeItem = authValidationMiddleware(async (typeTree, projectId, data) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/${typeTree}/${projectId}/actions/copy/`, data)

  return response.data
})

export const updateTreeItemActionPlan = authValidationMiddleware(async ({ auditId, itemId, dataToUpdate }) => {
  const formattedData = dataParser(dataToUpdate)

  const response = await axiosApiInstance.put(
    `/proyecto/riesgo/tree_item/hallazgo_planaccion/${itemId.replace(/-EV$|-PA$/, "")}/`,
    formattedData
  )

  return response.data
})

export const createTreeItemFromLibrary = authValidationMiddleware(
  async ({ riskIds, parentId, auditId, model = "auditoria", copy_from = "library", ...params }) => {
    const response = await axiosApiInstance.post(`/proyecto/riesgo/${model}/${auditId}/actions/riesgos/`, {
      riesgo_ids: riskIds,
      parent: parentId,
      copy_from,
      ...params
    })

    return response.data
  }
)

export const createTreeItemFromCopy = authValidationMiddleware(
  async ({ service, ids, parentId, auditId, model = "auditoria", ...params }) => {
    const response = await axiosApiInstance.post(`/proyecto/riesgo/${model}/${auditId}/actions/${service}/`, {
      ids,
      parent: parentId,
      ...params
    })

    return response.data
  }
)

export const massiveAssignation = authValidationMiddleware(
  async ({ auditId, usuario, treeitem_ids, model = "auditoria" }) => {
    const response = await axiosApiInstance.post(`/proyecto/riesgo/${model}/${auditId}/tree/actions/batch_assign/`, {
      usuario,
      treeitem_ids
    })

    return response.data
  }
)

export const fetchTreeitemGenericAction = authValidationMiddleware(async ({ id, action }) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/auditoria_treeitem/${id}/${action}/`)

  return response.data
})

export const postTreeitemGenericAction = authValidationMiddleware(async ({ id, action, params }) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/auditoria_treeitem/${id}/${action}/`, params)

  return response.data
})

export const putTreeitemGenericAction = authValidationMiddleware(async (id, action, payload, params = {}) => {
  const response = await axiosApiInstance.put(`/proyecto/riesgo/auditoria_treeitem/${id}/${action}/`, payload, {
    params
  })

  return response.data
})

export const postTreeitemApplyAction = authValidationMiddleware(async ({ id, action, params = {} }) => {
  const response = await axiosApiInstance.post(`/proyecto/riesgo/auditoria_treeitem/${id}/apply_action/`, {
    action,
    ...params
  })

  return response.data
})

export const adminTreeitemCustomService = (service) => {
  const URL = `/proyecto/riesgo/tree_item/${service}/`
  return {
    LIST: authValidationMiddleware(async () => {
      const response = await axiosApiInstance.get(URL)

      return response.data
    }),
    POST: authValidationMiddleware(async (params) => {
      const response = await axiosApiInstance.post(URL, params)

      return response.data
    }),
    RETRIEVE: authValidationMiddleware(async (id) => {
      const response = await axiosApiInstance.get(URL + id + "/")

      return response.data
    }),
    PUT: authValidationMiddleware(async (id, params) => {
      const response = await axiosApiInstance.put(URL + id + "/", params)

      return response.data
    }),
    DELETE: authValidationMiddleware(async (id) => {
      const response = await axiosApiInstance.delete(URL + id + "/")

      return response.data
    })
  }
}
