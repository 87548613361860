import { CLEAR_USER, SET_USER } from "./actions"

export const makeAuthInitialState = () => ({
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  username: "",
  sigla: "",
  is_superuser: false
})

const meReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.user
      }

    case "UPDATE_USER":
      return {
        ...state,
        ...action.data
      }

    case CLEAR_USER:
      return {}

    default:
      return { ...state }
  }
}

export default meReducer
