import styles from "./style.module.scss"

export default function DialogHeader({
  theme = null,
  background = null,
  color = null,
  align = null,
  buttonClose = false,
  children,
  handleClose = null
}) {
  if (theme === "light") {
    background ??= "#0a858d"
    color ??= "#e6e6e6"
    align ??= "center"
  }
  return (
    <div style={{ backgroundColor: background, color: color, textAlign: align }} className={styles.dialogHeader}>
      {children}
      {buttonClose && handleClose ? (
        <span className={styles.buttonClose} onClick={handleClose}>
          &times;
        </span>
      ) : null}
    </div>
  )
}
