import React from 'react'
import styles from './style.module.scss'

export default function Divider({ vertical = false, style = {}, color = '' }) {
  return (
    <div
      className={`${styles.divider} ${
        vertical ? styles.dividerVertical : styles.dividerHorizontal
      }`}
      style={{ ...style, borderColor: color }}
    />
  )
}
