export function projectReducer(state = {}, action) {
  switch (action.type) {
    case "PROJECT_SET":
      return action.value
    case "PROJECT_UPDATE_FIELD":
      return { ...state, ...{ [action.field]: action.value } }
    default:
      return { ...state }
  }
}
