export const initialState = {
    options: [],
    selected: '',
    collapse: ''
  }
  
  export default function mainBarReducer(state = initialState, action) {
    switch (action.type) {
      case 'MAINBAR.POPULATE':
        const options = [...action.data]
        return {
          ...state,
          options
        }
      case 'MAINBAR.ACTIVE':
        const active = state.options.filter((el) => action.data?.id === el.id)[0]?.id
        return {
          ...state,
          selected: active
        }
      case 'MAINBAR.COLLAPSE_ACTIVE':
        return {
          ...state,
          collapse: true
        }
      case 'MAINBAR.COLLAPSE_DESACTIVE':
        return {
          ...state,
          collapse: false
        }
      default:
        return state
    }
  }