const menu = [
  {
    id: 0,
    icon: "mainbar/dashboard",
    name: "page.dashboard",
    path: "/dashboard/"
  },
  {
    id: 1,
    icon: "mainbar/risks",
    name: "page.evaluacion",
    path: "/evaluacion-analisis-de-riesgos/",
    permission: "pyr_evaluacion"
  },
  {
    id: 8,
    icon: "mainbar/prioritization-matrix",
    name: "page.matriz_priorizacion",
    path: "/matriz-priorizacion/",
    permission: "matriz_priorizacion"
  },
  {
    id: 2,
    icon: "mainbar/audit-plan",
    name: "page.auditoria",
    path: "/",
    permission: "pyr_auditoria"
  },
  {
    id: 9,
    icon: "icon chart line large",
    iconStyle: { fontSize: "1.7em" },
    name: "page.reports",
    path: "/reportes/",
    permission: "reports"
  },
  {
    id: 4,
    icon: "mainbar/time-report",
    name: "page.informe_tiempo",
    path: "/informe-tiempo/resumen/",
    permission: "informe_tiempo"
  },
  {
    id: 5,
    icon: "mainbar/personal-allowance",
    name: "page.asignacion_personal",
    path: "/asignacion-personal/",
    permission: "asignacion_personal"
  },
  {
    id: 7,
    icon: "mainbar/libraries",
    name: "page.plantillas",
    path: "/plantillas/",
    permission: "plantillas"
  },
  {
    id: 6,
    icon: "mainbar/settings",
    name: "page.configuraciones",
    path: "/configuraciones/",
    permission: "configuraciones"
  },
  {
    id: 99,
    icon: "icon user secret large",
    name: "page.ab_admin",
    path: "/abadmin/",
    permission_group: 3
  }
]

export default menu
