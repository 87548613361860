import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const logError = authValidationMiddleware(async (body) => {
  body.env = process.env

  const response = await axiosApiInstance.post(`/sys/error_report/`, body)

  return response.data
})
