class Settings {
  _settings = {
    dateFormat: "dd/MM/yyyy",
    fallbackDateFormat: "yyyy-MM-dd",
    tz: "America/Bogota"
  }

  get dateFormat() {
    return this._get("dateFormat")
  }

  get fallbackDateFormat() {
    return this._get("fallbackDateFormat")
  }

  _get(attr = "") {
    return localStorage?.getItem(attr) ?? this._settings[attr] ?? null
  }
}

export const settings = new Settings()
