import "@/semantic-ui/semantic.min.css"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { Fragment } from "react"
import "react-day-picker/lib/style.css"
import { I18nextProvider } from "react-i18next"
import { Provider } from "react-redux"
import "react-toastify/dist/ReactToastify.css"
import { appInsightsReactPlugin } from "src/configs/appInsights"
import i18n from "src/i18n"
import AuthenticatedBasicLayout from "src/layouts/AuthenticatedBasicLayout"
import BasicLayout from "src/layouts/BasicLayout"
import { SupportedBrowserAlert } from "src/macrocomponents/SupportedBrowserAlert"
import ErrorBoundary from "../errors/ErrorBoundary"
import ConfirmationModalProvider from "../providers/ConfirmationModalProvider"
import { initializeStore } from "../store"
import "../styles/globals.scss"

// noinspection JSUnusedGlobalSymbols
export default function AuditManagementApp({ Component, pageProps, router }) {
  let ComponentLayout = AuthenticatedBasicLayout
  let componentProps = { pageTitle: Component.pageTitle }
  if (typeof Component.hideSideBar !== "undefined") {
    componentProps.hideSideBar = Component.hideSideBar
  }

  if (Component.layout === false) {
    ComponentLayout = Fragment
    componentProps = {}
  } else if (Component.layout === "basic") {
    ComponentLayout = BasicLayout
  } else {
    switch (router.route) {
      case "/login":
      case "/attachments/download":
        ComponentLayout = Fragment
        componentProps = {}
        break
    }
  }

  return (
    <ErrorBoundary>
      <Provider store={initializeStore()}>
        <AppInsightsContext.Provider value={appInsightsReactPlugin}>
          <ConfirmationModalProvider>
            <I18nextProvider i18n={i18n}>
              <ComponentLayout {...componentProps}>
                <ErrorBoundary>
                  <SupportedBrowserAlert />
                  <Component {...pageProps} />
                </ErrorBoundary>
              </ComponentLayout>
            </I18nextProvider>
          </ConfirmationModalProvider>
        </AppInsightsContext.Provider>
      </Provider>
    </ErrorBoundary>
  )
}
