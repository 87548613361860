export const POPULATE_DATA = "POPULATE_DATA"
export const UPDATE_SURVEY = "UPDATE_SURVEY"
export const SET_USER_ROLES = "SET_USER_ROLES"
export const CREATE_USER_ROLE = "CREATE_USER_ROLE"
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE"
export const DELETE_USER_ROLE = "DELETE_USER_ROLE"

export const populateData = ({ surveys }) => {
  return {
    type: POPULATE_DATA,
    surveys
  }
}

export const updateSurvey = ({ userId, updatedSurvey }) => {
  return {
    type: UPDATE_SURVEY,
    userId,
    updatedSurvey
  }
}

export const setUserRoles = (roles = []) => ({
  type: SET_USER_ROLES,
  roles
})

export const createUserRoles = ({ key, name, description, id }) => ({
  type: CREATE_USER_ROLE,
  key,
  id,
  description,
  name
})

export const updateUserRoles = ({ id, key, name }) => ({
  type: UPDATE_USER_ROLE,
  id,
  key,
  name
})

export const deleteUserRoles = ({ id, key }) => ({
  type: DELETE_USER_ROLE,
  key,
  id
})
