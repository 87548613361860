import startOfDay from "date-fns/startOfDay"
import cloneDeep from "lodash/cloneDeep"
import merge from "lodash/merge"
import { statusFinding } from "src/constants/status"
import { getWorksheetKey } from "src/helpers/getWorksheetKey"
import { getTreeitemConfig } from "src/helpers/tree"
import {
  ArrayPropertyUpdateType,
  FILTER_TREE,
  FILTER_TREE_NEW,
  MANAGE_TREE_ROW_PROP_ARRAY,
  TREE_ADD,
  TREE_UPDATE,
  UPDATE_TREE_ROW
} from "./actions"
import treeInitialState, {
  findInTree,
  manageArrayPropData,
  mapRows,
  moveRow,
  removeFromTree,
  updateTreeRow
} from "./helpers"

const toggleRow = (state, indexes, open = null, clone = true) => {
  const rows = clone ? cloneDeep(state.rows) : state.rows
  const row = findInTree(rows, indexes)

  if (row === null) {
    return {
      ...state
    }
  }

  if (open === null) {
    open = !row.isOpen
  }

  row.isOpen = open

  const [ok, subRows] = mapRows(row.items, (subRow) => {
    subRow.isOpen = row.isOpen

    return subRow
  })

  if (ok) {
    row.items = subRows
  }

  return { ...state, rows }
}

function deactivateRows(rows = []) {
  const [ok, subRows] = mapRows(cloneDeep(rows), (subRow) => {
    subRow.isActive = false

    return subRow
  })

  return subRows
}

export default function treeReducer(state = treeInitialState(), action) {
  const { type, name, data, indexes, flag, prevIndexes } = action
  let prevRow = null
  let row = null
  let rows = []

  const hasIndexes = typeof indexes !== "undefined" && indexes !== null && Array.isArray(indexes) && indexes.length > 0

  switch (type) {
    case "TREE.EXPAND":
      return {
        ...state,
        rows: mapRows(
          cloneDeep(state.rows),
          (subRow) => {
            subRow.isOpen = true

            return subRow
          },
          true
        )[1]
      }

    case "TREE.COLLAPSE":
      return {
        ...state,
        rows: mapRows(
          cloneDeep(state.rows),
          (subRow) => {
            subRow.isOpen = false

            return subRow
          },
          true
        )[1]
      }

    case "TREE.MOVE_ROW":
      rows = moveRow(prevIndexes, indexes, cloneDeep(state.rows))

      return {
        ...state,
        rows
      }

    case "TREE.START_DRAG":
      return {
        ...state,
        itemDragging: action.value
      }

    case "TREE.END_DRAG":
      return {
        ...state,
        itemDragging: null
      }

    case "TREE.DEACTIVATE_ROWS":
      return {
        ...state,
        rows: deactivateRows(state.rows)
      }

    case "TREE.ACTIVATE_ROW":
      rows = cloneDeep(state.rows)
      prevRow = findInTree(rows, prevIndexes)
      row = findInTree(rows, indexes)

      if (prevRow !== null) {
        prevRow.isActive = false
      }

      if (row === null) {
        return { ...state, rows: rows }
      }

      row.isActive = true

      return toggleRow({ ...state, rows: rows }, indexes, true, false)

    case "TREE.SET_ITEM_ID":
      return {
        ...state,
        id: action.id
      }

    case "TREE.OPEN_ROW":
      return toggleRow(state, indexes, true)

    case "TREE.CLOSE_ROW":
      return toggleRow(state, indexes, false)

    case "TREE.TOGGLE_ROW":
      return toggleRow(state, indexes, null)

    case "TREE.OPEN_MODAL":
      return {
        ...state,
        openModal: { type: action.value, params: action.params }
      }

    case "TREE.CLOSE_MODAL":
      return {
        ...state,
        openModal: null
      }

    case "TREE.OPEN_DELETE_OBJECT":
      return {
        ...state,
        openDeleteObject: {
          open: true,
          itemId: action.itemId,
          indexes: action.indexes
        }
      }

    case "TREE.CLOSE_DELETE_OBJECT":
      return {
        ...state,
        rows: deactivateRows(state.rows),
        openDeleteObject: {
          open: false,
          itemId: null,
          indexes: null
        }
      }

    case "TREE.DELETE_OBJECT":
      return {
        ...state,
        rows: removeFromTree(indexes, cloneDeep(state.rows))
      }

    case "TREE.OPEN_RENAME_OBJECT":
      return {
        ...state,
        openRenameObject: true
      }

    case "TREE.CLOSE_RENAME_OBJECT":
      return {
        ...state,
        rows: deactivateRows(state.rows),
        openRenameObject: false
      }

    case "TREE.RENAME_OBJECT":
      rows = cloneDeep(state.rows)
      row = findInTree(rows, indexes)

      if (row === null) {
        return {
          ...state
        }
      }

      row.name = name

      return { ...state, rows: rows }

    case TREE_UPDATE:
      if (!hasIndexes) {
        return {
          ...state
        }
      }

      rows = cloneDeep(state.rows)
      row = findInTree(rows, indexes)

      merge(row, data)

      return {
        ...state,
        rows: rows
      }

    case UPDATE_TREE_ROW: {
      rows = updateTreeRow(state.rows, indexes, (row) => {
        if (action.overwrite) {
          Object.keys(row).forEach((key) => {
            if (key in action.data) {
              row[key] = action.data[key]
            }
          })
          return row
        } else {
          return merge(row, data)
        }
      })

      return {
        ...state,
        rows
      }
    }

    case "TREE.ITEM_UPDATE":
      rows = updateTreeRow(state.rows, indexes, action.dataToUpdate)

      return {
        ...state,
        rows
      }

    case MANAGE_TREE_ROW_PROP_ARRAY:
      if (!hasIndexes) {
        return {
          ...state
        }
      }

      const { selectorFn, finderFn, finderNeedle, actionType } = action

      if (typeof actionType === "undefined" || actionType === null || actionType === ArrayPropertyUpdateType.NONE) {
        return {
          ...state
        }
      }

      rows = cloneDeep(state.rows)
      row = findInTree(rows, indexes)

      if (row === null) {
        return {
          ...state
        }
      }

      manageArrayPropData(selectorFn(row), data, actionType, finderFn, finderNeedle)

      return {
        ...state,
        rows
      }
    case TREE_ADD:
      if (!hasIndexes) {
        return {
          ...state,
          rows: [
            ...state.rows,
            {
              ...data,
              level: 0,
              colorless: false,
              isDraggable: false,
              start: "start" in data ? data.start : startOfDay(new Date()),
              end: "end" in data ? data.end : startOfDay(new Date())
            }
          ]
        }
      }

      rows = cloneDeep(state.rows)
      row = findInTree(rows, indexes)

      if (row === null) {
        return {
          ...state
        }
      }

      row.isOpen = true

      const level = row.level + 1

      let colorless

      switch (data.type) {
        case "unit":
        case "macroprocess":
        case "process":
        case "subprocess":
          colorless = false
          break

        default:
          colorless = true
          break
      }
      const _data = {
        ...data,
        level,
        isDraggable: getTreeitemConfig(data.item_tipo, "allowSort", true),
        colorless,
        start: "start" in data ? data.start : startOfDay(new Date()),
        end: "end" in data ? data.end : startOfDay(new Date()),
        parents: [].concat(row.parents, [row])
      }

      if (Array.isArray(row.items)) {
        row.items.push(_data)
      } else {
        row.items = [_data]
      }

      return {
        ...state,
        rows
      }
    case "TREE.POPULATE":
      const allRows = [...action.data]
      rows = [...action.data]

      if (action.persistPosition) {
        const { indexes, flag } = action.persistPosition

        const traverseData = (_data) => {
          const toFind = indexes[0]
          indexes.shift()
          if (indexes.length > 0) {
            if (
              _data !== null &&
              typeof _data !== "undefined" &&
              _data[toFind] !== null &&
              typeof _data[toFind] !== "undefined"
            ) {
              _data[toFind].isOpen = true
              traverseData(_data[toFind].items)
            }
          } else {
            if (
              _data !== null &&
              typeof _data !== "undefined" &&
              _data[toFind] !== null &&
              typeof _data[toFind] !== "undefined"
            ) {
              _data[toFind].isOpen = flag
            }
          }
        }

        traverseData(rows)

        return {
          ...state,
          allRows,
          rows: rows,
          configs: action.configs
        }
      }

      return {
        ...state,
        allRows,
        rows: rows,
        configs: action.configs
      }

    case "TREE.SET_CONFIG":
      return {
        ...state,
        configs: { ...state.configs, ...action.value }
      }

    case FILTER_TREE_NEW:
      const mergeAllRowsNew = (_data, withData) => {
        return _data.map((el) => {
          const found = withData.find((elem) => elem.id === el.id)

          if (found) {
            Object.entries(el).forEach(([key]) => {
              if (key !== "items") {
                el[key] = found[key]
              } else {
                el.items = el.items && el.items.length > 0 ? mergeAllRowsNew(el.items, found.items) : el.items
              }
            })
          }

          return el
        })
      }

      const allRowsUpdatedNew = state.rows.length > 0 ? mergeAllRowsNew(state.allRows, state.rows) : state.allRows

      const filters = action.filters
      const specialCase = ["usuario_asignado"]
      const keyFilters = Object.keys(filters)

      const validateUser = (el, key) => {
        if (filters[key] === "sin_asignar") return el?.[key] == null
        return el?.[key]?.id == filters[key]
      }

      const validateRef = (el) => {
        return el?.item_tipo === "HT_REF" && el?.refitem_details?.item_tipo === filters?.item_tipo
      }

      /**
       * Toma una función de condición y devuelve una función que toma una matriz de objetos y
       * devuelve una nueva matriz de objetos que han sido filtrados por la función de condición
       * @param condition - una función que toma una fila y devuelve un valor booleano
       * @returns Una función que toma una condición y devuelve una función que toma filas y devuelve
       * una matriz de objetos.
       */
      const filteredDataNew = (condition) => (menus) => {
        return menus.reduce((acc, obj) => {
          if (obj.items?.length < 0) return

          if (obj?.items?.length > 0) {
            const items = filteredDataNew(condition)(obj.items)
            return items.length > 0 || (condition(obj) && obj?.item_tipo === filters?.item_tipo)
              ? [...acc, { ...obj, items }]
              : acc
          } else if (condition(obj) && obj.item_tipo !== obj.item_tipo.includes("GR")) {
            return [...acc, obj]
          }

          return acc
        }, [])
      }

      const condition = (el) => {
        if (validateRef(el)) {
          return true
        }

        const validateElement = keyFilters.map((key) => {
          if (specialCase.includes(key)) return validateUser(el, key)
          return el[key] === filters[key]
        })

        return validateElement.every((item) => item === true) && validateElement?.length === keyFilters?.length
      }

      return {
        ...state,
        allRows: allRowsUpdatedNew,
        rows:
          Object.keys(action.filters)?.length > 0 ? filteredDataNew(condition)(allRowsUpdatedNew) : allRowsUpdatedNew
      }

    case FILTER_TREE:
      const mergeAllRows = (_data, withData) => {
        return _data.map((el) => {
          const found = withData.find((elem) => elem.id === el.id)

          if (found) {
            Object.entries(el).forEach(([key]) => {
              if (key !== "items") {
                el[key] = found[key]
              } else {
                el.items = el.items && el.items.length > 0 ? mergeAllRows(el.items, found.items) : el.items
              }
            })
          }

          return el
        })
      }

      const allRowsUpdated = state.rows.length > 0 ? mergeAllRows(state.allRows, state.rows) : state.allRows

      const filteredData = (data) => {
        let filtered = []

        const copy = (data) => {
          return Object.assign({}, data)
        }

        const traverse = (_data) => {
          return _data.filter((elem, index) => {
            const el = copy(elem)

            if (statusFinding[action.searchText]) {
              _data[index].isOpen = true
              return true
            }

            const regex = new RegExp(action.searchText, "i")

            const isWorksheet = !!getWorksheetKey(el.item_tipo)

            if (isWorksheet && (regex.test(el.name) || regex.test(el.codigo))) {
              _data[index].isOpen = true
              return true
            }

            if (el?.items?.length > 0) {
              const hasMatched = !!traverse(el.items).length

              if (hasMatched) {
                _data[index].isOpen = true
              }

              return hasMatched
            }
          })
        }

        filtered = traverse(data)

        return filtered
      }

      return {
        ...state,
        allRows: allRowsUpdated,
        rows: action.searchText ? filteredData(allRowsUpdated) : allRowsUpdated
      }

    case "TREE.LIST_BREADCRUMBS":
      return {
        ...state,
        breadcrumb: action.breadcrumbs
      }
    default:
      return state
  }
}
