import compareDesc from "date-fns/compareDesc"
import merge from "lodash/merge"
import { rowBackendType, rowFrontendType } from "src/constants/rowTypes"
import parse from "../../macrocomponents/Tree/Parser/TreeDataParser"
import parseRiskEvaluation from "../../macrocomponents/Tree/Parser/TreeRiskDataParser"
import { ArrayPropertyUpdateType } from "./actions"

export default function getInitialState() {
  return {
    allRows: [],
    rows: null,
    configs: {},
    openModal: null,
    openRenameObject: false,
    openDeleteObject: false,
    id: undefined
  }
}

export function filterInTreeBy(rows, predicate) {
  const found = []

  const filterFn = (_rows) => {
    _rows?.forEach((row) => {
      if (predicate(row)) {
        found.push(row)
      }

      if (Array.isArray(row.items)) {
        filterFn(row.items)
      }
    })
  }

  filterFn(rows)

  return found
}

export function findInTreeBy(rows = [], predicate = () => {}) {
  const searchFn = (_rows = [], _indexes = []) => {
    let _ok = false
    let _row = null
    let _i = 0

    while (_i < _rows.length) {
      _row = _rows[_i]

      _ok = predicate(_row)

      if (_ok) {
        break
      }

      if (
        typeof _row.items !== "undefined" &&
        _row.items !== null &&
        Array.isArray(_row.items) &&
        _row.items.length > 0
      ) {
        const [subRow, subIndexes] = searchFn(_row.items, [..._indexes, _i])

        if (subRow !== null) {
          return [subRow, subIndexes]
        }
      }

      _i += 1
    }

    if (!_ok) {
      return [null, []]
    }

    return [_row, [..._indexes, _i]]
  }

  return searchFn(rows)
}

export function findInTree(rows = [], indexes = []) {
  let needle = null

  const lastI = indexes.length !== 0 ? indexes.length - 1 : 0

  for (let i = 0; i < indexes.length; i++) {
    if (typeof rows === "undefined" || rows === null || !Array.isArray(rows)) {
      return needle
    }

    const idx = indexes[i]
    const row = rows[idx]

    if (typeof row === "undefined" || row === null) {
      break
    }

    if (i === lastI) {
      needle = row
      break
    }

    rows = row.items
  }

  return needle
}

export function updateInTree(rows = [], indexes = [], dataToUpdate = {}) {
  let needle = null

  const lastI = indexes.length !== 0 ? indexes.length - 1 : 0

  for (let i = 0; i < indexes.length; i++) {
    if (typeof rows === "undefined" || rows === null || !Array.isArray(rows)) {
      return needle
    }

    const idx = indexes[i]
    const row = rows[idx]

    if (typeof row === "undefined" || row === null) {
      break
    }

    if (i === lastI) {
      needle = row
      break
    }

    rows = row.items
  }

  if (!needle) {
    return needle
  }

  needle = typeof dataToUpdate === "function" ? dataToUpdate(needle) : { ...needle, ...dataToUpdate }

  return needle
}

export function updateTreeRow(rows, indexes, callback) {
  if (typeof rows === "undefined" || rows === null) {
    return []
  }

  const newRows = rows.map((row, idx) => {
    if (idx === indexes[0]) {
      if (indexes.length === 1) {
        return typeof callback === "function" ? callback({ ...row }) : { ...row, ...callback }
      }
      const newIndexes = [...indexes]
      newIndexes.shift()

      return {
        ...row,
        items: updateTreeRow(row.items, newIndexes, callback)
      }
    }

    return row
  })

  return newRows
}

export function removeFromTree(indexes = [], rows = []) {
  const firstIdx = indexes[0]
  const remainingFromIndexes = indexes.slice(1)

  let current = rows[firstIdx]
  let prevIdx = null
  let prevParent = null

  for (const remainingIdx of remainingFromIndexes) {
    if (current.items === null) {
      break
    }

    prevParent = current.items
    prevIdx = remainingIdx
    current = prevParent[prevIdx]
  }

  if (prevParent !== null) {
    prevParent.splice(prevIdx, 1)

    return rows
  }

  rows.splice(firstIdx, 1)

  return rows
}

export function mapRows(rows = [], func, throughAllRows = false) {
  if (typeof rows === "undefined" || rows === null || !Array.isArray(rows)) {
    return [false, null]
  }

  const mapRow = (row) => {
    if (typeof row.items === "undefined" || row.items === null || !Array.isArray(row.items)) {
      row.items = null

      return func(row)
    }

    row.items = row.items.map((_entry) => mapRow(_entry))

    return func(row)
  }

  if (throughAllRows) {
    return [true, rows.map(mapRow)]
  } else {
    return [true, rows]
  }
}

export function moveRow(fromIndexes = [], toIndexes = [], rows = []) {
  const row = findInTree(rows, fromIndexes)
  const targetRow = findInTree(rows, toIndexes)

  console.log(row, targetRow)

  rows = removeFromTree(fromIndexes, rows)

  if (!("items" in targetRow) || targetRow.items === null || !Array.isArray(targetRow.items)) {
    targetRow.items = [row]
  } else {
    targetRow.items.push(row)
  }

  return rows
}

export function findParents(indexes = [], rows = [], action = "add") {
  if (!rows) {
    return []
  }
  let node = rows[indexes[0]]
  const parents = [node]
  const parentIndexes = indexes.slice(1, action === "add" ? indexes.length : indexes.length - 1)

  for (let i = 0; i < parentIndexes.length; i++) {
    if ("items" in node && typeof node.items !== "undefined" && node.items !== null && Array.isArray(node.items)) {
      node = node.items[parentIndexes[i]]

      parents.push(node)

      continue
    }

    break
  }

  return parents
}

export function findDateRanges(rows = []) {
  let earliest = null
  let latest = null

  rows.forEach((row) => {
    const compareEarliest = earliest === null ? 0 : compareDesc(earliest, row.start)
    const compareLatest = latest === null ? 0 : compareDesc(latest, row.end)

    earliest = earliest === null ? row.start : compareEarliest === -1 || compareEarliest === 0 ? row.start : earliest
    latest = latest === null ? row.end : compareLatest === 1 || compareLatest === 0 ? row.end : latest
  })

  return {
    earliest,
    latest
  }
}

export async function formatAndParse(data, typeTree = "auditPlan", include_items = false, treeConfigs = {}) {
  const formatData = Array.isArray(data) ? data : [data]
  const parsedResult = ["auditPlan", "auditTemplate"].includes(typeTree)
    ? await parse(formatData, 0, treeConfigs)
    : await parseRiskEvaluation(formatData, 0, treeConfigs)
  const [result] = parsedResult

  delete result.level
  delete result.colorless
  delete result.noPadding
  delete result.isOpen
  delete result.isActive
  delete result.isDraggable
  delete result.emphasis
  delete result.allowDrop
  delete result.dataIsLoaded
  if (!include_items) {
    delete result.items
  }

  return result
}

export function manageArrayPropData(selected, data, actionType, finderFn, finderNeedle) {
  switch (actionType) {
    case ArrayPropertyUpdateType.ADD:
      selected.push(data)
      break
    case ArrayPropertyUpdateType.UPDATE:
      const foundItem = selected.find((item) => finderFn(item, finderNeedle))

      if (typeof foundItem === "undefined" || foundItem === null) {
        return selected
      }

      merge(foundItem, data)
      break
    case ArrayPropertyUpdateType.DELETE:
      const foundIdx = selected.findIndex((item) => finderFn(item, finderNeedle))

      if (foundIdx === -1) {
        return selected
      }

      selected.splice(foundIdx, 1)
      break
  }

  return selected
}

export function getRowLabel(row = { type: "", name: "", _name: "", codigo: "" }) {
  let label = row.name

  const cleanName = (str) => {
    if (typeof str === "string") {
      return str.trim().replace(/^<br\s*\/?>/, "")
    }

    return str
  }

  switch (row.type) {
    case rowFrontendType.PHASE:
      label = cleanName(row.name)
      break
    case rowFrontendType.RISK:
    case rowFrontendType.CONTROL: {
      const codigo =
        typeof row.codigo === "undefined" || row.codigo === null || row.codigo === ""
          ? null
          : row.codigo + (row.refitem_details?.id ? "<span class='itemreferred'>*</span>" : "")
      return [codigo, cleanName(row._name)].filter((x) => x !== null).join(" - ")
    }
    case rowFrontendType.FINDING:
      if (
        (typeof row.codigo === "undefined" || row.codigo === null || row.codigo === "") &&
        (typeof row.name === "undefined" || row.name === null || row.name === "")
      ) {
        label = "-"
      } else if (typeof row.codigo === "undefined" || row.codigo === null || row.codigo === "") {
        label = `${cleanName(row._name)}`
      } else {
        label = `${row.codigo} - ${cleanName(row._name)}`
      }
      break
    case rowFrontendType.TEST:
      label = row.name
      break
    case rowFrontendType.LINK:
      label = row.name.split("|")[0]
      break
  }

  return label
}

export function getRowIcon(row) {
  switch (row._type) {
    case "step":
      if (row.paso_tipo === "INSPECCION_ITEM") {
        return `tree/inspection_item`
      }
      return `tree/${row._type}`
    default:
      return `tree/${row._type}`
  }
}

export function findSiblings(indexes = [0], rows = [{}]) {
  return indexes.length > 1 ? findInTree(rows, indexes.slice(0, indexes.length - 1))?.items ?? [] : [...rows]
}

export function rowHasPositionProp(item) {
  return "position" in item && typeof item.position === "number" && item.position > -1
}

export function treeRowSort(item1, item2) {
  const item1HasPositionProp = rowHasPositionProp(item1)
  const item2HasPositionProp = rowHasPositionProp(item2)

  if (!item1HasPositionProp && !item2HasPositionProp) {
    return 0
  }

  if (item1HasPositionProp && !item2HasPositionProp) {
    return 1
  }

  if (!item1HasPositionProp && item2HasPositionProp) {
    return -1
  }

  // If both have the position prop...
  if (item1.position < item2.position) {
    return -1
  }

  if (item1.position > item2.position) {
    return 1
  }

  try {
    if (new Date(item1.created_at).getTime() < new Date(item2.created_at).getTime()) {
      return -1
    }

    if (new Date(item1.created_at).getTime() > new Date(item2.created_at).getTime()) {
      return 1
    }
  } catch (e) {
    return 0
  }

  return 0
}

export function findKeyFirstGrouper(rows, grouper = false) {
  let allRowsWithoutItems = true

  if (grouper) {
    if (rows !== null && rows.items !== null && typeof rows.items !== "undefined") return rows.items.length
    else return 0
  }

  const keys = () => {
    return rows?.items?.map((el, key) => {
      switch (el.item_tipo) {
        case rowBackendType.GR_BUSINESS_UNIT:
        case rowBackendType.GR_MACROPROCESS:
        case rowBackendType.GR_PROCESS:
        case rowBackendType.GR_ACTIVITY:
          allRowsWithoutItems = false
          return key
        default:
          break
      }
    })
  }

  keys()

  return allRowsWithoutItems
    ? rows.items !== null && typeof rows.items !== "undefined"
      ? rows.items.length
      : 0
    : keys()
    ? keys().filter((el) => el !== undefined)[0]
    : keys()
}

export function getRowsUpdate(rows) {
  if (!rows || !rows.items) return null

  rows.items.forEach((el, key) => {
    el.position = key + 1
  })

  return rows.items.filter((el, key) => key >= findKeyFirstGrouper(rows))
}

export function getDataPPT(indexes, rows) {
  const getParentWorksheet = (_currentIndexes, _data) => {
    const _parentIndexes = [..._currentIndexes]

    //_parentIndexes.pop()

    let obj = {}

    const traverseData = (_indexes, _rows) => {
      const toFind = _indexes[0]

      _indexes.shift()

      const rowFound = _rows?.[toFind]

      if (_indexes.length > 0) {
        return traverseData(_indexes, rowFound.items)
      }
      if (!rowFound) {
        return
      }

      switch (rowFound.type) {
        case rowFrontendType.CONTROL:
        case rowFrontendType.FINDING:
        case rowFrontendType.RISK:
        case rowFrontendType.UNDERSTANDING:
        case rowFrontendType.TEST:
        case rowFrontendType.STEP:
        case rowFrontendType.INSPECTION:
          obj.label = [rowFrontendType.RISK, rowFrontendType.CONTROL, rowFrontendType.FINDING].includes(rowFound.type)
            ? rowFound.codigo
            : rowFound.nombre
          obj.indexes = _parentIndexes
          obj.data = rowFound
          break
      }
    }

    traverseData([..._parentIndexes], _data)

    return Object.values(obj).length > 0 ? obj : null
  }

  return getParentWorksheet(indexes, rows)
}

export function calculateNextItemPosition(parentRow) {
  let position = -1

  const n = (v) => (isNaN(parseInt(v)) ? 0 : parseInt(v))

  parentRow?.items?.forEach((item) => {
    if (n(item.position) > position) {
      position = n(item.position)
    }
  })

  return position + 1
}
