export const ADD_AUDITS = "ADD_AUDITS";
export const UPDATE_AUDIT = "UPDATE_AUDIT";
export const DELETE_AUDIT = "DELETE_AUDIT";
export const CREATE_CONTROL_CRITERIA = "CREATE_CONTROL_CRITERIA";
export const POPULATE_AUDITS = 'POPULATE_AUDITS'

export const populateAudits = (
  audits = [], 
  year = 2021
) => {
  return {
    type: POPULATE_AUDITS,
    audits,
    year
  }
}

export const addAudits = (
  audits = [], 
  year = 2021
) => {
  return {
    type: ADD_AUDITS,
    data: audits,
    year,
  };
}

export const editAudit = (
  auditId = "", 
  audit = {}
) => {
  return {
    type: UPDATE_AUDIT,
    data: [audit],
    target: auditId,
  };
}

export const deleteAudit = (
  auditId = ""
) => {
  return {
    type: DELETE_AUDIT,
    target: auditId,
  };
}
