import parse from "date-fns/parse"
import { sortBy } from "lodash"
import { dateFormatter } from "src/helpers/dateFormatter"

export function parseItemsByProject(member) {
  return {
    id: member.id ?? "",
    label: `${member.usuario?.name ?? "-"} (${member.rol?.nombre ?? "-"})`,
    status: {
      value: "SIN INICIAR",
      color: "#ed4138",
      label: "Sin iniciar"
    },
    progress: "0%",
    icon: null,
    duration: member.horas_presupuestadas ?? 0,
    startString: member.fecha_inicio,
    start:
      typeof member.fecha_inicio === "string" && member.fecha_inicio !== ""
        ? parse(member.fecha_inicio, "yyyy-MM-dd", new Date())
        : null,
    endString: member.fecha_fin,
    end:
      typeof member.fecha_fin === "string" && member.fecha_fin !== ""
        ? parse(member.fecha_fin, "yyyy-MM-dd", new Date())
        : null,
    tooltip: {
      title: "Detalle del equipo",
      width: 300,
      entries: [
        {
          label: member.rol?.nombre ?? "-",
          value: member.usuario?.name ?? "-",
          hasUserIcon: true
        },
        {
          label: "Inicio",
          value: dateFormatter(member.fecha_inicio) ?? "Pendiente",
          hasCalendarIcon: true
        },
        {
          label: "Fin",
          value: dateFormatter(member.fecha_fin) ?? "Pendiente",
          hasCalendarIcon: true
        },
        {
          label: "Horas presupuestadas",
          value: member.horas_presupuestadas ?? 0,
          hasUserIcon: false
        }
      ]
    },
    budgetedCost: member.costo_presupuestado ?? 0,
    usuario: {
      id: member.usuario.id
    },
    source: member
  }
}

export const getBudgetedHours = (items) =>
  items.reduce((sum, item) => sum + (item.horas_presupuestadas || item.duration || 0), 0)

export const getBudgetedCost = (items) =>
  items.reduce((sum, item) => sum + (item.costo_presupuestado || item.budgetedCost || 0), 0)

export const getAssignedHours = (items) => items.reduce((sum, item) => sum + (item.duration || 0), 0)

export const parseAuditByProjectAudit = (audit) => {
  return {
    id: audit.id ?? "",
    budgetedHours: audit.horas_presupuestadas || 0,
    nombre: `${audit.codigo}-${audit.nombre ?? "-"} (${audit.ano_auditoria})`,
    startString: dateFormatter(audit.fecha_inicio),
    start:
      typeof audit.fecha_inicio === "string" && audit.fecha_inicio !== ""
        ? parse(audit.fecha_inicio, "yyyy-MM-dd", new Date())
        : null,
    endString: dateFormatter(audit.fecha_fin),
    end:
      typeof audit.fecha_fin === "string" && audit.fecha_fin !== ""
        ? parse(audit.fecha_fin, "yyyy-MM-dd", new Date())
        : null,
    progress: audit.progreso,
    tooltip: {
      title: "Auditoría",
      width: 300,
      entries: [
        {
          label: "Nombre",
          value: `${audit.codigo}-${audit.nombre ?? "-"} (${audit.ano_auditoria})`
        },
        {
          label: "Inicio",
          value: dateFormatter(audit.fecha_inicio),
          hasCalendarIcon: true
        },
        {
          label: "Fin",
          value: dateFormatter(audit.fecha_fin),
          hasCalendarIcon: true
        },
        {
          label: "Horas presupuestadas",
          value: audit.horas_presupuestadas
        },
        {
          label: "Avance",
          value: audit.progreso + "%"
        }
      ]
    },
    source: audit
  }
}

export function parsePersonalByProject(audits = []) {
  return sortBy(audits, "fecha_inicio").map((audit) => ({
    ...parseAuditByProjectAudit(audit),
    icon: false,
    items:
      "Equipo" in audit && Array.isArray(audit.Equipo)
        ? sortBy(audit.Equipo, "fecha_inicio").map((member) => {
            return parseItemsByProject(member)
          })
        : [],
    assignedHours: getBudgetedHours(audit.Equipo) ?? 0,
    budgetedCost: getBudgetedCost(audit.Equipo) ?? 0,
    open: true
  }))
}

export function makeTeamAuditObj(teamMember) {
  return {
    id: teamMember.id,
    status: {
      value: "SIN INICIAR",
      color: "#ed4138",
      label: "Sin iniciar"
    },
    progress: "100%",
    icon: null,
    duration: teamMember.horas_presupuestadas ?? 0,
    start:
      typeof teamMember.fecha_inicio === "string" && teamMember.fecha_inicio !== ""
        ? parse(teamMember.fecha_inicio, "yyyy-MM-dd", new Date())
        : null,
    startString: teamMember.fecha_inicio,
    end:
      typeof teamMember.fecha_fin === "string" && teamMember.fecha_fin !== ""
        ? parse(teamMember.fecha_fin, "yyyy-MM-dd", new Date())
        : null,
    endString: teamMember.fecha_fin,
    budgetedCost: teamMember.costo_presupuestado ?? 0,
    tooltip: {
      title: "Detalle de auditoría",
      width: 300,
      entries: [
        {
          label: "Inicio",
          value: dateFormatter(teamMember.fecha_inicio) ?? "Pendiente",
          hasCalendarIcon: true
        },
        {
          label: "Fin",
          value: dateFormatter(teamMember.fecha_fin) ?? "Pendiente",
          hasCalendarIcon: true
        },
        {
          label: "Horas presupuestadas",
          value: teamMember.horas_presupuestadas ?? 0,
          hasUserIcon: false
        },
        {
          label: "Usuario",
          value: `(${teamMember.usuario?.sigla ?? "-"}) ${teamMember.usuario?.name ?? "-"}`,
          hasUserIcon: true
        }
      ]
    },
    source: teamMember
  }
}

export function parseTeamMember(teamMember, auditId) {
  return {
    id: teamMember.usuario?.id ?? "",
    icon: false,
    nombre: `(${teamMember.usuario?.sigla ?? "-"}) ${teamMember.usuario?.name ?? "-"} - (${
      teamMember.rol?.nombre ?? "-"
    })`,
    memberToAuditMap: {
      [teamMember.id]: auditId
    },
    rol: {
      id: teamMember.rol?.id
    }
  }
}

export function parseUser(items) {
  const _items = Array.isArray(items) ? items : [items]
  return {
    items: sortBy(_items, "startString") ?? [],
    open: true,
    assignedHours: getAssignedHours(_items) ?? 0,
    budgetedCost: getBudgetedCost(_items) ?? 0
  }
}

export function makeAuditObj(audit) {
  return {
    auditId: audit.id,
    label: `${audit?.codigo}-${audit?.nombre} (${audit?.ano_auditoria})`,
    progreso: audit.progreso
  }
}

export function parsePersonalByUser(audits = []) {
  const { auditMap, userList } = sortBy(audits, "fecha_inicio").reduce(
    (agg, audit) => {
      const auditId = audit.id ?? ""
      const auditTeam = "Equipo" in audit && Array.isArray(audit.Equipo) ? audit.Equipo : []

      if (auditId === "") {
        return agg
      }

      auditTeam.forEach((teamMember) => {
        const teamMemberId = teamMember.id ?? ""
        const userId = teamMember.usuario?.id ?? ""

        if (teamMemberId === "" || userId === "") {
          return
        }

        if (userId in agg.userMap) {
          agg.userMap[userId].memberToAuditMap[teamMemberId] = auditId
        } else {
          const user = parseTeamMember(teamMember, auditId)
          agg.userMap[userId] = user
          agg.userList.push(user)
        }

        if (userId in agg.auditMap) {
          agg.auditMap[userId].push({
            ...makeAuditObj(audit),
            ...makeTeamAuditObj(teamMember)
          })
        } else {
          agg.auditMap[userId] = [
            {
              ...makeAuditObj(audit),
              ...makeTeamAuditObj(teamMember)
            }
          ]
        }
      })
      return agg
    },
    {
      auditMap: {},
      userMap: {},
      userList: []
    }
  )
  return userList.map((user) => ({
    ...user,
    ...parseUser(auditMap[user.id])
  }))
}
