export const ADD_ATTACHMENTS = 'ADD_ATTACHMENTS'
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT'
export const UPDATE_ATTACHMENT_SIGNATURES = 'UPDATE_ATTACHMENT_SIGNATURES'

export function addAttachments(
  {
    modelId = '',
    fieldId = '',
    attachments = [
      {
        'id': '',
        'nombre_archivo': '',
        'peso_archivo': 0,
        'created_at': '',
        'created_by': ''
      }
    ]
  }) {
  return {
    type: ADD_ATTACHMENTS,
    key: { modelId, fieldId },
    data: attachments
  }
}

export function deleteAttachment(
  {
    modelId = '',
    fieldId = '',
    fileId = ''
  }) {
  return {
    type: DELETE_ATTACHMENT,
    key: { modelId, fieldId, fileId }
  }
}

export function updateAttachmentSignatures(
  {
    modelId = '',
    fieldId = '',
    fileId = '',
    signatures = {
      completado: [],
      revisado: []
    }
  }
  ) {
  return {
    type: UPDATE_ATTACHMENT_SIGNATURES,
    key: { modelId, fieldId, fileId },
    signatures: signatures
  }
}
