import cx from "classnames"
import debounce from "lodash/debounce"
import { useEffect, useMemo, useRef, useState } from "react"
import Icon from "../Icon"
import Portal from "../Portal"
import styles from "./style.module.scss"

export const tooltipPosition = Object.freeze({
  below: "below",
  above: "above",
  left: "left",
  right: "right",
  belowRight: "belowRight",
  aboveLeft: "aboveLeft"
})

export default function Tooltip({
  children,
  content,
  title = null,
  disabled = false,
  inline = false,
  mode = null,
  style = {},
  maxWidth = 400,
  useHTML = false,
  containerStyle = {},
  position = tooltipPosition.below
}) {
  if (Object.entries(tooltipPosition).findIndex(([key]) => key === position) === -1) {
    throw new Error(
      `position cannot be "${position}". The only supported values are:
      ${Object.entries(tooltipPosition)
        .map(([key]) => key)
        .join(", ")}.`
    )
  }

  const [showTooltip, setShowTooltip] = useState(false)
  const [extraStyle, setExtraStyle] = useState({
    visibility: "",
    opacity: 0
  })
  const [coords, setCoords] = useState({
    left: 0,
    top: 0
  })
  const triggerRef = useRef(null)
  const tooltipContentWrapperRef = useRef(null)
  const requestRef = useRef(0)

  const updateCoords = debounce(() => {
    const { current: currentTrigger } = triggerRef
    const { current: currentContentWrapper } = tooltipContentWrapperRef

    if (!!currentTrigger && !!currentContentWrapper) {
      const triggerRect = currentTrigger.getBoundingClientRect()
      const contentrapperRect = currentContentWrapper.getBoundingClientRect()

      const { below, above, left, right, aboveLeft, belowRight } = tooltipPosition

      switch (position) {
        case below:
          setCoords({
            left: Math.max(triggerRect.x - contentrapperRect.width + 33, 0),
            top: triggerRect.bottom + 8
          })
          break
        case belowRight:
          setCoords({
            left: triggerRect.x,
            top: triggerRect.bottom + 8
          })
          break
        case left:
          setCoords({
            left: Math.max(triggerRect.left - contentrapperRect.width - 10, 0),
            top: triggerRect.top
          })
          break
        case right:
          setCoords({
            left: Math.max(triggerRect.right + 15, 0),
            top: triggerRect.top
          })
          break

        case above:
          setCoords({
            left: Math.max(triggerRect.x),
            top: triggerRect.top - contentrapperRect.height - 10
          })
          break
        case aboveLeft:
          setCoords({
            left: Math.max(triggerRect.left - contentrapperRect.width + 10, 0),
            top: triggerRect.top - contentrapperRect.height - 10
          })
          break
      }
    }
  }, 50)

  const hoverHandler = () => {
    setShowTooltip(true)
    requestRef.current = requestAnimationFrame(updateCoords)
  }

  const mouseoutHandler = () => {
    setShowTooltip(false)

    cancelAnimationFrame(requestRef.current)
  }

  useEffect(() => {
    if (triggerRef.current !== null && tooltipContentWrapperRef.current !== null) {
      updateCoords()
    }

    return () => {
      cancelAnimationFrame(requestRef.current)
    }
  }, [triggerRef.current, tooltipContentWrapperRef.current])

  useEffect(() => {
    if (coords.left === 0 && coords.top === 0) {
      setExtraStyle({
        visibility: "hidden",
        opacity: 0
      })

      return
    }

    setExtraStyle({
      visibility: "visible",
      opacity: 1
    })
  }, [coords.left, coords.top])

  const getClassName = useMemo(() => {
    let className

    const { above, below, left, right, aboveLeft, belowRight } = tooltipPosition

    switch (position) {
      case below:
        className = styles.tooltipBelow
        break
      case belowRight:
        className = styles.tooltipBelowRight
        break
      case right:
        className = styles.tooltipRight
        break
      case left:
        className = styles.tooltipLeft
        break

      case above:
        className = styles.tooltipAbove
        break
      case aboveLeft:
        className = styles.tooltipAboveLeft
        break
    }

    return className
  }, [position])

  if (disabled) {
    return children
  }

  if (inline) {
    containerStyle = { display: "inline-block", verticalAlign: "middle", ...containerStyle }
  }

  return (
    <>
      <div ref={triggerRef} onMouseEnter={hoverHandler} onMouseLeave={mouseoutHandler} style={containerStyle}>
        {children}
      </div>
      {showTooltip ? (
        <Portal>
          <div
            ref={tooltipContentWrapperRef}
            className={cx(styles.tooltip, getClassName, mode && "mode-" + mode, title && styles.titled)}
            style={{
              maxWidth: maxWidth,
              ...coords,
              ...style,
              ...extraStyle
            }}
          >
            {title && (
              <div className={styles.title}>
                {mode == "help" && (
                  <span style={{ marginRight: 5 }}>
                    <Icon name='help' size={12} color='#FFF' />
                  </span>
                )}{" "}
                <span className={styles.titleText}>{title}</span>
              </div>
            )}
            {useHTML ? (
              <div className={styles.body} dangerouslySetInnerHTML={{ __html: content }}></div>
            ) : (
              <div className={styles.body}>{content}</div>
            )}
          </div>
        </Portal>
      ) : null}
    </>
  )
}
