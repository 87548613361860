import cx from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import * as Spinners from "react-spinners"
import useTranslation from "src/hooks/useTranslation"
import styles from "./style.module.scss"

function LoadingPortal({ children }) {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const el = document.getElementById("abi-loading-portal")
    if (el) {
      ref.current = el
      setMounted(true)
    }
  }, [])

  return mounted ? createPortal(children, ref.current) : <>{children}</>
}

export default function Loading({
  color = "#0a858d",
  type = null,
  curtain = true,
  height = 32,
  width = null,
  centered = true,
  style = {},
  text = null,
  textStyle = {},
  ...otherProps
}) {
  let SpinnerComponent = null

  const { t } = useTranslation()

  if (curtain && centered && (!textStyle || !Object.values(textStyle).length)) {
    textStyle = { fontSize: 22, fontWeight: "bold", marginTop: 50, color: "#EEE", lineHeight: "1.5em" }
  }

  if (type !== null) {
    const SpinnerType = Spinners[type]

    SpinnerComponent = (
      <div className='loading-spinner' style={{ width, height, margin: "auto", textAlign: "center", ...style }}>
        <SpinnerType
          color={color}
          height={height}
          width={width || height}
          className='loading-spinner'
          {...otherProps}
        />
        <div className='clearfix' />
        <div className={cx("loading-spinner-text", styles.spinnerText)} style={textStyle}>
          {t(text)}
        </div>
      </div>
    )
  } else {
    SpinnerComponent = (
      <div className='loading-spinner' style={{ margin: "auto" }}>
        <div className={cx(styles.ldsRing)}>
          <div style={{ width: 40, margin: "0 auto" }}>
            <div
              className='ring-arc'
              style={{
                borderColor: `${color} transparent transparent transparent`
              }}
            ></div>
            <div
              className='ring-arc'
              style={{
                borderColor: `${color} transparent transparent transparent`
              }}
            ></div>
            <div
              className='ring-arc'
              style={{
                borderColor: `${color} transparent transparent transparent`
              }}
            ></div>
            <div
              className='ring-arc'
              style={{
                borderColor: `${color} transparent transparent transparent`
              }}
            ></div>
          </div>
        </div>
        <div className={cx("loading-spinner-text", styles.spinnerText)} style={textStyle}>
          {t(text)}
        </div>
      </div>
    )

    if (!centered) {
      SpinnerComponent = (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            width: "100%"
          }}
        >
          {SpinnerComponent}
        </div>
      )
    }
  }

  if (centered) {
    return (
      <LoadingPortal>
        <div
          className={cx(styles.spinnerCenteredWrapper, curtain && styles.curtain)}
          style={{ backgroundColor: curtain === true ? "#33333336" : curtain }}
        >
          {SpinnerComponent}
        </div>
      </LoadingPortal>
    )
  }

  return SpinnerComponent
}
