import { useMemo } from "react"
import { applyMiddleware, combineReducers, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import attachmentsReducer, { makeAttachmentsState } from "./attachments/reducer"
import auditingCommitteesReducer, { makeAuditingCommitteesState } from "./auditingCommittees/reducer"
import auditsReducer, { makeAuditsState } from "./audits/reducer"
import companiesReducer from "./companies/reducer"
import evaluationsReducer, { initialState as evaluationsInitialState } from "./evaluations/reducer"
import findingsReducer, { makeInitialState as makeFindingsInitialState } from "./findings/reducer"
import httpReducer, { initialState as httpInitialState } from "./http/reducer"
import mainBarReducer, { initialState as mainBarInitialState } from "./mainBar/reducer"
import treeMiddleware from "./middlewares/treeMiddleware"
import notesReducer, { initialState as notesInitialState } from "./notes/reducer"
import pageReducer from "./page/reducer"
import { paramlistReducer } from "./paramlistReducer/reducer"
import personalAllowanceReducer, { initialState as personalAllowanceInitialState } from "./personalAllowance/reducer"
import { projectReducer } from "./project/reducer"
import requirementsReducer, { initialState as requirementsInitialState } from "./requirements/reducer"
import responsiblesReducer, { initialState as responsiblesInitialState } from "./responsibles/reducer"
import risksReducer, { initialState as risksInitialState } from "./risks/reducer"
import { makeInitialState as makeSettingsInitialSTate, settingsReducer } from "./settings/reducer"
import sidenavContainerReducer, { initialState as sidenavContainerInitialState } from "./sidenavContainer/reducer"
import surveysReducer, { initialState as surveysInitialState } from "./surveys/reducer"
import treeInitialState from "./tree/helpers"
import treeReducer from "./tree/reducer"
import userReducer, { makeAuthInitialState } from "./user/reducer"

let store

const initialState = {
  tree: treeInitialState(),
  sidenavContainer: sidenavContainerInitialState,
  responsibles: responsiblesInitialState,
  http: httpInitialState,
  risks: risksInitialState,
  notes: notesInitialState,
  attachments: makeAttachmentsState(),
  auditingCommittees: makeAuditingCommitteesState(),
  project: null,
  audits: makeAuditsState(),
  requirements: requirementsInitialState,
  mainBar: mainBarInitialState,
  evaluations: evaluationsInitialState,
  findings: makeFindingsInitialState(),
  settings: makeSettingsInitialSTate(),
  personalAllowance: personalAllowanceInitialState,
  user: makeAuthInitialState(),
  paramlists: {},
  surveys: surveysInitialState,
  page: { title: "", loading: false, topAlerts: [], configs: {} },
  companies: { selected: null, companies: null, selectedSettings: {} }
}

const rootReducer = combineReducers({
  tree: treeReducer,
  sidenavContainer: sidenavContainerReducer,
  responsibles: responsiblesReducer,
  http: httpReducer,
  risks: risksReducer,
  notes: notesReducer,
  attachments: attachmentsReducer,
  auditingCommittees: auditingCommitteesReducer,
  audits: auditsReducer,
  project: projectReducer,
  requirements: requirementsReducer,
  mainBar: mainBarReducer,
  evaluations: evaluationsReducer,
  findings: findingsReducer,
  settings: settingsReducer,
  personalAllowance: personalAllowanceReducer,
  user: userReducer,
  paramlists: paramlistReducer,
  surveys: surveysReducer,
  page: pageReducer,
  companies: companiesReducer
})

function initStore(preloadedState = initialState) {
  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools({ trace: true })(applyMiddleware(thunk, treeMiddleware))
  )
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState)

  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState
    })

    store = undefined
  }

  if (typeof window === "undefined") {
    return _store
  }

  if (!store) {
    store = _store
  }

  return _store
}

export default function useDispatch() {
  return initializeStore().dispatch
}

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState])
}
