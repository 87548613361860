export const treeItemColumn = Object.freeze({
  DEFEATED: 'DEFEATED',
  TYPE: 'TYPE',
  LOST: 'LOST',
  IMPACT: 'IMPACT',
  ACTION_PLAN: 'ACTION_PLAN',
  DESIGN: 'DESIGN',
  EXECUTION: 'EXECUTION',
  SOLIDITY: 'SOLIDITY',
  FINDINGS: 'FINDINGS',
  STATUS: 'STATUS',
  PROGRESS: 'PROGRESS',
  SIGNATURE: 'SIGNATURE',
  INHERENT_RISK: 'INHERENT_RISK',
  RESIDUAL_RISK: 'RESIDUAL_RISK',
  YEAR: 'YEAR',
  PREVIOUS_YEAR: 'PREVIOUS_YEAR',
  AUDITS_AMOUNT: 'AUDITS_AMOUNT',
  PROCESS_TYPE: 'PROCESS_TYPE',
  SOLIDITY_CONTROL: 'SOLIDITY_CONTROL',
  RISKS: 'RISKS',
  PROBABILITY: 'PROBABILITY',
  IMPACT_RISK: 'IMPACT_RISK',
  EVALUATION: 'EVALUATION',
  EXPIRATION: 'EXPIRATION',
  RESPONSIBLE: 'RESPONSABLE',
  CUMPLIMIENTO: "CUMPLIMIENTO",
  EVALUATION_STATE: "EVALUATION_STATE",
  
})
