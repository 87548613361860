import cloneDeep from 'lodash/cloneDeep'
import { ADD_ATTACHMENTS, DELETE_ATTACHMENT, UPDATE_ATTACHMENT_SIGNATURES } from './actions'
import { findAttachments } from './helpers'

export function makeAttachmentsState() {
  return {
    models: {}
  }
}

export default function attachmentsReducer(
  state = makeAttachmentsState(),
  action = {
    type: '',
    key: { modelId: '', fieldId: '', fileId: '' },
    data: [
      {
        'id': '',
        'nombre_archivo': '',
        'peso_archivo': 0,
        'created_at': '',
        'created_by': ''
      }
    ],
    signatures: {
      completado: [],
      revisado: []
    }
  }) {
  const { type, key, data, signatures } = action

  switch (type) {
    case ADD_ATTACHMENTS: {
      const _state = cloneDeep(state)
      const _attachments = findAttachments(_state, { ...key })

      if (!('firmas' in data)) {
        data.firmas = {
          completado: [],
          revisado: []
        }
      }

      _attachments.push(...data)

      return _state
    }

    case DELETE_ATTACHMENT: {
      const _state = cloneDeep(state)
      const _attachments = findAttachments(_state, { ...key })

      const attachmentIdx = _attachments.findIndex(a => a.id === key.fileId)

      if (attachmentIdx === -1) {
        return _state
      }

      _attachments.splice(attachmentIdx, 1)

      return _state
    }

    case UPDATE_ATTACHMENT_SIGNATURES: {
      const _state = cloneDeep(state)
      const _attachments = findAttachments(_state, { ...key })

      const attachmentIdx = _attachments.findIndex(a => a.id === key.fileId)

      if (attachmentIdx === -1) {
        return _state
      }

      const attachment = _attachments[attachmentIdx]

      attachment.firmas = signatures

      return _state
    }

    default: {
      return state
    }
  }
}
