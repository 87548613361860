import { get } from "get-wild"
import { rowBackendType } from "../constants/rowTypes"
import treeItemConfigs from "../constants/treeItemConfigs"

export const getNameGroupers = (code) => {
  return rowBackendType[code] ?? ""
}

export const getNameWorkSheets = (code) => {
  return rowBackendType[code] ?? ""
}

export const getParamQueryByTypeTree = (typeTree) => {
  switch (typeTree) {
    case "auditPlan":
    case "auditTemplate":
      return "auditoria-id"
    default:
      return "evaluacion-id"
  }
}

export function decodeTreeMode(typeTree) {
  switch (typeTree) {
    case "AUDITPLAN":
      return "auditoria"
    case "RISKEVALUATION":
      return "evaluacion"
  }
}

export const getTreeitemTipoToTr = (item_tipo) => {
  if (typeof item_tipo === "undefined" || item_tipo == null) {
    return null
  }

  if (typeof item_tipo == "object") {
    item_tipo = getTreeitemType(item_tipo)
  }

  const nameWorkSheet = item_tipo?.replace(/^\w\w_/, "")
  if (nameWorkSheet) {
    return `frontend.auditoria.treeitem.${nameWorkSheet.toLowerCase()}`
  }
  return null
}

export const getTreeitemShortName = (item) => {
  if (typeof item === "undefined" || item === null) {
    return ""
  }

  switch (item.item_tipo) {
    case rowBackendType.HT_RISK:
    case rowBackendType.HT_CONTROL:
    case rowBackendType.HT_FINDING:
      return typeof item.codigo === "undefined" ? item.code : item.codigo
    default:
      return item.nombre
  }
}

export const getTreeitemName = (item) => {
  switch (item.item_tipo) {
    case rowBackendType.HT_RISK:
    case rowBackendType.HT_CONTROL:
    case rowBackendType.HT_FINDING:
      return `${typeof item.codigo === "undefined" ? item.code : item.codigo}-${item.nombre}`
    case rowBackendType.HT_EVALUACION:
      return `${typeof item.codigo === "undefined" ? item.code : item.codigo}-${item.nombre} [Evaluación]`
    default:
      return item.nombre
  }
}

export const getTreeitemRefId = (item) => {
  return item.item_tipo == "HT_REF" ? item.refitem_details?.id : item.id
}

export const getTreeitemType = (item) => {
  return item.item_tipo == "HT_REF" ? item.refitem_details?.item_tipo : item.item_tipo
}

export const getTreeitemDefaultUrl = (itemId, auditId) => {
  const href = `${process.env.webAppUrl}/auditorias`

  return `${href}/plan/?auditoria-id=${auditId}&hoja-id=${itemId}`
}

export const getTreeitemLinkUrl = (_item, auditId) => {
  if (!["HT_LINK", "HT_ENCUESTA"].includes(_item.item_tipo)) {
    return null
  }

  const href = `${process.env.webAppUrl}/auditorias`

  switch (_item.codigo) {
    case "INFORME_DETALLADO":
      return `${href}/reportes/informe-detallado/?auditoria-id=${auditId}`
    case "INFORME_EJECUTIVO":
      return `${href}/reportes/informe-ejecutivo/?auditoria-id=${auditId}`
    case "PLANES_DE_ACCION_Y_SEGUIMIENTO":
    case "PLANES_DE_ACCIÓN_Y_SEGUIMIENTO":
      return `${href}/plan/?auditoria-id=${auditId}&tab=2`
    case "SATISFACCION":
      return `${href}/encuestas/encuestas-de-satisfaccion/?auditoria-id=${auditId}`
    case "AUTOEVALUACION":
      return `${href}/encuestas/autoevaluacion/?auditoria-id=${auditId}`
    default:
      return _item.name.split("|")[1]
  }
}

export const countChildrenBy = (_item, predicate) => {
  let number = 0
  if (_item && Array.isArray(_item) && _item.length > 0) {
    _item.map((el) => {
      let itemIndex

      if (Array.isArray(el.items) && el.items.length > 0) {
        itemIndex = el.items?.findIndex((el2) => predicate(el) || predicate(el2))
      } else {
        itemIndex = predicate(el) ? 1 : -1
      }
      if (itemIndex !== -1 && itemIndex !== undefined) {
        number++
      }
      if (el.items?.length > 0) {
        number += countChildrenBy(el.items, predicate)
      }
    })
  }

  return number
}

export const getTreeitemConfig = (_item, configName, defaultValue = null) => {
  if (typeof _item == "object") {
    _item = _item.item_tipo == "HT_REF" ? _item.refitem_details.item_tipo : _item.item_tipo
  }

  return get(treeItemConfigs, `${_item}.${configName}`, defaultValue)
}

export const findFromConfigList = (list, value, field, defaultValue = null) => {
  if (!list) {
    return defaultValue
  }
  const found = list.find((x) => x.value + "" === value + "")
  if (field === "object") {
    return found
  }
  return found ? found[field] : defaultValue
}

export const findFieldFromRanges = (value, ranges, field, inclusive = "max") => {
  if (typeof ranges === "undefined" || ranges === null) {
    return null
  }

  for (const k in ranges) {
    const range_it = ranges[k]

    if (typeof range_it.min !== "undefined" && range_it.min < value && typeof range_it.max == "undefined") {
      return range_it[field]
    }

    if (typeof range_it.max !== "undefined" && range_it.max >= value && typeof range_it.min == "undefined") {
      return range_it[field]
    }

    if (inclusive == "max" || k == ranges.length - 1) {
      if (range_it.max >= value && range_it.min < value) {
        return range_it[field]
      }
    } else {
      if (range_it.max > value && range_it.min <= value) {
        return range_it[field]
      }
    }
  }

  const last = ranges[ranges.length - 1]
  if (inclusive == "min" && last.min == value) {
    return last[field]
  }
}
