import { ADD_RESPONSIBLE, POPULATE_RESPONSIBLES } from './actions'

export const initialState = {
  list: [],
  isLoaded: false
}

export default function responsiblesReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_RESPONSIBLE:
      return {
        ...state,
        list: [...state.list, action.responsibleCreated]
      }
    case POPULATE_RESPONSIBLES:
      return {
        ...state,
        list: action.responsibles,
        isLoaded: true
      }

    default:
      return state
  }
}
