import { setUser as SentrySetUser } from "@sentry/browser"
import once from "lodash/once"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { batch, useSelector } from "react-redux"
import { appInsights } from "src/configs/appInsights"
import { mergeUserDataInStorage } from "src/helpers/user"
import { getMyData, logout as logoutService } from "../services/authentication.service"
import useDispatch from "../store"
import { setHasAuthenticatedOnce, setIsLoadingAuthentication } from "../store/http/actions"
import { clearUser, setUser } from "../store/user/actions"

const tokenStorageKeys = ["logged_in_at", "token", "expires_in", "type", "user"]
const tokenStorageParsers = {
  user: (value) => JSON.parse(value),
  logged_in_at: (value) => new Date(value),
  expires_in: (value) => parseFloat(value)
}

function isValidDate(value) {
  return value instanceof Date && !isNaN(value)
}

export function logout(dispatch, storage = localStorage, isManual = false) {
  return logoutService().finally(() => {
    appInsights.trackEvent({ name: "auth.user_loggedout" })

    storage.removeItem("token")
    storage.removeItem("user")
    storage.removeItem("logged_in_at")
    storage.removeItem("ABI.account_public_settings")
    storage.removeItem("firstStepsVideo")
    storage.removeItem("ABI.general.global_settings")

    setTimeout(() => {
      dispatch(clearUser())
    }, 2000)

    if (location.pathname !== "/login/") {
      window.location.href = "/login/"
    }
  })
}

const getMyUserDataOnce = once(() => {
  return getMyData({ pageUrl: window.location.href })
})

export default function useAuthentication() {
  const router = useRouter()
  const dispatch = useDispatch()

  const hasAuthenticatedOnce = useSelector((state) => state.http.hasAuthenticatedOnce)
  const isLoadingAuthentication = useSelector((state) => state.http.isLoadingAuthentication)

  const [isMalformed, setIsMalformed] = useState(false)
  const [finishedLoading, setFinishedLoading] = useState(false)
  const authMap = tokenStorageKeys.reduce((agg, value) => {
    agg[value] = null

    return agg
  }, {})

  useEffect(() => {
    if (hasAuthenticatedOnce) {
      if (!finishedLoading) {
        setFinishedLoading(true)
      }

      return
    }

    if (isLoadingAuthentication) {
      return
    }

    dispatch(setIsLoadingAuthentication(true))

    for (const tokenStorageKey of tokenStorageKeys) {
      const item = localStorage.getItem(tokenStorageKey)

      if (item === null) {
        if (!["/", "/login/", "/profile/ResetPassword/"].includes(window.location.pathname)) {
          localStorage.setItem("lastPageUrl", window.location.href)
        }
        setIsMalformed(true)
        setFinishedLoading(true)

        return
      }

      authMap[tokenStorageKey] =
        tokenStorageKey in tokenStorageParsers ? tokenStorageParsers[tokenStorageKey](item) : item
    }

    if (
      authMap.token === "" ||
      isNaN(authMap.expires_in) ||
      !isValidDate(authMap.logged_in_at) ||
      authMap.type === "" ||
      authMap.user === null
    ) {
      console.error("Authentication", "User data is malformed")

      setIsMalformed(true)
      setFinishedLoading(true)

      return
    }

    const getUserPromise = getMyUserDataOnce()

    if (typeof getUserPromise === "undefined" || getUserPromise === null) {
      return
    }

    getUserPromise
      .then(({ data }) => {
        batch(() => {
          dispatch(setUser(data.data))
          dispatch(setHasAuthenticatedOnce())
          dispatch(setIsLoadingAuthentication(false))
          SentrySetUser({ id: data.data.id, username: data.data.username, role: data.data.role })

          try {
            localStorage.removeItem("ABI.general.global_settings")
            // eslint-disable-next-line no-empty
          } catch (e) {}

          mergeUserDataInStorage(data.data)
        })
      })
      .catch(() => {
        batch(() => {
          if (!["/", "/login/", "/profile/ResetPassword/"].includes(window.location.pathname)) {
            localStorage.setItem("lastPageUrl", window.location.href)
          }
          dispatch(setHasAuthenticatedOnce())
          dispatch(setIsLoadingAuthentication(false))
          setIsMalformed(true)
        })
      })
      .finally(() => {
        setFinishedLoading(true)
      })
  }, [
    hasAuthenticatedOnce,
    isLoadingAuthentication,
    authMap.token,
    authMap.expires_in,
    authMap.logged_in_at,
    authMap.type,
    authMap.user
  ])

  return {
    router,
    isAuthenticated: !isMalformed,
    hasAuthenticatedOnce,
    finishedLoading
  }
}
