import { sortBy } from "lodash"
import cloneDeep from "lodash/cloneDeep"
import { ADD_AUDITS, DELETE_AUDIT, POPULATE_AUDITS, UPDATE_AUDIT } from "./actions"

export function makeAuditsState() {
  return {
    audits: null,
    loadedYears: {}
  }
}

export default function auditsReducer(
  state = makeAuditsState(),
  action = {
    type: "",
    target: "",
    year: 2021,
    data: [
      {
        id: "",
        date: "",
        hour: "",
        name: "",
        organizer: "",
        description: ""
      }
    ],
    audits: []
  }
) {
  const { type, data, target, year, audits } = action

  const cleanArray = (v) => (v === null ? [] : v)

  switch (type) {
    case POPULATE_AUDITS: {
      const loadedYears = {
        ...state.loadedYears
      }

      const loadedKey = typeof year === "string" ? year : typeof year === "number" ? year.toString(10) : year

      loadedYears[loadedKey] = true

      return {
        ...state,
        audits: audits ? cleanArray(audits) : null,
        loadedYears
      }
    }

    case ADD_AUDITS: {
      const audits = cloneDeep(cleanArray(state.audits))
      const loadedYears = cloneDeep(state.loadedYears)

      audits.push(...data)
      loadedYears[typeof year === "string" ? year : typeof year === "number" ? year.toString(10) : year] = true

      return {
        ...state,
        audits: sortBy(audits, "fecha_inicio"),
        loadedYears
      }
    }
    case UPDATE_AUDIT: {
      state.audits.forEach((el, key) => {
        delete state.audits[key].showCell
      })
      const auditIdx = cleanArray(state.audits).findIndex((audit) => audit.id === target)
      if (auditIdx === -1) {
        return { ...state }
      }
      const audits = cloneDeep(cleanArray(state.audits))
      const audit = audits[auditIdx]
      const [newData] = data

      if (typeof newData === "undefined") {
        return { ...state }
      }

      Object.keys(newData).forEach((dataKey) => {
        audit[dataKey] = newData[dataKey]
      })

      return {
        ...state,
        audits
      }
    }
    case DELETE_AUDIT: {
      state.audits.forEach((el, key) => {
        delete state.audits[key].showCell
      })

      const auditIdx = cleanArray(state.audits).findIndex((audit) => audit.id === target)

      if (auditIdx === -1) {
        return { ...state }
      }

      const audits = cloneDeep(cleanArray(state.audits))

      audits.splice(auditIdx, 1)

      return {
        ...state,
        audits
      }
    }
    default: {
      return { ...state }
    }
  }
}
