export default function companiesReducer(state = null, action) {
  switch (action.type) {
    case "COMPANIES.POPULATE_DATA":
      return { companies: action.value }

    case "COMPANIES.ADD":
      if (state.companies === null) {
        return { ...state, companies: [action.value] }
      }

      return { ...state, companies: state.companies.concat([action.value]) }

    case "COMPANIES.SELECT":
      return { ...state, selected: action.value, selectedSettings: {} }

    case "COMPANIES.SET_SETTING":
      return { ...state, selectedSettings: { ...state.selectedSettings, [action.setting]: action.value } }

    default:
      return state
  }
}
