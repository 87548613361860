import { createContext, useState } from "react"
import YesNoModal from "../../macrocomponents/YesNoModal"

export const ConfirmationModalContext = createContext(null)

export default function ConfirmationModalProvider({ children }) {
  const [confirmationModalConfig, setConfirmationModalConfig] = useState({
    isOpen: false,
    title: "",
    question: "",
    buttons: {
      left: {
        text: "No, Eliminar",
        name: "normal"
      },
      right: {
        text: "Sí, Eliminar",
        name: "warning"
      }
    },
    requestConfig: {
      request: async () => {},
      onSuccess: () => {},
      onError: () => {}
    },
    className: null,
    style: {}
  })

  return (
    <>
      <ConfirmationModalContext.Provider value={{ confirmationModalConfig, setConfirmationModalConfig }}>
        {children}
      </ConfirmationModalContext.Provider>
      <YesNoModal
        isOpen={confirmationModalConfig.isOpen}
        onClose={() => setConfirmationModalConfig({ isOpen: false })}
        config={{
          request: confirmationModalConfig.requestConfig?.request,
          title: confirmationModalConfig.title,
          question: confirmationModalConfig.question,
          buttons: confirmationModalConfig.buttons
        }}
        onSuccess={(res) => {
          if (typeof confirmationModalConfig.requestConfig.onSuccess === "function") {
            confirmationModalConfig.requestConfig.onSuccess(res)
          }

          setConfirmationModalConfig({ isOpen: false })
        }}
        className={confirmationModalConfig.className}
        style={confirmationModalConfig.style}
      />
    </>
  )
}
