export const ADD_RISK = "ADD_RISK"
export const POPULATE_RISKS = "POPULATE_RISKS"
export const DELETE_RISK = "DELETE_RISK"
export const DELETE_SPECIFIC_RISK = "DELETE_SPECIFIC_RISK"
export const UPDATE_RISK = "UPDATE_RISK"
export const CREATE_RISK = "CREATE_RISK"
export const CREATE_SPECIFIC_RISK = "CREATE_SPECIFIC_RISK"
export const UPDATE_SPECIFIC_RISK = "UPDATE_SPECIFIC_RISK"
export function addRisk({ riskCreated, key, id = null }) {
  return {
    type: ADD_RISK,
    riskCreated,
    key,
    id
  }
}

export function deleteSpecificRisk({ generalId, specificId }) {
  return {
    type: DELETE_SPECIFIC_RISK,
    generalId,
    specificId
  }
}

export function deleteRisk({ key, id }) {
  return {
    type: DELETE_RISK,
    key,
    id
  }
}

export function updateRisk({ key, id, name }) {
  return {
    type: UPDATE_RISK,
    key,
    id,
    name
  }
}
export function createRisk({ key, newRisk }) {
  return {
    type: CREATE_RISK,
    key,
    newRisk
  }
}

export function updateSpecificRisk({ specificId, generalId, name }) {
  return {
    type: UPDATE_SPECIFIC_RISK,
    specificId,
    generalId,
    name
  }
}
export function createSpecificRisk({ newRisk, generalId }) {
  return {
    type: CREATE_SPECIFIC_RISK,
    generalId,
    newRisk
  }
}

export function populateRisks({ risks = { general: [], factors: [], objectives: [] } }) {
  return {
    type: POPULATE_RISKS,
    risks
  }
}
