export function paramlistReducer(state = {}, action) {
  switch (action.type) {
    case "PARAMLIST_SET":
      return {
        ...state,
        [action.name]: action.value
      }

    default: {
      return state
    }
  }
}
