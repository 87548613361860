export const LOAD_FINDINGS = 'LOAD_FINDINGS'
export const SET_IS_LOADING_FINDINGS = 'SET_IS_LOADING_FINDINGS'
export const ADD_FINDING_ACTION_PLAN_ACTIVITY = 'ADD_FINDING_ACTION_PLAN_ACTIVITY'
export const UPDATE_FINDING_ACTION_PLAN_ACTIVITY = 'UPDATE_FINDING_ACTION_PLAN_ACTIVITY'
export const DELETE_FINDING_ACTION_PLAN_ACTIVITY = 'DELETE_FINDING_ACTION_PLAN_ACTIVITY'
export const UPDATE_FINDING = 'UPDATE_FINDING'

export const loadFindings = ({ data = [] }) => ({
  type: LOAD_FINDINGS,
  entries: data
})

export const setIsLoadingFindings = ({ value = true }) => ({
  type: SET_IS_LOADING_FINDINGS,
  flag: value
})

export const addFindingActionPlanActivity = ({ findingId = '', data = null }) => ({
  type: ADD_FINDING_ACTION_PLAN_ACTIVITY,
  entry: data,
  key: findingId
})

export const updateFindingActionPlanActivity = ({ findingId = '', activityId = '', data = null }) => ({
  type: UPDATE_FINDING_ACTION_PLAN_ACTIVITY,
  entry: data,
  key: [ findingId, activityId ]
})

export const deleteFindingActionPlanActivity = ({ planActionId = '', activityId = '' }) => ({
  type: DELETE_FINDING_ACTION_PLAN_ACTIVITY,
  key: [ planActionId, activityId ]
})

export const updateFinding = ({ findingId = '', data = null }) => ({
  type: UPDATE_FINDING,
  entries: data,
  key: [ findingId ]
})