export const impact = Object.freeze({
  LOW: {
    value: 1,
    color: "#00a750",
    label: "Bajo"
  },
  MEDIUM: {
    value: 3,
    color: "#ffe543",
    contrastColor: "#333",
    label: "Medio"
  },
  HIGH: {
    value: 5,
    color: "#ed4138",
    label: "Alto"
  }
})

export const actionPlan = Object.freeze({
  NEW: {
    value: "NUEVO",
    color: "#ed4138",
    label: "Nuevo"
  },
  ASSIGNED: {
    value: "ASIGNADO",
    color: "#ffe543",
    label: "Asignado"
  },
  IN_PROCESS: {
    value: "EN_PROCESO",
    color: "#ffae39",
    label: "En Proceso"
  },
  IMPLEMENTED: {
    value: "IMPLEMENTADO",
    color: "#00a750",
    label: "Implementado"
  },
  TRACING: {
    value: "SEGUIMIENTO",
    color: "#00a750",
    label: "Seguimiento"
  },
  EVALUATED: {
    value: "EVALUADO",
    color: "#56aaff",
    label: "Evaluado"
  },
  NO_ACCEPTED: {
    value: "NO_ACEPTADO",
    color: "#662e93",
    fgColor: "#ffffff",
    label: "No Aceptado"
  },
  REJECTED: {
    value: "RECHAZADO",
    color: "#ed4138",
    label: "Rechazado"
  },
  _IMPLEMENTED: {
    value: "_IMPLEMENTADO",
    color: "#56aaff",
    label: "Implementado"
  }
})

export const actionPlanActivity = Object.freeze({
  NEW: {
    value: "NUEVO",
    color: "#ed4138",
    label: "Pendiente"
  },
  IN_PROCESS: {
    value: "EN_PROCESO",
    color: "#ffe543",
    label: "En Proceso"
  },
  APPROVED: {
    value: "APROBADO",
    color: "#ffae39",
    label: "Aprobado"
  },
  REALIZED: {
    value: "REALIZADO",
    color: "#00a750",
    label: "Realizado"
  },
  IMPLEMENTED: {
    value: "IMPLEMENTADO",
    color: "#00a750",
    label: "Implementado"
  },
  EVALUATED: {
    value: "EVALUADO",
    color: "#56aaff",
    label: "Evaluado"
  },
  REJECTED: {
    value: "RECHAZADO",
    color: "#ed4138",
    label: "Rechazado"
  },
  ASSIGNED: {
    value: "ASIGNADO",
    color: "#ed4138",
    label: "Asignado"
  }
})

export const modelStatus = Object.freeze({
  IN_DRAFT: {
    value: "NUEVO",
    color: "#ed4138",
    label: "Nuevo",
    score: 0
  },
  NO_DISCUSSED: {
    value: "SIN_DISCUTIR",
    color: "#ffae39",
    label: "En proceso",
    score: 0.4
  },
  DISCUSSED: {
    value: "COMPLETADO",
    color: "#00a750",
    label: "Completado",
    score: 0.85
  },
  NO_ACCEPTED: {
    value: "NO_ACEPTADO",
    color: "#A26ACF",
    label: "Rechazado",
    score: 1
  },

  REVISADO: {
    value: "REVISADO",
    color: "#56aaff",
    label: "Revisado",
    score: 1
  },
  NOTA_REVISION: {
    value: "NOTA_REVISION",
    color: "#662e93",
    label: "Notas de revisión",
    score: 0.85
  }
})
