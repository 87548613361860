export const dataParser = (data) => {
  Object.entries(data).forEach(([key, value]) => {
    switch (key) {
      case 'name':
        data.nombre = value
        delete data.name
        break
      case '_name':
        data._nombre = value
        delete data._name
        break
    }
  })

  return data
}
