import { useEffect, useState } from "react"
import brandLogoImg from "src/assets/login/brand-logo.png"
import Button from "src/components/Button"
import Dialog from "src/components/Dialog"
import DialogBody from "src/components/Dialog/DialogBody"
import DialogHeader from "src/components/Dialog/DialogHeader"
import Loading from "src/components/Loading"
import useAccessControl from "src/hooks/useAccessControl"
import { logout } from "src/hooks/useAuthentication"
import { getUserDisclamerText, getUserEvent, postUserEvent } from "src/services/user.service"
import useDispatch from "src/store"

export const TermsAndConditionsModal = ({ text, accept, decline }) => {
  return (
    <Dialog isOpen>
      <DialogHeader background='var(--primary-emerald-green)' color='white' align='center'>
        Términos y condiciones
      </DialogHeader>
      <DialogBody background='white' align='justify'>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div dangerouslySetInnerHTML={{ __html: text }} />

          <div style={{ display: "flex", gap: "1rem", alignSelf: "center" }}>
            <Button
              style={{ width: "fit-content" }}
              text={"Rechazar"}
              name='warning'
              type='big'
              option='normal'
              handleClick={decline}
            />

            <Button
              style={{ width: "fit-content" }}
              text={"Aceptar"}
              name='normal'
              type='big'
              option='normal'
              handleClick={accept}
            />
          </div>
        </div>
      </DialogBody>
    </Dialog>
  )
}

export const TermsAndConditions = ({ onAccept }) => {
  const [open, setOpen] = useState(false)
  const [text, setText] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    getUserEvent("terms_and_conditions").then(({ data }) => {
      if (data.exists) {
        localStorage.setItem("acceptTermsAndConditions", "1")
        onAccept()
      } else {
        setOpen(true)
        fetchText()
      }
    })
  }, [])

  const fetchText = () => {
    getUserDisclamerText().then(({ data }) => {
      if (data?.value === null) {
        setOpen(false)
        onAccept()
      } else {
        setText(data?.value)
      }
    })
  }

  const handleDecline = () => {
    setOpen(false)
    logout(dispatch, localStorage)
    dispatch({ type: "PAGE_LOADING", value: "Cerrando sesión." })
  }

  const handleAccept = () => {
    setOpen(false)
    onAccept()
    localStorage.setItem("acceptTermsAndConditions", "1")
    postUserEvent("terms_and_conditions")
  }

  if (!open) {
    return null
  }

  if (text === null) {
    return <Loading centered />
  }

  return <TermsAndConditionsModal text={text} decline={handleDecline} accept={handleAccept} />
}

export const TermsAndConditionsGuard = ({ children }) => {
  const [checked, setChecked] = useState(null)

  const dispatch = useDispatch()
  const { getCurrentUser } = useAccessControl()
  const currentUser = getCurrentUser()

  useEffect(() => {
    setChecked(currentUser.account_category != "DEMO" || localStorage.getItem("acceptTermsAndConditions") == "1")
  }, [])

  useEffect(() => {
    if (checked) {
      dispatch({ type: "PAGE_SETCONFIG", config: "SUPPORTHERO_ENABLED", value: 1 })
    }
  }, [checked])

  if (checked === null) {
    return null
  }

  return !checked ? (
    <div>
      <div style={{ textAlign: "center" }}>
        <img src={brandLogoImg} alt='logo' style={{ height: 120 }} />
      </div>

      <TermsAndConditions onAccept={() => setChecked(true)} />
    </div>
  ) : (
    children
  )
}
