import cx from "classnames"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Container, Header } from "semantic-ui-react"
import Dialog from "src/components/Dialog"
import GoogleChromeIcon from "src/components/Icons/GoogleChrome"
import style from "./style.module.scss"

export const SupportedBrowserAlert = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const idNavigator = getIdNavigator()
    const alertNavigator = localStorage.getItem("alertNavigator")

    if (idNavigator == 5 && alertNavigator == null) setOpen(true)
  }, [])

  const getIdNavigator = () => {
    const aKeys = ["MSIE", "Firefox", "Safari", "Opera", "Chrome", "Edg"]
    const sUsrAg = typeof window != "undefined" ? window.navigator.userAgent : []
    let nIdx = aKeys.length - 1

    for (nIdx; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--);

    return nIdx
  }

  const redirectChromeInstall = () => {
    window.open("https://www.google.com/intl/es-419/chrome/")
  }

  const saveAlert = () => {
    localStorage.setItem("alertNavigator", true)
    setOpen(false)
  }

  return (
    <>
      <Dialog isOpen={open} style={{ maxWidth: "500px" }}>
        <Dialog.Body background='#fff' color='#424242' align='left'>
          <Container className={cx(style.general_container, style.container)}>
            <Header as='h2'>{t("frontend.general.browser.unsupported_browser")}</Header>
            <p>{t("frontend.general.browser.message_unsupported")}</p>
            <Container className={cx(style.general_container, style.container_icon)} onClick={redirectChromeInstall}>
              <GoogleChromeIcon width='60px' heigth='60px' className={cx(style.icon)} />
            </Container>
            <button className={cx("ui button olive")} onClick={saveAlert}>
              {t("frontend.general.browser.confirm")}
            </button>
          </Container>
        </Dialog.Body>
      </Dialog>
    </>
  )
}
