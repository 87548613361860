import cx from "classnames"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import brandLogoImg from "src/assets/login/brand-logo.png"
import Divider from "src/components/Divider"
import Icon from "src/components/Icon"
import SlideToggle from "src/components/SlideToggle"
import Tooltip from "src/components/Tooltip"
import optionsMainBar from "src/constants/menu"
import useAccessControl from "src/hooks/useAccessControl"
import { genericAccountActionService } from "src/services/user.service"
import useDispatch from "src/store"
import styles from "./style.module.scss"

const minWidth = 45
const maxWidth = 190

export default function MainBar() {
  const dispatch = useDispatch()
  const router = useRouter()
  const { hasPermission, hasGroup, getCurrentUserId } = useAccessControl()
  const { t } = useTranslation()

  const {
    mainBar: { options, selected, collapse }
  } = useSelector((state) => state)

  const account_public_settings = useSelector((state) => state.user?.account_public_settings)

  const [logo2, setLogo2] = useState(null)
  const [menuWidth, setMenuWidth] = useState(minWidth)

  useEffect(() => {
    dispatch({ type: "MAINBAR.POPULATE", data: optionsMainBar })

    let pathName
    if (router.pathname === "/") {
      pathName = "/"
    } else {
      pathName = `/${router.pathname.split("/")[1]}`
    }
    pathName = pathName.startsWith("/auditorias") ? "/" : pathName
    const active = optionsMainBar.find((el) => (pathName === "/" ? el.path === pathName : el.path.startsWith(pathName)))
    dispatch({ type: "MAINBAR.ACTIVE", data: active })

    if (
      (collapse === "" && localStorage.getItem("menuCollapse") == 1) ||
      localStorage.getItem("menuCollapse") === null ||
      collapse
    ) {
      dispatch({ type: "MAINBAR.COLLAPSE_ACTIVE" })
      setMenuWidth(minWidth)
    } else {
      const width = collapse ? minWidth : maxWidth
      setMenuWidth(width)
    }
  }, [])

  useEffect(() => {
    if (account_public_settings?.public_ui_menu_logo) {
      const reader = new FileReader()
      reader.addEventListener("load", (event) => {
        setLogo2(event.target.result.toString())
      })

      genericAccountActionService("logo")
        .GET({}, { responseType: "blob" })
        .then((response) => {
          reader.readAsDataURL(response)
        })
    }
  }, [account_public_settings?.public_ui_menu_logo])

  const filterByPermission = (el) => {
    if (typeof el.permission !== "undefined") {
      return hasPermission(el.permission + ".access")
    }
    if (typeof el.permission_group !== "undefined") {
      return hasGroup(el.permission_group)
    }
    return true
  }

  const handleCollapseMenu = (isActive) => {
    if (isActive) {
      dispatch({
        type: "MAINBAR.COLLAPSE_ACTIVE"
      })
      setMenuWidth(minWidth)
    } else {
      dispatch({
        type: "MAINBAR.COLLAPSE_DESACTIVE"
      })
      setMenuWidth(maxWidth)
    }
    localStorage.setItem("menuCollapse", isActive ? 1 : 0)
  }

  if (!getCurrentUserId()) {
    return <div className={styles.wrapper} style={{ width: menuWidth }} />
  }

  return (
    <div className={styles.wrapper} style={{ width: menuWidth }}>
      {!collapse ? (
        <div className={styles.boxImg}>
          <img className={styles.image} src={brandLogoImg} alt='Auditbrain' />{" "}
        </div>
      ) : (
        <div className={styles.boxIcon}>
          <Icon name='mainbar/logo-collapsed' />
        </div>
      )}

      <div className={collapse ? styles.boxDividerCollapse : styles.boxDividerNoCollapse}>
        <Divider />
      </div>
      {collapse ? (
        <div className={styles.boxCollapseMenu}>
          <SlideToggle checked={true} onChange={(isActive) => handleCollapseMenu(isActive)} />
        </div>
      ) : (
        <div className={styles.boxNoCollapseMenu}>
          <div>{t("main_ui.navigation.menu__collapse")}</div>
          <SlideToggle checked={false} onChange={(isActive) => handleCollapseMenu(isActive)} />
        </div>
      )}

      <div className={styles.boxOptions}>
        {options.filter(filterByPermission).map((el, index) => {
          return !collapse ? (
            <a
              key={index}
              className={cx(styles.optionMenu, { [styles.optionSelect]: el.id === selected })}
              href={el.path}
            >
              <Icon name={el.icon} className='menu-icon' style={el.iconStyle} />
              <div className={styles.boxTextOptions}>{t(el.name)}</div>
              <Icon name='keyboard_arrow_right' />
            </a>
          ) : (
            <Tooltip content={t(el.name)} position='right' key={index}>
              <a className={cx(styles.optionMenu, { [styles.optionSelect]: el.id === selected })} href={el.path}>
                <Icon name={el.icon} className='menu-icon' />
              </a>
            </Tooltip>
          )
        })}
        {account_public_settings?.public_ui_menu_logo == "1" && logo2 && (
          <div>
            <img src={logo2} alt='logo2' className={styles.customerLogo} style={{ width: "70%" }} />
          </div>
        )}
      </div>
    </div>
  )
}
