import cx from "classnames"
import Head from "next/head"
import Script from "next/script"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import useAccessControl from "src/hooks/useAccessControl"
import usePageHead from "src/hooks/usePageHead"
import { FreshChat } from "src/utils/freshchat"
import styles from "../styles/layouts/BasicLayout.module.scss"

const Freshchat = () => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      const s = document.createElement("script")
      s.async = 1
      s.type = "text/javascript"
      s.src = "https://wchat.freshchat.com/js/widget.js"
      s.onload = () => {
        const freshChat = new FreshChat()
        freshChat.start()
      }
      document.getElementById("freshchat-scripts").appendChild(s)
    }
  }, [mounted])

  return mounted && <div id='freshchat-scripts' />
}
export default function BasicLayout({ pageTitle = null, children, hideSideBar = false, ...otherProps }) {
  const {
    mainBar: { collapse },
    page: { configs: pageConfigs }
  } = useSelector((state) => state)

  const { isAuthenticated } = useAccessControl()
  const { pageTitle: pageTitleStored, setPageTitle } = usePageHead()

  useEffect(() => {
    if (pageTitle !== null) {
      setPageTitle(pageTitle)
    }
  }, [])

  return (
    <div
      className={cx(`env-${process.env.NODE_ENV}`, {
        "server-dev": process.env.NEXT_PUBLIC_DEV_SERVER
      })}
    >
      <Script
        src='/ckeditor4/ckeditor.js'
        onLoad={() => {
          const myScript = document.createElement("script")
          myScript.setAttribute("src", "/ckeditor4/styles.js")
          document.body.appendChild(myScript)
        }}
      />

      <Head>
        <title>
          ABI {process.env.NEXT_PUBLIC_DEV_SERVER && " (DEV)"} | {pageTitleStored}
        </title>
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <main
        className={cx(styles.main, {
          "mainbar--none": hideSideBar,
          "mainbar--expanded": !hideSideBar && !collapse,
          "server-dev": process.env.NEXT_PUBLIC_DEV_SERVER
        })}
        {...otherProps}
      >
        {children}
      </main>

      <div id='abif-portal' />
      <div
        id='abi-loading-portal'
        className={cx({ "mainbar--none": hideSideBar, "mainbar--expanded": !hideSideBar && !collapse })}
      />
      {isAuthenticated() && <Freshchat />}
      {isAuthenticated() && pageConfigs?.SUPPORTHERO_ENABLED === 1 && process.env.NEXT_PUBLIC_SUPPORTHERO_KEY && (
        <Script
          async
          data-cfasync='false'
          src={`https://d29l98y0pmei9d.cloudfront.net/js/widget.min.js?k=${process.env.NEXT_PUBLIC_SUPPORTHERO_KEY}`}
        />
      )}
    </div>
  )
}
