export const ADD_RESPONSIBLE = 'ADD_RESPONSIBLE'
export const POPULATE_RESPONSIBLES = 'POPULATE_RESPONSIBLES'

export function addResponsible({ responsibleCreated }) {
  return {
    type: ADD_RESPONSIBLE,
    responsibleCreated
  }
}

export function populateResponsibles({ responsibles }) {
  return {
    type: POPULATE_RESPONSIBLES,
    responsibles
  }
}
