import React from "react"
import styles from "./style.module.scss"

export default function DialogFooter({ theme = null, background = null, align = null, children }) {
  if (theme == "light") {
    background = "#fff"
    align = "right"
  }
  return (
    <div
      style={{
        backgroundColor: background,
        textAlign: align
      }}
      className={styles.dialogFooter}
    >
      {children}
    </div>
  )
}
