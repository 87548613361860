import React, { useState } from 'react'

import styles from './style.module.scss'

export default function SlideToggle({
  checked = false,
  onChange = () => {},
  color = '#a8cf45',
  labelConfig = {
    left: null,
    right: null
  },
  size = 'normal'
}) {
  const [isChecked, setIsChecked] = useState(checked)

  const getSizeClass = () => {
    let sizeClass = ''

    switch (size) {
      case 'small':
        break

      case 'normal':
        sizeClass = styles.sizeNormal
        break
      case 'large':
        break
    }

    return sizeClass
  }

  return (
    <div className={getSizeClass()}>
      {labelConfig.left}
      <div className={styles.toggle}>
        <div
          className={styles.thumbContainer}
          style={{
            color: isChecked && color,
            transform: isChecked && 'translateX(20px)'
          }}
        >
          <div className={styles.label}>
            <div
              className={styles.input}
              onClick={(event) => {
                onChange(!isChecked)
                setIsChecked(!isChecked)
              }}
            />
            <div className={styles.thumb} />
          </div>
        </div>
        <div
          className={styles.track}
          style={{
            backgroundColor: isChecked && color,
            opacity: isChecked && '0.5'
          }}
        />
      </div>
      {labelConfig.right}
    </div>
  )
}
