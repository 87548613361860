import { cloneDeep } from "lodash"
import {
  CREATE_USER_ROLE,
  DELETE_USER_ROLE,
  POPULATE_DATA,
  SET_USER_ROLES,
  UPDATE_SURVEY,
  UPDATE_USER_ROLE
} from "./actions"

export const initialState = {
  surveys: [],
  userRoles: [],
  isLoaded: false
}
export default function surveysReducer(state = initialState, action) {
  switch (action.type) {
    case POPULATE_DATA: {
      return {
        ...state,
        surveys: action.surveys,
        isLoaded: true
      }
    }
    case UPDATE_SURVEY: {
      const surveyUpdated = cloneDeep([...state.surveys])
      const survey = surveyUpdated.find(
        (el) => el.id || el.encuesta_id === action.userId
      )
      const newData = action.updatedSurvey
      Object.keys(survey).forEach((key) => {
        if (key in newData) {
          survey[key] = newData[key]
        }
      })
      return {
        ...state,
        surveys: surveyUpdated
      }
    }
    case SET_USER_ROLES:
      return {
        ...state,
        userRoles: action.roles,
        isLoaded: true
      }

    case CREATE_USER_ROLE: {
      const updatedUserRoles = [...state[action.key]]
      console.log(updatedUserRoles)
      updatedUserRoles.push({
        descripcion: action.description,
        id: action.id,
        nombre: action.name
      })

      return {
        ...state,
        [action.key]: updatedUserRoles
      }
    }

    case UPDATE_USER_ROLE: {
      const updatedUserRoles = [...state[action.key]]

      const found = updatedUserRoles.find((el) => el.id === action.id)

      console.log(found)

      found.nombre = action.name

      return {
        ...state,
        [action.key]: updatedUserRoles
      }
    }

    case DELETE_USER_ROLE: {
      const updatedUserRoles = [...state[action.key]]

      const indexFound = updatedUserRoles.findIndex((el) => el.id === action.id)

      updatedUserRoles.splice(indexFound, 1)

      return {
        ...state,
        [action.key]: updatedUserRoles
      }
    }

    default:
      return state
  }
}
