import { dataParser } from "../helpers/dataParser"
import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getTreeData = authValidationMiddleware(async (evaluationId, divisionId = null, filters = {}) => {
  const url = divisionId
    ? `/proyecto/riesgo/evaluacion/${evaluationId}/subdivision/${divisionId}/tree/`
    : `/proyecto/riesgo/evaluacion/${evaluationId}/tree/`

  const response = await axiosApiInstance.get(url, filters ? { params: filters } : {})

  return response.data
})

export const getTreeEvaluationItem = authValidationMiddleware(async ({ evaluationId, itemId }) => {
  const response = await axiosApiInstance.get(`/proyecto/riesgo/evaluacion/${evaluationId}/tree/${itemId}/`)

  return response.data
})

export const createTreeEvaluationItem = authValidationMiddleware(
  async ({
    evaluationId,
    item_tipo,
    parent,
    codigo = undefined,
    _name = undefined,
    name = undefined,
    riesgos_especificos = undefined,
    riesgo_general = undefined,
    factores = undefined,
    objetivos = undefined,
    position = undefined
  }) => {
    const response = await axiosApiInstance.post(`/proyecto/riesgo/evaluacion/${evaluationId}/tree/`, {
      _nombre: _name,
      nombre: name,
      item_tipo,
      parent,
      codigo,
      riesgos_especificos,
      riesgo_general,
      factores,
      objetivos,
      position
    })

    return response.data
  }
)

export const deleteTreeEvaluationItem = authValidationMiddleware(async ({ evaluationId, itemId }) => {
  const response = await axiosApiInstance.delete(`/proyecto/riesgo/evaluacion/${evaluationId}/tree/${itemId}/`)

  return response.data
})

export const updateTreeEvaluationItem = authValidationMiddleware(async ({ evaluationId, itemId, dataToUpdate }) => {
  const formattedData = dataParser(dataToUpdate)

  const response = await axiosApiInstance.put(
    `/proyecto/riesgo/evaluacion/${evaluationId}/tree/${itemId}/`,
    formattedData
  )

  return response.data
})
