export const ADD_NOTE = 'ADD_NOTE'
export const POPULATE_NOTES = 'POPULATE_NOTES'
export const DELETE_NOTE = 'DELETE_NOTE'
export const ADD_COMMENT = 'ADD_COMMENT'
export const UPDATE_NOTE = 'UPDATE_NOTE'

export function addNote({ noteCreated }) {
  return {
    type: ADD_NOTE,
    noteCreated
  }
}

export function deleteNote(noteId) {
  return {
    type: DELETE_NOTE,
    noteId
  }
}

export function addComment({ newComment, noteId }) {
  return {
    type: ADD_COMMENT,
    newComment,
    noteId
  }
}

export function updateNote({ updatedNote, noteId }) {
  return {
    type: UPDATE_NOTE,
    updatedNote,
    noteId
  }
}

export function populateNotes({ notes }) {
  return {
    type: POPULATE_NOTES,
    notes
  }
}
