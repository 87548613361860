import mapStatus from 'src/helpers/map-statuses'
import formatFunc from 'date-fns/format'
import { signType } from 'src/constants/signTypes'

export const revisionNoteFormatter = (revisionNote) => {
  const obj = {
    ...revisionNote,
    id: revisionNote['id'],
    header: {
      status: {
        label: revisionNote['status'],
        icon: mapStatus(revisionNote['status'])
      }
    },
    body: {
      user: `${revisionNote.created_by?.name} (${revisionNote.created_by?.sigla})`,
      text: revisionNote['descripcion'],
      date: formatFunc(new Date(revisionNote['created_at']), 'dd-MM-yyyy') // Formato deseado '6 Nov 2019 / 6:40 PM'
    },
    reviewed: revisionNote['status'] === signType.REVIEWED,
    signature: revisionNote.firmas,
    comments: revisionNote.comentarios || {
      completado: [],
      revisado: []
    },
    modelId: revisionNote.modelo_id
  }

  return obj
}
