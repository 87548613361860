import axiosApiInstance from "./httpInterceptor"

export async function getMyData(params = {}) {
  const url = "/users/@me/"

  const response = await axiosApiInstance.get(url, { params })

  return {
    data: response.data ?? null,
    status: response.status ?? -1
  }
}

export async function authenticate(username = "", password = "", pkg = "audit", account = null, other_params = {}) {
  const url = "/login/"

  const payload = { username, password, pkg, ...other_params }

  if (account) {
    payload.account = account
  }

  try {
    const response = await axiosApiInstance.post(url, payload, { NO_TOKEN: true })

    return [true, null, response.data]
  } catch (error) {
    return [false, error, null]
  }
}

export function validateUsername(username) {
  return axiosApiInstance.post("/login/validate-username/", { u: username }, { NO_TOKEN: true })
}

export function validateAuthenticationCode(username, code, token, pkg = "audit") {
  return axiosApiInstance.post("/login/validate/", { u: username, code, w: token, pkg }, { NO_TOKEN: true })
}

export async function logout() {
  const url = "/logout/"

  const response = await axiosApiInstance.post(url)

  return [true, null, response.data]
}

export async function forgotpassword(email, pkg = "audit") {
  const response = await axiosApiInstance.post("/forgot-password/", { email, pkg }, { NO_TOKEN: true })
  return response.data
}

export async function checkforgotpasswordtoken(uid, token) {
  const response = await axiosApiInstance.get("/forgot-password/", { params: { uid, token }, NO_TOKEN: true })
  return response.data
}

export async function changepassword(params) {
  const response = await axiosApiInstance.post("/forgot-password/change/", params, { NO_TOKEN: true })
  return response.data
}
