import React from "react"
import { useTranslation as useTranslationI18next } from "react-i18next"

export default function useTranslation() {
  const i18next = useTranslationI18next()

  return {
    ...i18next,
    t: function (text, options = {}) {
      if (typeof text === "undefined") {
        return null
      }

      if (text === null) {
        return null
      }

      if (typeof text !== "string" && React.isValidElement(text)) {
        return text
      }

      if (typeof text === "string") {
        let defaultText
        if (typeof options === "string") {
          defaultText = options
        } else if (options !== null) {
          defaultText = options?.default
        } else {
          defaultText = text
        }

        return i18next.t(text, options ? { ...options, defaultValue: defaultText } : text)
      }

      if (Array.isArray(text)) {
        return i18next.t(text, options)
      }

      const { key, ...args } = text
      const defaultValue = options?.default ?? key
      return i18next.t(key, { ...options, ...args, defaultValue })
    }
  }
}
