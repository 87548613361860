const closedAuditPermCheckerForFindings = (record, user, permissions, treeConfigs) => {
  if (!Array.isArray(permissions)) {
    permissions = [permissions]
  }

  if (user.isAdmin) {
    return true
  }

  if (permissions.some((x) => x == `plan.${record.type}__delete`)) {
    return false
  }

  if (
    permissions.some((x) => x == "plan.evaluate_actionplan__revisado") &&
    treeConfigs?.cstm_planaccion_evaluacion_revisado_restricted == 1
  ) {
    return null
  }

  return record.created_by?.id == user.id || record.usuario_asignado?.id == user.id
}

const defaults = {
  HT: {
    onTreeitemClick: "sidemodal",
    treeRowBarShowIcon: true,
    allowRename: false,
    dndDropAllowed: ["HT_HALLAZGO"]
  },
  GR: {
    onTreeitemClick: "toggle",
    treeRowBarShowIcon: false,
    allowDuplicate: true,
    icon: false
  }
}

const configs = {
  HT_ENTENDIMIENTO: {
    typeName: "flowchart",
    allowSort: false,
    allowRename: true,
    allowDuplicate: true,
    projectTypeSupports: true
  },
  HT_RIESGO: {
    typeName: "risk",
    allowRename: false,
    projectTypeSupports: true
  },
  HT_CONTROL: {
    typeName: "control"
  },
  HT_CONTROL_TEST: {
    typeName: "test",
    allowDuplicate: true
  },
  HT_HALLAZGO: {
    typeName: "finding",
    allowDuplicate: true,
    closedAuditPermChecker: closedAuditPermCheckerForFindings,
    dndDropAllowed: []
  },

  HT_PLAN_ACCION: {
    typeName: "acctionPlan",
    allowDelete: false,
    closedAuditPermChecker: closedAuditPermCheckerForFindings,
    dndDropAllowed: []
  },
  HT_EVALUACION: {
    typeName: "evaluation",
    allowDelete: false,
    closedAuditPermChecker: closedAuditPermCheckerForFindings,
    dndDropAllowed: []
  },
  HT_PASO: {
    typeName: "step",
    allowDuplicate: true
  },
  HT_INSPECCION: {
    typeName: "inspection",
    allowDuplicate: true
  },
  HT_ENCUESTA: {
    typeName: "survey",
    onTreeitemClick: "openlink",
    icon: false,
    dndDropAllowed: []
  },
  HT_REF: {
    typeName: "risk"
  },
  HT_LINK: {
    typeName: "link",
    allowRename: false,
    allowDelete: false,
    onTreeitemClick: "openlink",
    icon: false,
    dndDropAllowed: []
  },
  GR_FASE: {
    typeName: "phase",
    treeRowBarStyle: { backgroundColor: "var(--primary-green)" },
    dndDropAllowed: ["GR_MACROPROCESO", "GR_PROCESO", "GR_ACTIVIDAD", "HT_HALLAZGO", "HT_RIESGO", "HT_PASO"]
  },
  GR_UNIDAD_NEGOCIO: {
    typeName: "unit",
    projectTypeSupports: "evaluacion",
    treeRowBarStyle: { backgroundColor: "var(--accent-purple)" },
    dndDropAllowed: ["GR_MACROPROCESO", "GR_PROCESO", "GR_ACTIVIDAD", "HT_HALLAZGO", "HT_RIESGO", "HT_PASO"]
  },
  GR_MACROPROCESO: {
    typeName: "macroprocess",
    parents: ["GR_FASE"],
    treeRowBarStyle: { backgroundColor: "var(--accent-yellow)" },
    dndDropAllowed: ["GR_PROCESO", "GR_ACTIVIDAD", "HT_HALLAZGO", "HT_RIESGO", "HT_PASO"]
  },
  GR_PROCESO: {
    typeName: "process",
    parents: ["GR_FASE", "GR_MACROPROCESO"],
    treeRowBarStyle: { backgroundColor: "var(--accent-orange)" },
    dndDropAllowed: ["GR_ACTIVIDAD", "HT_HALLAZGO", "HT_RIESGO", "HT_PASO"]
  },
  GR_ACTIVIDAD: {
    typeName: "subprocess",
    parents: ["GR_FASE", "GR_MACROPROCESO", "GR_PROCESO"],
    treeRowBarStyle: { backgroundColor: "var(--accent-sky-blue)" },
    dndDropAllowed: ["HT_HALLAZGO", "HT_RIESGO", "HT_PASO"]
  }
}

for (const k in configs) {
  configs[k] = { ...defaults[k.substring(0, 2)], ...configs[k] }
}

export default configs
