import cx from "classnames"
import { useEffect, useState } from "react"
import ErrorBoundary from "../../errors/ErrorBoundary"
import Loading from "../Loading"
import Portal from "../Portal"
import DialogBody from "./DialogBody"
import DialogFooter from "./DialogFooter"
import DialogHeader from "./DialogHeader"
import styles from "./style.module.scss"

export default function Dialog({
  isOpen = false,
  children,
  size = "default",
  style = {},
  className = null,
  dialogStyle = {},
  isLoading = false
}) {
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setRendered(true)
    }
  }, [isOpen])

  const classShow = isOpen
    ? `${styles.displayBlock} ${styles.dialog} ${styles["dialog" + size.charAt(0).toUpperCase() + size.slice(1)]}`
    : `${styles.displayNone}`

  const handleOnClick = (ev) => {
    ev.stopPropagation()
  }

  const renderDialog = () => {
    return (
      <ErrorBoundary>
        <Portal>
          <div className={classShow} style={dialogStyle} onClick={handleOnClick}>
            <div
              className={cx(styles.dialogWrapper, className)}
              style={{
                ...style,
                backgroundColor: children[1]?.props.background
              }}
            >
              {children}
              {isLoading && <Loading curtain />}
            </div>
          </div>
        </Portal>
      </ErrorBoundary>
    )
  }

  return isOpen || rendered ? renderDialog() : null
}

Dialog.Footer = DialogFooter
Dialog.Header = DialogHeader
Dialog.Body = DialogBody
