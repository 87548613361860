import { toast } from "react-toastify"
import useDispatch from "src/store"
import { logout } from "../hooks/useAuthentication"
import { setHasAuthenticatedOnce } from "../store/http/actions"

export function logoutCallback(dispatch, router) {
  return () =>
    logout(dispatch, localStorage).then(() => {
      toast.error("Sesión ha expirado, por favor ingresar nuevamente.", { toastId: "SESSION_EXPIRED" })
      dispatch(setHasAuthenticatedOnce(false))

      return Promise.reject("unauthorized")
    })
}

export default function authValidationMiddleware(request = () => Promise.resolve()) {
  return (...args) => {
    let callback = null

    let requestArgs = [...args]

    if (args.length > 0 && typeof args[args.length - 1] === "function") {
      callback = args[args.length - 1]
      requestArgs = requestArgs.slice(0, requestArgs.length - 1)
    }

    if (callback === null) {
      const dispatch = useDispatch()

      callback = logoutCallback(dispatch, null)
    }

    return request(...requestArgs).catch((error) => {
      if (
        error?.response?.status === 401 &&
        ["The Token is expired", "Invalid Token"].includes(error?.response?.data?.message)
      ) {
        return callback()
      }

      throw new Error(error)
    })
  }
}
