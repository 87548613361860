import { captureException } from "@sentry/browser"
import { withRouter } from "next/router"
import React from "react"
import { appInsights } from "src/configs/appInsights"
import { saveError } from "src/helpers/errors"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    saveError(error, errorInfo, `${process.env.webAppUrl}${this.props.router.asPath}`, localStorage.getItem("user"))

    try {
      appInsights.trackException(
        {
          error,
          exception: error,
          severityLevel: 3,
          properties: errorInfo
        },
        { userSessData: localStorage.getItem("user") }
      )
      captureException(error)
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Ha ocurrido un error</h1>
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
