import { SET_AUTHENTICATED_ONCE, SET_IS_LOADING_AUTHENTICATION } from "./actions"

export const initialState = {
  snackbar: {
    isOpen: false,
    message: ""
  },
  hasAuthenticatedOnce: false,
  isLoadingAuthentication: false
}

export default function httpReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED_ONCE: {
      if (state.hasAuthenticatedOnce) {
        return { ...state }
      }

      return {
        ...state,
        hasAuthenticatedOnce: !!action.data
      }
    }

    case SET_IS_LOADING_AUTHENTICATION: {
      return {
        ...state,
        isLoadingAuthentication: !!action.data
      }
    }

    default: {
      return { ...state }
    }
  }
}
