import axios from "axios"
import { toast } from "react-toastify"
import useDispatch from "src/store"

const axiosApiInstance = axios.create({
  baseURL: process.env.apiUrl
})

export class ApiResponseError extends Error {
  constructor(res) {
    super(res?.response?.data?.message)
    this.responseError = res
  }
}

axiosApiInstance.interceptors.request.use(
  (config) => {
    if (!config.NO_TOKEN) {
      const token = localStorage.getItem("token") ?? "NO_TOKEN"

      config.headers.Authorization = `Token ${token}`
    }

    return config
  },
  (error) => {
    // handle the error
    return Promise.reject(error)
  }
)

axiosApiInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    const dispatch = useDispatch()
    if (dispatch) {
      dispatch({ type: "PAGE_LOADING", value: false })
    }

    if (error?.response?.status === 401) {
      return Promise.reject(error)
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    let message = ""

    const msg = error?.response?.data?.message

    if (typeof msg === "string") {
      message = msg
    } else if (Array.isArray(msg)) {
      message = msg.map((item, k) => <div key={k}>- {item}</div>)
    } else if (typeof msg === "object") {
      if (msg.error) {
        message = (
          <>
            <div>{msg.error}</div>
            {msg.code && msg.code != "invalid" && <div style={{ fontSize: "smaller" }}>ErrCode: {msg.code}</div>}
          </>
        )
      } else {
        message = Object.entries(msg).map(([k, v]) => (
          <div key={k}>
            <strong>{k}</strong>: {JSON.stringify(v)}
          </div>
        ))
      }
    } else if (typeof error.message !== "undefined") {
      message = error.message
    }

    toast.error(<>{message}</>)

    return Promise.reject(error)
  }
)

export default axiosApiInstance
