import cx from "classnames"
import { isValidElement, useEffect, useRef, useState } from "react"
import CancellationTokenSource, { CancellationToken } from "../../utils/cancellation-token-source"

export default function Icon({ name, size = 24, color = null, rendered = null, className = null, style = {} }) {
  const ImportedIconRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const isIconByCls =
    (typeof name == "string" && name.startsWith("icon ")) || (typeof name === "object" && name?.type == "icons")

  useEffect(() => {
    if (loading) {
      return
    }

    if (name === null || isIconByCls) {
      return
    }

    setLoading(true)

    const cancellationTokenSource = new CancellationTokenSource()

    const importIcon = (cancellationToken = new CancellationToken()) => {
      return import(`../../assets/icons/${name}.svg`)
        .then((result) => {
          if (cancellationToken.isCancelled()) {
            return Promise.reject("CANCELLED")
          }

          ImportedIconRef.current = result["default"]

          setLoading(false)
        })
        .catch((error) => {
          if (typeof error === "string" && error === "CANCELLED") {
            return
          }

          console.error("icon loading error ->", error)
        })
    }

    importIcon(cancellationTokenSource.token).finally(() => {})

    return () => {
      cancellationTokenSource.cancel()
    }
  }, [name])

  useEffect(() => {
    if (ImportedIconRef.current) {
      if (rendered) {
        rendered()
      }
    }
  }, [ImportedIconRef.current])

  if (isIconByCls) {
    if (typeof name === "object" && name?.type == "icons") {
      return (
        <i className={cx("icons", name.className, className)}>
          {name.icons?.map((item, k) => {
            return <i key={k} className={cx("icon", item)} />
          })}
        </i>
      )
    } else if (typeof name == "string" && name.startsWith("icon ")) {
      return <i className={cx(name, className)} style={style} />
    }
  }

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef
    return <ImportedIcon className={className} width={size} height={size} color={color} fill={color} style={style} />
  }

  return null
}

export function renderIcon(icon) {
  if (isValidElement(icon)) {
    return icon
  } else if (typeof icon == "string" && icon.startsWith("ui icon")) {
    return <i className={icon} />
  } else {
    return <Icon name={icon} />
  }
}
